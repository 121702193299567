import Login from '@/views/auth/Login.vue';
import Logout from '@/views/auth/Logout.vue'
import Profile from '@/views/auth/Profile.vue'
import UpdatePassword from '@/views/auth/UpdatePassword.vue'

import AuthCallback from '@/views/auth/Callback'

export default [
    {
        path: '/auth/callback',
        name: 'auth.callback',
        component: AuthCallback
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: {
            authRequired: true
        }
    },
    {
        path: '/profile',
        name: 'MyProfile',
        component: Profile,
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },
    {
        path: '/password',
        name: 'UpdatePassword',
        component: UpdatePassword,
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },
]