<template>
  <div>
    <page-title>Edit Application (Client)</page-title>
    <el-row type="flex" justify="space-between" class="xs-mb-10">
      <div>
        <el-button plain @click="handleBack" :disabled="loading">
          <i class="fa fa-arrow-circle-left xs-mr-10"></i>Back
        </el-button>
      </div>
      <div>
        <el-row type="flex" justify="end">
          <el-button plain @click="handleReset" :disabled="loading">
            <i class="fa fa-times xs-mr-10"></i>Reset
          </el-button>
          <el-button type="primary" plain @click="handleSubmit" :loading="loading">
            <i class="fa fa-save xs-mr-10"></i>Save
          </el-button>
        </el-row>
      </div>
    </el-row>
    <el-form
      label-position="right"
      label-width="150px"
      :model="application"
      :rules="rules"
      ref="appForm"
      v-loading="loading"
    >
      <el-card>
        <div slot="header">
          <span>
            <i class="fa fa-info-circle xs-mr-10"></i>Info
          </span>
        </div>
        <el-form-item label="Name" prop="name">
          <el-input v-model="application.name" style="width:600px;"></el-input>
        </el-form-item>
        <el-form-item label="Application" prop="application_id">
          <select-oauth-app v-model="application.application_id" style="width:400px;"/>
        </el-form-item>
        <el-form-item label="Account">
          <select-account v-model="application.account_id" style="width:400px;"/>
        </el-form-item>

        <el-form-item label="API Key" prop="api_key">
          <el-input v-model="application.api_key" readonly style="width:400px;">
            <span slot="suffix">
              <i
                class="far fa-copy xs-pr-10 pointer"
                v-clipboard:copy="application.api_key"
                v-clipboard:success="handleCopySuccess"
              ></i>
              <i class="fa fa-sync-alt xs-pr-10 xs-pl-10 pointer" @click="handleRefreshApiKey"></i>
            </span>
          </el-input>
        </el-form-item>
        <el-form-item label="Active">
          <el-switch v-model="application.is_active"></el-switch>
        </el-form-item>
      </el-card>
      <el-card v-if="!_isEmpty(application.application_settings)" class="xs-mt-15">
        <div slot="header">
          <span>
            <i class="fa fa-cogs xs-mr-10"></i> App Configuration
          </span>
        </div>
        <pre>{{application.application_settings}}</pre>
      </el-card>
    </el-form>
    <el-row type="flex" justify="space-between" class="xs-mb-10 xs-mt-10">
      <div></div>
      <div>
        <el-button type="danger" plain @click="handleRemove" :loading="loading">
          <i class="far fa-trash-alt xs-mr-10"></i>Remove Application
        </el-button>
      </div>
    </el-row>
  </div>
</template>

<script>
import PageTitle from "@/components/ui/page-title";
import { getPage, getResource } from "@/helpers/rest-store-vuex";
import { isOK, isLoading } from "@jorgenevens/rest-store";
import { mapActions } from "vuex";
import SelectAccount from "@/components/app/select-account";
import SelectOauthApp from "@/components/app/select-oauth-app";

import _cloneDeep from "lodash/cloneDeep";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

import ensureResource from "@/store/helpers/ensure";

export default {
  components: {
    PageTitle,
    SelectAccount,
    SelectOauthApp
  },
  beforeRouteEnter(to, from, next) {
    ensureResource(to.params.applicationId, {
      namespace: "serviceApps"
    }).then(data => {
      if (isOK(data)) next(vm => vm.setData(data));
      else next({ name: "NotFound" });
    });
  },
  // when route changes and this component is already rendered,
  // the logic will be slightly different.
  beforeRouteUpdate(to, from, next) {
    ensureResource(this.applicationId, {
      namespace: "serviceApps"
    }).then(data => {
      if (isOK(data)) {
        this.setData(data);
        next();
      } else next({ name: "NotFound" });
    });
  },
  data() {
    return {
      application: {
        name: "",
        application_id: "",
        api_key: "",
        is_active: true,
        account_id: undefined
      },
      loading: false,
      rules: {
        name: [
          {
            required: true,
            message: "Name is required",
            trigger: "blur"
          }
        ],
        application_id: [
          {
            required: true,
            message: "Application is required",
            trigger: "blur"
          }
        ],
        account_id: [
          {
            required: true,
            message: "account is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    applicationId() {
      return this.$route.params.applicationId;
    }
  },
  methods: {
    isOK,
    isLoading,
    _isEmpty,
    setData(_data) {
      this.application = {
        ..._cloneDeep(_data),
        account_id: _data.account.id,
        application_id: _data.application.id
      };
    },
    ...mapActions("serviceApps", {
      updateApplication: "update",
      deleteApplication: "delete",
      refreshApiKey: "refreshApiKey"
    }),
    handleCopySuccess(e) {
      this.$message({
        message: "Copied.",
        center: true,
        duration: 500
      });
    },
    handleReset() {
      this.$refs["appForm"].resetFields();
      // let d = _cloneDeep(this.appData);
      // this.application = {
      //   ...d,
      //   account_id: d.account.id,
      //   application_id: d.application.id
      // };
    },
    handleRemove() {
      this.$prompt(
        "Please enter the application name for confirmation.",
        "Remove Application",
        {
          confirmButtonText: "OK, Remove",
          cancelButtonText: "Cancel",
          closeOnClickModal: false,
          closeOnPressEscape: false,
          inputValidator: input => {
            if (input !== this.application.name)
              return "application name invalid.";
            return true;
          }
        }
      )
        .then(() => {
          this.loading = true;
          this.deleteApplication(this.applicationId)
            .then(_ => {
              this.$message({
                title: "Success",
                message: "Service Application Removed",
                type: "success"
              });
              this.handleBack();
            })
            .catch(err => {
              this.$notify({
                title: "Error",
                message: "Service Application could not be removed!",
                type: "error"
              });
            })
            .finally(_ => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    handleRefreshApiKey() {
      this.$prompt(
        "Please enter the application name for confirmation.",
        "Refresh Api Key",
        {
          confirmButtonText: "OK, Refresh",
          cancelButtonText: "Cancel",
          closeOnClickModal: false,
          closeOnPressEscape: false,
          inputValidator: input => {
            if (input !== this.application.name)
              return "application name invalid.";
            return true;
          }
        }
      )
        .then(() => {
          this.loading = true;
          this.refreshApiKey({
            id: this.applicationId
          })
            .then(_ => {
              this.$notify({
                title: "Success",
                message: "New API key generated",
                type: "success"
              });
            })
            .catch(err => {
              this.$notify({
                title: "Error",
                message: "Could not generate new API key!",
                type: "error"
              });
            })
            .finally(_ => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    handleBack() {
      this.$router.push({
        name: "serviceApps.index"
      });
    },
    handleSubmit() {
      this.$refs["appForm"].validate(valid => {
        if (valid) {
          this.loading = true;
          this.updateApplication({
            id: this.applicationId,
            data: this.application
          })
            .then(res => {
              this.$notify({
                title: "Success",
                message: "Service Application Updated",
                type: "success"
              });
              this.handleBack();
            })
            .catch(err => {
              this.$notify({
                title: "Error",
                message: "Service Application could not be updated!",
                type: "error"
              });
            })
            .finally(_ => {
              this.loading = false;
            });
        } else {
          this.$notify({
            title: "Warning",
            message: "Please complete the form!",
            type: "warning"
          });
        }
      });
    }
  }
};
</script>

<style>
</style>
