import { getResource } from '@/helpers/rest-store-vuex';
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState('admin', ['currentAccountId']),
        currentAccount: getResource(cmp => cmp.currentAccountId, {
            namespace: 'accounts',
            condition: cmp => cmp.currentAccountId
        })
    }
}