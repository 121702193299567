<template>
  <table-page-layout>
    <page-title>Accounts</page-title>
    <el-row type="flex" :gutter="10" justify="space-between" class="xs-mb-10">
      <el-col :md="6">
        <el-add-button @click="handleNewAccount" :disabled="true">Account</el-add-button>
      </el-col>
      <el-col :md="18" class="text-right">
        <el-popover placement="bottom-end" width="300" trigger="click" v-model="filtersVisible">
          <filters @update="applyCustomFilters" :value="customFilters"/>
          <el-button plain slot="reference" class="xs-mr-5">
            <i class="fa fa-sliders-h xs-mr-10"></i>
            {{$t('Filters')}}
            <i class="fa fa-caret-down xs-ml-10"></i>
          </el-button>
        </el-popover>
        <el-input
          suffix-icon="fa fa-search"
          :placeholder="`${$t('Search Accounts')}...`"
          v-model="search"
          clearable
          style="width:400px;"
        ></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="m-t-1">
      <el-col :md="24">
        <el-card shadow="never">
          <el-table
            :data="accounts"
            stripe
            size="small"
            v-loading="isLoading(accounts)"
            :empty-text="$t('No Accounts')"
            @sort-change="handleSortChange"
          >
            <el-table-column width="40" align="center">
              <template slot-scope="{ row }">
                <i
                  class="fa fa-circle"
                  :class="{'text-success': row.is_active, 'text-danger': !row.is_active}"
                ></i>
              </template>
            </el-table-column>
            <el-table-column :label="$t('Name')" sortable="custom" prop="company_name">
              <template slot-scope="{ row }">
                <span class="text-small pointer" @click="showAccount(row)">{{row.company_name}}</span>
                <el-tag
                  type="primary"
                  size="mini"
                  class="xs-ml-10 text-uppercase"
                  :disable-transitions="true"
                  v-if="row.is_demo_account"
                >demo account</el-tag>
              </template>
            </el-table-column>
            <el-table-column :label="$t('Locations')" width="100" align="center">
              <template slot-scope="{ row }">
                <span class="text-small">{{row.total_locations}}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Date Created')"
              width="120"
              sortable="custom"
              prop="created_at"
            >
              <template slot-scope="{ row }">
                <span class="text-small">{{row.created_at | date}}</span>
              </template>
            </el-table-column>

            <el-table-column width="60" align="center">
              <template slot-scope="{ row }">
                <el-button type="text" @click="showAccount(row)">
                  <i class="fa fa-search"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="xs-mt-20 xs-mb-10">
      <el-col :md="24">
        <el-pagination
          :disabled="isLoading(accounts)"
          class="text-center"
          style="margin-bottom:20px;"
          background
          layout="prev, pager, next"
          :current-page="page"
          :page-count="pages"
          @current-change="onPageChange"
        />
      </el-col>
    </el-row>
  </table-page-layout>
</template>

<script>
import { getPage } from "@/helpers/rest-store-vuex";
import { mapState } from "vuex";
import { isOK, isLoading } from "@jorgenevens/rest-store";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import reject from "lodash/reject";
import pickBy from "lodash/pickBy";
import { search } from "@/mixins";

import Filters from "./Filters";
import ElAddButton from "@/components/ui/add-button";
import PageTitle from "@/components/ui/page-title";

export default {
  mixins: [search],
  components: {
    Filters,
    ElAddButton,
    PageTitle
  },
  data() {
    return {
      page: parseInt(this.$route.query.page) || 1,
      needle: this.$route.query.needle || "",
      search: this.$route.query.needle || "",
      pageSize: 20,
      sort: "company_name",
      customFilters: {
        is_active: this.$route.query.is_active,
        is_demo_account: this.$route.query.is_demo_account
      },
      filtersVisible: false
    };
  },
  watch: {
    filterQueryParams: "setQueryParams"
  },
  methods: {
    isLoading,
    isEmpty,
    setQueryParams() {
      if (this.filteringIsActive) {
        this.page = 1;
        this.$router.push({
          query: {
            page: this.page,
            ...this.filterQueryParams
          }
        });
      } else {
        this.$router.push({
          query: {
            page: this.page
          }
        });
      }
    },
    onPageChange(_page) {
      this.page = _page;
      this.$router.push({
        query: {
          page: _page,
          ...this.filterQueryParams
        }
      });
      this.setQueryParams();
    },
    showAccount(account) {
      this.$router.push({
        name: "account.dashboard",
        params: {
          accountId: account.id
        }
      });
    },
    handleNewAccount() {},
    applyCustomFilters(_filters) {
      this.customFilters = cloneDeep(_filters);
      this.filtersVisible = false;
    },
    handleSortChange({ column, prop, order }) {
      if (order === "ascending") this.sort = `${prop}`;
      if (order === "descending") this.sort = `-${prop}`;
      if (order === null) this.sort = "company_name";
    }
  },
  computed: {
    ...mapState("admin", ["currentAccountId"]),
    listName() {
      const whiteListed = ["needle", "is_active", "is_demo_account"];
      let filter = Object.keys(this.filters).map(key => {
        if (whiteListed.includes(key) && this.filters[key] !== undefined) {
          if (typeof this.filters[key] === "boolean")
            return `${key}-${this.filters[key]}`;
          return String(this.filters[key])
            .split(",")
            .join("_");
        }
      });
      filter = reject(filter, isEmpty).join("_");
      if (this.filteringIsActive) return `search_${filter}`;
      return `all`;
    },
    filteringIsActive() {
      return (
        !isEmpty(this.needle) ||
        this.customFilters.is_active !== undefined ||
        this.customFilters.is_demo_account !== undefined
      );
    },
    filterQueryParams() {
      const whiteListed = ["needle", "is_active", "is_demo_account"];

      return pickBy(this.filters, (val, key) => {
        if (typeof val === "boolean") return whiteListed.includes(key);
        return !isEmpty(val) && whiteListed.includes(key);
      });
    },
    filters() {
      let _filters = {};

      if (!isEmpty(this.needle)) _filters["needle"] = this.needle;
      _filters["is_active"] = this.customFilters.is_active;
      _filters["is_demo_account"] = this.customFilters.is_demo_account;

      if (!isEmpty(this.currentAccountId))
        _filters["id"] = this.currentAccountId;

      return _filters;
    },
    pages() {
      const total = this.$store.state.accounts.list[this.listName].total;
      return Math.ceil(total / this.pageSize);
    },
    accounts: getPage(cmp => cmp.page, cmp => cmp.listName, {
      namespace: "accounts",
      params: cmp => ({
        pageSize: cmp.pageSize,
        filters: cmp.filters,
        sort: cmp.sort
      })
    })
  }
};
</script>

<style>
</style>
