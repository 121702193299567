<template>
  <div>
    <el-row type="flex" justify="center" class="xs-mb-10">
      <el-input
        suffix-icon="fa fa-search"
        :placeholder="`${$t('Search Accounts')}...`"
        v-model="search"
        clearable
        style="width:100%;"
      ></el-input>
    </el-row>
    <el-row :gutter="10" class="m-t-1">
      <el-col :md="24">
        <el-table
          :data="accounts"
          size="small"
          v-loading="isLoading(accounts)"
          :empty-text="$t('No Accounts')"
          height="250"
          :show-header="false"
          :highlight-current-row="true"
          @current-change="handleCurrentChange"
        >
          <el-table-column :label="$t('Name')">
            <template slot-scope="{ row }">
              <div class="text-small pointer">{{row.company_name}}</div>
              <el-tag
                type="primary"
                size="mini"
                class="xs-ml-10 text-uppercase"
                :disable-transitions="true"
                v-if="row.is_demo_account"
              >demo account</el-tag>
            </template>
          </el-table-column>
          <el-table-column width="60" align="center">
            <template slot-scope="{ row }">
              <i v-if="accountIsSelected(row)" class="far fa-check-square text-primary"></i>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row type="flex" justify="space-between" :gutter="10" class="xs-mt-10 xs-mb-5">
      <div>
        <div
          @click="resetCurrentAccount"
          class="pointer"
          v-if="isOK(currentAccount)"
          style="line-height:30px;"
        >
          <i class="far fa-times-circle xs-ml-15 text-info"></i> Clear
        </div>
      </div>
      <el-pagination
        :disabled="isLoading(accounts)"
        class="text-center"
        background
        layout="prev, pager, next"
        :current-page="page"
        :page-count="pages"
        @current-change="onPageChange"
      />
    </el-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { getPage } from "@/helpers/rest-store-vuex";
import search from "@/mixins/search";
import currentAccountMixin from "@/mixins/current_account";

import { isOK, isLoading } from "@jorgenevens/rest-store";
import isEmpty from "lodash/isEmpty";
import reject from "lodash/reject";
import pickBy from "lodash/pickBy";

export default {
  mixins: [search, currentAccountMixin],
  data() {
    return {
      page: 1,
      pageSize: 20,
      sort: "company_name"
    };
  },

  methods: {
    isLoading,
    isEmpty,
    isOK,
    ...mapActions("admin", ["setCurrentAccount"]),
    onPageChange(_page) {
      this.page = _page;
    },
    handleCurrentChange(account) {
      if (account) this.setCurrentAccount(account.id);
      this.$emit("on-select");
    },
    resetCurrentAccount() {
      this.setCurrentAccount(null);
      this.$emit("on-select");
    },
    accountIsSelected(account) {
      return account.id === this.currentAccountId;
    }
  },
  computed: {
    ...mapState("admin", ["currentAccountId"]),

    listName() {
      const whiteListed = ["needle"];
      let filter = Object.keys(this.filters).map(key => {
        if (whiteListed.includes(key) && this.filters[key] !== undefined) {
          if (typeof this.filters[key] === "boolean")
            return `${key}-${this.filters[key]}`;
          return String(this.filters[key])
            .split(",")
            .join("_");
        }
      });
      filter = reject(filter, isEmpty).join("_");
      if (this.filteringIsActive) return `current_account_search_${filter}`;
      return `current_account_all`;
    },
    filteringIsActive() {
      return !isEmpty(this.needle);
    },
    filters() {
      let _filters = {};

      if (!isEmpty(this.needle)) _filters["needle"] = this.needle;

      return _filters;
    },
    pages() {
      const total = this.$store.state.accounts.list[this.listName].total;
      return Math.ceil(total / this.pageSize);
    },
    accounts: getPage(cmp => cmp.page, cmp => cmp.listName, {
      namespace: "accounts",
      params: cmp => ({
        pageSize: cmp.pageSize,
        filters: cmp.filters,
        sort: cmp.sort
      })
    })
  }
};
</script>
<style lang="scss" scoped>
.current-row {
  background-color: red;
}
</style>

