import _Promise from 'babel-runtime/core-js/promise';
import JsonApiParser from '../parser/json-api-parser';

var isRefreshing = false;
var refreshSubscribers = [];

function getError(err) {
    if (err === undefined) return;
    return Array.isArray(err) && err.length > 0 ? err[0] : err;
}

function isTokenExpiredError(err) {
    if (err === undefined) return false;
    var code = Array.isArray(err) && err.length > 0 ? err[0].code : err.code;
    console.log("Error code", code);
    return code && code === 'doorkeeper:invalid_token.expired';
}

export default function (client, error) {
    console.log(error);
    var config = error.config,
        response = error.response;
    // service is unreachable

    if (response === undefined) return 'network.error';

    var status = response.status;
    var originalRequest = config;

    var contentType = response.headers['content-type'];
    var err = null;
    if (contentType && contentType.includes('application/vnd.api+json')) err = getError(new JsonApiParser(response.data).parseError());

    if (status === 401) {
        if (isTokenExpiredError(err)) {
            if (!isRefreshing) {
                isRefreshing = true;

                if (client.token) {
                    client.token.refresh().then(function (newToken) {
                        isRefreshing = false;
                        refreshSubscribers.map(function (cb) {
                            return cb(newToken);
                        });
                        refreshSubscribers = [];
                    });
                }
            }

            var retryOrigReq = new _Promise(function (resolve, reject) {
                refreshSubscribers.push(function (token) {
                    // replace the expired token and retry
                    originalRequest.headers['Authorization'] = token.token_type + ' ' + token.access_token;
                    resolve(client.request(originalRequest));
                });
            });

            return retryOrigReq;
        }
    }

    return err;
}