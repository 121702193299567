<template>
  <div>
    <el-input @focus="openDialog" :placeholder="placeholder" v-model="inputValue" readonly>
      <i slot="suffix" class="fa fa-search xs-mr-10 pointer" @click="openDialog"/>
    </el-input>
    <el-dialog title="Select Account" :visible.sync="dialogOpen" width="500px">
      <select-account-dialog-body v-model="selectedValue" v-if="dialogOpen"/>
    </el-dialog>
  </div>
</template>

<script>
import { isLoading, isOK } from "@jorgenevens/rest-store";
import { getPage, getResource } from "@/helpers/rest-store-vuex";

import _debounce from "lodash/debounce";
import _isEmpty from "lodash/isEmpty";

import SelectAccountDialogBody from "./select-account-dialog-body";

export default {
  components: { SelectAccountDialogBody },
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: "Select Account"
    },
    pageSize: {
      type: Number,
      default: 10
    },
    filters: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      query: undefined,
      dialogOpen: false
    };
  },

  methods: {
    isLoading,
    isOK,
    openDialog() {
      this.dialogOpen = true;
      console.log("open dialog");
    },
  },

  watch: {
    selectedValue() {
      this.dialogOpen = false
    }
  },

  computed: {
    inputValue() {
      if (this.selectedValue && isOK(this.account)) {
        return this.account.company_name;
      }
      return undefined;
    },
    account: getResource(cmp => cmp.selectedValue, {
      namespace: "accountsList",
      condition: cmp => cmp.selectedValue
    }),

    selectedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  }
};
</script>
