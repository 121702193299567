<template>
  <table-page-layout>
    <el-row :gutter="10" class="m-t-1">
      <el-col :md="24">
        <el-card shadow="never">
          <el-table
            :data="operatorintegrations"
            stripe
            size="small"

            :empty-text="$t('No modules')"
          >
            <el-table-column
              :label="$t('Integration name')"
              sortable="custom"
              prop="integrationname"
              width="220"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="{ row }">
                {{ row.integration.title }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Integration name')"
              prop="module_nm"
              width="220"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="{ row }">
                {{ row.integration.title }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Start date')"
              prop="module_nm"
              width="220"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="{ row }">
                {{ row.created_at }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Requested date')"
              prop="module_nm"
              width="220"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="{ row }">
                {{ row.date_status_changed }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Billable')"
              align="center"
              width="180"
              sortable="custom"
              prop="billable"
            >
              <template slot-scope="{ row }">
                <i
                  class="fa fa-circle"
                  :class="{'text-success': row.billable, 'text-danger': !row.billable}"
                ></i>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Status')"
              align="center"
              width="180"
              sortable="custom"
              prop="billable"
            >
              <template slot-scope="{ row }">
                {{ row.status }}
              </template>
            </el-table-column>
            <el-table-column width="60" align="center">
              <template slot-scope="{ row }">
                <el-button type="text" @click="show(row)">
                  <i class="fa fa-search"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    </el-row>
  </table-page-layout>
</template>

<script>
import { getPage } from "@/helpers/rest-store-vuex";
import { isOK, isLoading } from "@jorgenevens/rest-store";
import { search } from "@/mixins";

import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import reject from "lodash/reject";
import pickBy from "lodash/pickBy";

import ElAddButton from "@/components/ui/add-button";
import PageTitle from "@/components/ui/page-title";
import Resource from "@/components/app/resource";

export default {
  components: {
    ElAddButton,
    PageTitle,
    Resource
  },
  props: {
    showPageTitle: {
      type: Boolean,
      default: true
    },
    pageTitle: {
      type: String,
      default: "Modules"
    }
  },
  data() {
    return {
      page: parseInt(this.$route.query.page) || 1,
      pageSize: 20,
    };
  },
  watch: {
  },
  methods: {
    isLoading,
    isEmpty,
    show(ob) {
      this.$router.push({
        name: "integration.detail",
        params: {
          accountIntegrationId: ob.id,
        }
      });
    },
  },
  computed: {
    accountId() {
      return this.$route.params.accountId;
    },
    listName() {
      return `aaall`;
    },
    pages() {
      const total = this.$store.state.operatormodules.list[this.listName].total;
      return Math.ceil(total / this.pageSize);
    },
    operatorintegrations: getPage(cmp => cmp.page, cmp => cmp.listName, {
      namespace: "accountintegrations",
      params: cmp => ({
        pageSize: cmp.pageSize,
        filters:{
          account_id: cmp.accountId,
          billable: true
        }
      })
    })
  }
};
</script>
