<template>
  <el-container direction="vertical">
    <page-title>Edit Branding</page-title>

    <el-card shadow="never">
      <el-form
        ref="brandingForm"
        v-loading="loading"
        label-position="left"
        label-width="150px"
        :model="branding_data"
        :rules="rules"
      >
        <el-form-item label="Custom domain" prop="domain">
          <el-input v-model="domain"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" plain @click="handleSubmit">
        <i class="fa fa-save xs-mr-10"></i>Save
      </el-button>
    </el-card>
  </el-container>
</template>

<script>
import { getPage } from "@/helpers/rest-store-vuex";
import PageTitle from "@/components/ui/page-title";
import createModel from "@/util/model";

import { mapActions } from "vuex";

import { isLoading } from "@jorgenevens/rest-store";

export default {
  components: {
    PageTitle,
  },
  data() {
    return {
      branding_data: {},
      rules: {},
    };
  },
  computed: {
    loading() {
      return isLoading(this.branding);
    },
    accountId() {
      return this.$route.params.accountId;
    },

    brandings: getPage(
      () => 1,
      () => "all-brandings",
      {
        namespace: "branding",
        params: (cmp) => ({
          pageSize: 50,
          filters: {
            account_id: cmp.accountId,
          },
        }),
      }
    ),
    branding() {
      return this.brandings[0] || {};
    },
    ...createModel("branding", "branding_data", ["domain"]),
  },
  methods: {
    ...mapActions("branding", {
      updateBranding: "update",
    }),
    handleSubmit() {
      this.updateBranding({ id: this.accountId, ...this.branding_data });
    },
  },
};
</script>
