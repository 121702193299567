import oauthApplicationsOverview from '@/views/oauthApplications/Overview/Index.vue';
import NewClientApplication from '@/views/oauthApplications/NewApplication/Index.vue';
import EditClientApplication from '@/views/oauthApplications/EditApplication/Index.vue';

export default [
    {
        path: '/client-applications',
        name: 'oauthApplications.index',
        component: oauthApplicationsOverview,
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },
    {
        path: '/client-applications/new',
        name: 'oauthApplications.new',
        component: NewClientApplication,
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },
    {
        path: '/client-applications/:applicationId',
        name: 'oauthApplications.edit',
        component: EditClientApplication,
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },
]