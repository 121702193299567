<template>
    <el-select
      placeholder="Select Country"
      v-bind="$attrs"
      clearable
      v-model="selectedValue"
      filterable
      :loading="isLoading(countries)"
      optionLabel="name"
      optionKey="id"
    >
      <el-option v-for="item in countries" :key="item.id" :label="item.name" :value="item.id"/>
    </el-select>

</template>

<script>
import { isLoading } from "@jorgenevens/rest-store";
import getAll from "@/store/helpers/get-all";

export default {
  props: {
    value: {
      type: String | Number
    }
  },

  data() {
    return {
      query: undefined
    };
  },

  methods: {
    isLoading
  },

  watch: {
    countries(v) {
      this.$emit("updated:loading", isLoading(v));
    }
  },

  computed: {
    listName() {
      return "all";
    },

    countries: getAll(cmp => cmp.listName, {
      namespace: "core/countries"
    }),

    selectedValue: {
      get() {
        return isLoading(this.countries) ? undefined : this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  }
};
</script>
