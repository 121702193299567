import { ZfHqApi } from '../helpers/vue-shared-api'
import store from '../store'


var zfhqClient = new ZfHqApi({
    clientId: "0b07c638595d88754264a7d26cb4797f09dec8db6ec6a513f8d434ad093b8d53",
    clientSecret: "66f108205c75d4197bb8a67f5e5ea232c6031d194e11e37ef1ad6e02cbb76b71",
    baseURL: process.env.VUE_APP_API_BASE,
    tokenURI: 'oauth/token',
    scopes: ["ALL:ACCESS"]
});

zfhqClient.onTokenChanged = function (token) {
    store.dispatch("auth/setToken", token.data)
};

zfhqClient.onError = function (err) {
    console.error("onError", err)
};

export default zfhqClient