<template>
  <el-select
    placeholder="Select Application"
    v-bind="$attrs"
    clearable
    v-model="selectedValue"
    filterable
    :filter-method="search"
    :loading="isLoading(applications)"
    optionLabel="name"
    optionKey="id"
  >
    <el-option v-for="item in applications" :key="item.id" :label="item.name" :value="item.id">
      <el-row type="flex" justify="space-between">
        {{ item.name }}
        <span class="text-smaller">{{ item.type }}</span>
      </el-row>
    </el-option>
  </el-select>
</template>

<script>
import { isLoading } from "@jorgenevens/rest-store";
import { getPage } from "@/helpers/rest-store-vuex";

import _debounce from "lodash/debounce";
import _isEmpty from "lodash/isEmpty";

export default {
  props: {
    value: {
      type: String
    },
    pageSize: {
      type: Number,
      default: 50
    },
    filters: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      query: undefined
    };
  },

  methods: {
    isLoading,

    search: _debounce(function(query) {
      this.query = query || undefined;
    }, 500)
  },

  watch: {
    applications(v) {
      this.$emit("updated:loading", isLoading(v));
    }
  },

  computed: {
    listName() {
      if (!_isEmpty(this.query)) return `select-${this.query}`;
      return "select";
    },
    applications: getPage(() => 1, cmp => cmp.listName, {
      namespace: "oauthApplications",
      condition: cmp => cmp.value || !cmp.$attrs.disabled,
      params: cmp => ({
        pageSize: cmp.pageSize,
        filters: {
          ...cmp.filters,
          needle: cmp.query
        }
      })
    }),

    selectedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  }
};
</script>
