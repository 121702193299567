<template>
  <div>
    <page-title>New Service Application</page-title>
    <el-row type="flex" justify="space-between" class="xs-mb-10">
      <div>
        <el-button plain @click="handleBack" :disabled="loading">
          <i class="fa fa-arrow-circle-left xs-mr-10"></i>Back
        </el-button>
      </div>
      <div>
        <el-row type="flex" justify="end">
          <el-button plain @click="handleReset" :disabled="loading">
            <i class="fa fa-times xs-mr-10"></i>Reset
          </el-button>
          <el-button type="primary" plain @click="handleSubmit" :loading="loading">
            <i class="fa fa-save xs-mr-10"></i>Save
          </el-button>
        </el-row>
      </div>
    </el-row>

    <el-card shadow="never">
      <el-form
        label-position="left"
        label-width="150px"
        :model="application"
        :rules="rules"
        ref="appForm"
        v-loading="loading"
      >
        <el-form-item label="Application" prop="application_id">
          <select-oauth-app v-model="application.application_id" style="width:400px;"/>
        </el-form-item>
        <el-form-item label="Account" prop="account_id">
          <select-account v-model="application.account_id" style="width:400px;"/>
        </el-form-item>
        <el-form-item label="Name" prop="name">
          <el-input v-model="application.name" style="width:600px;" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="Active">
          <el-switch v-model="application.is_active"></el-switch>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { isOK, isLoading } from "@jorgenevens/rest-store";
import PageTitle from "@/components/ui/page-title";
import { getPage, getResource } from "@/helpers/rest-store-vuex";
import { mapActions } from "vuex";
import { TYPES } from "../helpers/consts";
import SelectAccount from "@/components/app/select-account";
import SelectOauthApp from "@/components/app/select-oauth-app";

export default {
  components: {
    PageTitle,
    SelectAccount,
    SelectOauthApp
  },
  data() {
    return {
      TYPES,
      application: {
        name: "",
        application_id: "",
        application_type: "",
        is_active: true,
        account_id: undefined,
        location_id: undefined
      },
      loading: false,
      rules: {
        name: [
          {
            required: true,
            message: "Name is required",
            trigger: "blur"
          }
        ],
        application_id: [
          {
            required: true,
            message: "Application is required",
            trigger: "blur"
          }
        ],
        account_id: [
          {
            required: true,
            message: "account is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  watch: {
    selectedAccount: {
      handler(v) {
        if (
          isOK(v) &&
          !isLoading(v) &&
          isOK(this.selectedApplication) &&
          !isLoading(this.selectedApplication)
        )
          this.application.name = `${this.selectedApplication.name} for ${
            this.selectedAccount.company_name
          }`;
      }
    },
    selectedApplication: {
      handler(v) {
        if (
          isOK(v) &&
          !isLoading(v) &&
          isOK(this.selectedAccount) &&
          !isLoading(this.selectedAccount)
        )
          this.application.name = `${this.selectedApplication.name} for ${
            this.selectedAccount.company_name
          }`;
      }
    }
  },
  computed: {
    selectedAccount: getResource(cmp => cmp.application.account_id, {
      namespace: "accounts",
      condition: cmp => cmp.application.account_id
    }),
    selectedApplication: getResource(cmp => cmp.application.application_id, {
      namespace: "oauthApplications",
      condition: cmp => cmp.application.application_id
    })
  },
  methods: {
    ...mapActions("serviceApps", {
      createApplication: "create"
    }),
    handleReset() {
      this.$refs["appForm"].resetFields();
      this.application = {
        name: "",
        application_type: "",
        is_active: true,
        account_id: undefined,
        location_id: undefined
      };
    },
    handleBack() {
      this.$router.push({
        name: "serviceApps.index"
      });
    },
    handleSubmit() {
      this.$refs["appForm"].validate(valid => {
        if (valid) {
          this.loading = true;
          this.createApplication({
            data: this.application
          })
            .then(res => {
              this.$notify({
                title: "Success",
                message: "Application Created",
                type: "success"
              });
              this.handleBack();
            })
            .catch(err => {
              this.$notify({
                title: "Error",
                message: "Application could not be created!",
                type: "error"
              });
            })
            .finally(_ => {
              this.loading = false;
            });
        } else {
          this.$notify({
            title: "Warning",
            message: "Please complete the form!",
            type: "warning"
          });
          return false;
        }
      });
    }
  }
};
</script>

<style>
</style>
