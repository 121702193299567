// Register each file as a corresponding Vuex module. Module nesting
// will mirror [sub-]directory hierarchy and modules are namespaced
// as the camelCase equivalent of their file name.

import {
  some, includes, camelCase, sortBy,
} from 'lodash';

// https://webpack.js.org/guides/dependency-management/#require-context
const requireModule = require.context(
  // Search for files in the current directory
  '.',
  // Search for files in subdirectories
  true,
  // Include any .js files that are not unit tests, actions, mutations, getters or state
  /^((?!(unit|getters|actions|mutations|state)\.).)*\.js$/,
);
const root = { modules: {} };

/*
  sort by file names per module
  example:
 
  index.js
  customers/files/index.js
  customers/index.js
 
  customers/files/index.js is processed before customers/index.js
  therefore module customers/files is not included in customers module
 
  so we sort the list based on subdirectory count
 
  index.js
  customers/index.js
  customers/files/index.js
*/
const files = sortBy(requireModule.keys(), el => el.split('/').length);


files.forEach((fileName) => {
  // Skip this file, as it's not a module
  if (fileName === './' || fileName === './index.js') return;

  // Get the module path as an array
  const modulePath = fileName
    // Remove the "./" from the beginning
    .replace(/^\.\//, '')
    // Remove the file extension from the end
    .replace(/\.\w+$/, '')
    // Split nested modules into an array path
    .split(/\//)
    // camelCase all module namespaces and names
    .map(camelCase);

  // remove if fileName is index (module in directory)
  if (modulePath[modulePath.length - 1] === 'index') {
    modulePath.pop();
  }


  // Get the modules object for the current path
  const { modules } = getNamespace(root, modulePath);

  // Add the module to our modules object
  modules[modulePath.pop()] = {
    // Modules are namespaced by default
    namespaced: true,
    ...requireModule(fileName),
  };

  // Recursively get the namespace of the module, even if nested
  function getNamespace(subtree, path) {
    if (path.length === 1) return subtree;

    const namespace = path.shift();
    subtree.modules[namespace] = {
      // namespace directories
      namespaced: true,
      modules: {},
      ...subtree.modules[namespace],
    };
    return getNamespace(subtree.modules[namespace], path);
  }
});


export default root.modules;