import { apply } from '@jorgenevens/rest-store/vuex';
import { attach } from '@jorgenevens/rest-store';
import zfhqClient from '@/config/zfhq-api'


import { fetchResource, updateResource } from '../helpers/actions';
import _mutations from '../helpers/mutations';


export const mutations = {
    ..._mutations
};

export const state = {
    ...apply(attach)
};

export const actions = {
	fetch: fetchResource(({ id }) => zfhqClient.get(`v5/locations/${id}/settings`)),

	// // depricated
	// update: updateResource(options => {
	// 	const { id, ...settings } = options;
	// 	return zfhqClient.put(`v5/locations/${id}/settings`, { settings });
	// }),

	update: updateResource(options => {
        const { id, settings } = options;
        console.log("options", options)
		return zfhqClient.put(`v5/locations/${id}/settings`, { settings });
	}),
}