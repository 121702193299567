import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';


const config = {
    fieldsBagName: 'veeValidateFields',
    classes: true,
    classNames: {
        valid: 'ok',
        invalid: 'wrong',
    },
};


Vue.use(VeeValidate, config);