import memoize from 'lodash/memoize';

export const loadCumulio = memoize(function loadCumulio() {
    (function(d, a, s, h, b, oa, rd) {
      if (!d[b]) {oa = a.createElement(s), oa.async = 1;
      oa.src = h; rd = a.getElementsByTagName(s)[0];
      rd.parentNode.insertBefore(oa, rd);} d[b]=d[b]||{};
      d[b].addDashboard=d[b].addDashboard||function(v) {
      (d[b].list = d[b].list || []).push(v) };
    })(window, document, 'script',
      'https://cdn-a.cumul.io/js/cumulio.min.js', 'Cumulio');
});

export const DASHBOARDS = {
    'revenue': 'b6587dc8-7a29-48da-addd-4b73102a760b',
    'products': 'dc79d59c-8885-49bf-96b8-e235eab430ce',
    'reservations': 'a812a246-f039-4669-942e-43094b431922',
    'visitors': 'f9171356-61cf-45ef-9ff7-0479fcaf3fdb',
    'standard_contracts': '1a62fdec-a895-44a0-90c1-46a76e69f525',
    'invoiced_contracts': '6bb585a7-96f1-4bd4-bf1e-e1cce9bc8b44'
};
