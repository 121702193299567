<template>
  <table-page-layout>
    <page-title v-if="showPageTitle">{{pageTitle}}</page-title>
    <el-row type="flex" :gutter="10" justify="space-between" class="xs-mb-10">
      <el-col :md="6">
        <el-add-button @click="handleNewUser">User</el-add-button>
      </el-col>
      <el-col :md="18" class="text-right">
        <el-input
          suffix-icon="fa fa-search"
          :placeholder="`${$t('Search Users')}...`"
          v-model="search"
          clearable
          style="width:400px;"
        ></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="m-t-1">
      <el-col :md="24">
        <el-card shadow="never">
          <el-table
            :data="users"
            stripe
            size="small"
            v-loading="isLoading(users)"
            :empty-text="$t('No Users')"
            @sort-change="handleSortChange"
          >
            <el-table-column width="40" align="center">
              <template slot-scope="{ row }">
                <i
                  class="fa fa-circle"
                  :class="{'text-success': row.is_active, 'text-danger': !row.is_active}"
                ></i>
              </template>
            </el-table-column>
            <el-table-column :label="$t('Name')">
              <template slot-scope="{ row }">
                <span
                  class="text-small pointer"
                  @click="showUser(row)"
                >{{row.firstname}} {{row.lastname}}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('Username')" width="250">
              <template slot-scope="{ row }">
                <span class="text-small">{{row.username}}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('Email')" width="250">
              <template slot-scope="{ row }">
                <span class="text-small">{{row.email}}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('Role')" width="150">
              <template slot-scope="{ row }">
                <span class="text-small">{{row.role}}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Date Created')"
              width="120"
              sortable="custom"
              prop="created_at"
            >
              <template slot-scope="{ row }">
                <span class="text-small">{{row.created_at | date}}</span>
              </template>
            </el-table-column>

            <el-table-column width="60" align="center">
              <template slot-scope="{ row }">
                <el-button type="text" @click="showUser(row)">
                  <i class="fa fa-search"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="xs-mt-20 xs-mb-10">
      <el-col :md="24">
        <el-pagination
          :disabled="isLoading(users)"
          class="text-center"
          style="margin-bottom:20px;"
          background
          layout="prev, pager, next"
          :current-page="page"
          :page-count="pages"
          @current-change="onPageChange"
        />
      </el-col>
    </el-row>
  </table-page-layout>
</template>

<script>
import { getPage } from "@/helpers/rest-store-vuex";
import { isOK, isLoading } from "@jorgenevens/rest-store";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import reject from "lodash/reject";
import pickBy from "lodash/pickBy";
import { search } from "@/mixins";

import ElAddButton from "@/components/ui/add-button";
import PageTitle from "@/components/ui/page-title";

export default {
  mixins: [search],
  components: {
    ElAddButton,
    PageTitle
  },
  props: {
    showPageTitle: {
      type: Boolean,
      default: true
    },
    pageTitle: {
      type: String,
      default: "users"
    },
    roles: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      page: parseInt(this.$route.query.page) || 1,
      needle: this.$route.query.needle || "",
      search: this.$route.query.needle || "",
      pageSize: 20,
      sort: "firstname,lastname",
      customFilters: {
        is_active: this.$route.query.is_active
      },
      filtersVisible: false
    };
  },
  watch: {
    filterQueryParams: "setQueryParams"
  },
  methods: {
    isLoading,
    isEmpty,
    setQueryParams() {
      if (this.filteringIsActive) {
        this.page = 1;
        this.$router.push({
          query: {
            page: this.page,
            ...this.filterQueryParams
          }
        });
      } else {
        this.$router.push({
          query: {
            page: this.page
          }
        });
      }
    },
    onPageChange(_page) {
      this.page = _page;
      this.$router.push({
        query: {
          page: _page,
          ...this.filterQueryParams
        }
      });
      this.setQueryParams();
    },
    showUser(user) {
      //   this.$router.push({
      //     name: "users.show",
      //     params: {
      //       userId: user.id
      //     }
      //   });
    },
    handleNewUser() {
      if(this.$route.name === "users.admin.index"){
        this.$router.push({name: 'users.admin.new'})
      }else{
        this.$router.push({name: 'users.new'})
      }
    },
    applyCustomFilters(_filters) {
      this.customFilters = cloneDeep(_filters);
      this.filtersVisible = false;
    },
    handleSortChange({ column, prop, order }) {
      if (order === "ascending") this.sort = `${prop}`;
      if (order === "descending") this.sort = `-${prop}`;
      if (order === null) this.sort = "company_name";
    }
  },
  computed: {
    accountId() {
      return this.$route.params.accountId;
    },
    listName() {
      const whiteListed = ["needle", "is_active", "role"];
      let filter = Object.keys(this.filters).map(key => {
        if (whiteListed.includes(key) && this.filters[key] !== undefined) {
          if (typeof this.filters[key] === "boolean")
            return `${key}-${this.filters[key]}`;
          return String(this.filters[key])
            .split(",")
            .join("_");
        }
      });
      filter = reject(filter, isEmpty).join("_");
      if (this.filteringIsActive) return `search_${filter}`;
      return `all`;
    },
    filteringIsActive() {
      return (
        !isEmpty(this.needle) || this.customFilters.is_active !== undefined
      );
    },
    filterQueryParams() {
      const whiteListed = ["needle", "is_active"];

      return pickBy(this.filters, (val, key) => {
        if (typeof val === "boolean") return whiteListed.includes(key);
        return !isEmpty(val) && whiteListed.includes(key);
      });
    },
    filters() {
      let _filters = {};
      if(!isEmpty(this.roles))
        _filters['role']= this.roles.join(',')

      if (!isEmpty(this.needle)) _filters["needle"] = this.needle;
      _filters["is_active"] = this.customFilters.is_active;
      
      if (!isEmpty(this.accountId))
        _filters["organisation_id"] = this.accountId;
      return _filters;
    },
    pages() {
      const total = this.$store.state.users.list[this.listName].total;
      return Math.ceil(total / this.pageSize);
    },
    users: getPage(cmp => cmp.page, cmp => cmp.listName, {
      namespace: "users",
      params: cmp => ({
        pageSize: cmp.pageSize,
        filters: cmp.filters,
        sort: cmp.sort
      })
    })
  }
};
</script>

<style>
</style>
