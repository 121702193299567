var Buffer = require('safe-buffer').Buffer;

var btoa = window.btoa;
if (typeof Buffer === 'function') {
    btoa = function btoa(string) {
        return Buffer.from(string).toString('base64');
    };
}

export var BasicAuth = function BasicAuth(username, password) {
    return 'Basic ' + btoa(username + ":" + password);
};

export var BearerAuth = function BearerAuth(token) {
    return 'Bearer ' + token;
};