var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{attrs:{"direction":"vertical"}},[_c('el-row',{staticClass:"xs-mb-20",attrs:{"type":"flex","justify":"space-between"}},[_c('el-link'),_c('el-menu',{attrs:{"default-active":_vm.childPath,"router":"","mode":"horizontal","active-text-color":"#009fe1"}},[_c('el-menu-item',{attrs:{"index":"/","route":{
          name: 'account.dashboard',
          params: { accountId: _vm.accountId },
        }}},[_c('i',{staticClass:"fa fa-home xs-mr-10"}),_vm._v("Dashboard\n      ")]),_c('el-menu-item',{attrs:{"index":"/integrations","route":{
          name: 'integrations.overview',
          params: { accountId: _vm.accountId },
        }}},[_c('i',{staticClass:"fa fa-info-circle xs-mr-10"}),_vm._v("Integrations\n      ")]),_c('el-menu-item',{attrs:{"index":"/modules","route":{
          name: 'modules.overview',
          params: { accountId: _vm.accountId },
        }}},[_c('i',{staticClass:"fa fa-info-circle xs-mr-10"}),_vm._v("Modules\n      ")]),_c('el-menu-item',{attrs:{"index":"/branding","route":{
          name: 'account.branding',
          params: { accountId: _vm.accountId },
        }}},[_c('i',{staticClass:"fa fa-paint-roller xs-mr-10"}),_vm._v("Branding\n      ")]),_c('el-menu-item',{attrs:{"index":"/users","route":{ name: 'account.users', params: { accountId: _vm.accountId } }}},[_c('i',{staticClass:"fa fa-users xs-mr-10"}),_vm._v("Users\n      ")]),_c('el-menu-item',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"index":"/locations"}},[_c('i',{staticClass:"fa fa-map-marker-alt xs-mr-10"}),_vm._v("Locations\n      ")])],1)],1),_c('router-view',{key:_vm.$route.fullPath})],1)}
var staticRenderFns = []

export { render, staticRenderFns }