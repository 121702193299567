import Vue from 'vue'

function loadForms(r) {
    r.keys().forEach(key => {
        // Get the module path as an array
        const name = key
            // Remove the "./" from the beginning
            .replace(/^\.\//, '')
            // Remove the file extension from the end
            .replace(/.vue/, '')

        const pathName = _.kebabCase(name)
        const comp = r(key).default
        if (comp)
            Vue.component(pathName, comp)
    })
}

loadForms(require.context('@/components/layouts', false, /^((?!(index)\.).)*\Layout.vue$/));