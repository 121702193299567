
import "./helpers/polyfill";

import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store/index.js";

import { sync } from "vuex-router-sync";
const unsync = sync(store, router);

// PLUGINS
import i18n from "./plugins/i18n.js";
import "./plugins/element.js";
import "./plugins/simpleBreakPoint";
import "./plugins/vue-clipboard";
import './plugins/vee-validate'

// global style
import "./assets/scss/global.scss";

// global layout components
import "./config/layouts";

import "./registerServiceWorker";

import filters from '@/config/filters'
Vue.use(filters)

Vue.prototype.$redirectBack = () => {
  const from = store.state.route.from;
  if (from === undefined) return router.push("/");
  const blackList = ["Login"];
  const path = _.includes(blackList, from.name) ? "/" : from.fullPath;
  router.push({ path: path });
};

Vue.config.productionTip = process.env.NODE_ENV !== "production";

Vue.directive("el-input-focus", {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    let inputs = el.getElementsByTagName("input");
    if (inputs && inputs.length > 0) inputs[0].focus();
  }
});

Vue.prototype.$APP_VERSION = APP_VERSION
Vue.prototype.$APP_NAME = APP_NAME

console.info('%c%s', 'color: #009fe1; font-weight: 600;', `${APP_NAME} v${APP_VERSION}`)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  methods: {
    showInfo(e) {
      var evtobj = window.event ? event : e
      if (evtobj.keyCode == 73 && evtobj.ctrlKey) //CTRL + i
        this.$alert(`${APP_NAME} v${APP_VERSION}`, "Application Info", {
          confirmButtonText: "OK"
        });
    }
  },
  created() {
    this.$i18n.locale = 'en';
    window.addEventListener('keyup', this.showInfo)
  },
  beforeDestroy() {
    unsync()
    window.removeEventListener('keyup', this.showInfo)
  }
}).$mount("#app");
