import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters("auth", {
            currentUser: "getCurrentUser"
        }),
        isOperator() {
            return (
                this.currentUser.role === "operator" ||
                this.currentUser.role === "operator_admin"
            );
        },
        isTenant() {
            return (
                this.currentUser.role === "teanant" ||
                this.currentUser.role === "teanant_admin"
            );
        },
    }
}