import { apply } from "@jorgenevens/rest-store/vuex";
import { attach } from "@jorgenevens/rest-store";
import zfhqClient from "@/config/zfhq-api";

import {
  fetchPage,
  fetchResource,
  createResource,
  updateResource,
} from "../helpers/actions";
import _mutations from "../helpers/mutations";

export const mutations = {
  ..._mutations,
};

export const state = {
  ...apply(attach),
};

export const actions = {
  fetch: fetchResource((options) => {
    const { id } = options;
    return zfhqClient.get(`v5/mail_types/${id}`);
  }),

  fetchPage: fetchPage((options) => {
    const { page = 1, pageSize = 50, sort = "name", filters } = options;
    return zfhqClient.get("v5/mail_types", {
      page: {
        size: pageSize,
        number: page,
      },
      sort,
      filters,
    });
  }),

  update: updateResource((options) => {
    const { id, mail_type } = options;
    return zfhqClient.patch(`v5/mail_types/${id}`, { mail_type });
  }),

  create: createResource((options = {}) => {
    const { ...data } = options;
    const url = "v5/mail_types";
    return zfhqClient.post(url, { data });
  }),
};
