<template>
  <div>
	<page-title>New Location</page-title>
	<el-row type="flex" justify="space-between" class="xs-mb-10">
	  <div>
		<el-button plain @click="handleBack" :disabled="loading">
		  <i class="fa fa-arrow-circle-left xs-mr-10"></i>Back
		</el-button>
	  </div>
	  <div>
		<el-row type="flex" justify="end">
		  <el-button plain @click="handleReset" :disabled="loading">
			<i class="fa fa-times xs-mr-10"></i>Reset
		  </el-button>
		  <el-button type="primary" plain @click="handleSubmit" :loading="loading">
			<i class="fa fa-save xs-mr-10"></i>Save
		  </el-button>
		</el-row>
	  </div>
	</el-row>

	<el-card shadow="never">
	  <el-form
		label-position="left"
		label-width="150px"
		:model="location"
		:rules="rules"
		ref="loactionForm"
		v-loading="loading"
	  >
		<el-form-item label="Account" prop="account_id">
		  <select-account v-model="location.account_id" style="min-width:300px;"/>
		</el-form-item>
		<el-form-item label="Billing Entity" prop="billing_entity_id">
		  <select-billing-entity :account-id="location.account_id" v-model="location.billing_entity_id" style="min-width:300px;"/>
		</el-form-item>
		<el-form-item label="Alias" prop="alias">
		  <el-input v-model="location.alias"></el-input>
		</el-form-item>
		<el-form-item label="Street" prop="addressline1">
		  <el-input v-model="location.addressline1"></el-input>
		</el-form-item>
		<el-form-item label="Number" prop="number"  style="width:300px;">
		  <el-input v-model="location.number"></el-input>
		</el-form-item>
		<el-form-item label="Street 2" prop="addressline2">
		  <el-input v-model="location.addressline2"></el-input>
		</el-form-item>
		<el-form-item label="Zipcode" prop="zipcode"  style="width:300px;">
		  <el-input v-model="location.zipcode"></el-input>
		</el-form-item>
		 <el-form-item label="City" prop="city">
		  <el-input v-model="location.city"></el-input>
		</el-form-item>
		<el-form-item label="State" prop="state">
		  <el-input v-model="location.state"></el-input>
		</el-form-item>
		<el-form-item label="Country" prop="countrycode">
		  <select-country v-model="location.countrycode" style="min-width:300px;"/>
		</el-form-item>
		<el-form-item label="Time Zone" prop="timezone">
		  <select-timezone v-model="location.timezone" style="min-width:300px;"/>
		</el-form-item>

		<el-form-item label="Is visible" prop="is_visible">
			<el-switch v-model="location.is_visible"></el-switch>
		</el-form-item>
		<el-form-item label="Manager" prop="manager_id">
			<select-user :customerId="location.account_id" v-model="location.manager_id" style="min-width:300px;"/>
		</el-form-item>
	  </el-form>
	</el-card>
	{{location}}
  </div>
</template>

<script>
import PageTitle from "@/components/ui/page-title";
import { mapActions } from "vuex";
import SelectAccount from "@/components/app/select-account";
import SelectCountry from "@/components/app/select-country";
import SelectBillingEntity from "@/components/app/select-billing-entity";
import SelectUser from "@/components/app/select-user";
import SelectTimezone from "@/components/app/select-timezone";
import MultiTagInput from "@/components/ui/multi-tag-input";

export default {
  components: {
	PageTitle,
	SelectAccount,
	SelectCountry,
	SelectBillingEntity,
	MultiTagInput,
	SelectTimezone,
	SelectUser
  },
  data() {
	return {
	  location: {
		alias: "",
		addressline1: "",
		number: "",
		addressline2: "",
		zipcode: "",
		city: "",
		countrycode: "",
		account_id: undefined,
		latitude: 0,
		longitude: 0,
		is_visible: true,
		manager_id: "",
		timezone: "Europe/Brussels"
	  },
	  loading: false,
	  rules: {
		alias: [
		  {
			required: true,
			message: "Alias is required",
			trigger: "blur"
		  }
		],		
		billing_entity_id: [
		  {
			required: true,
			message: "Billing entity is required",
			trigger: "blur"
		  }
		],
		account_id: [
		  {
			required: true,
			message: "Account is required",
			trigger: "blur"
		  }
		],
		addressline1: [
		  {
			required: true,
			message: "Street is required",
			trigger: "blur"
		  }
		],
		number: [
		  {
			required: true,
			message: "Number is required",
			trigger: "blur"
		  }
		],
		zipcode: [
		  {
			required: true,
			message: "Zipcode is required",
			trigger: "blur"
		  }
		],
		city: [
		  {
			required: true,
			message: "City is required",
			trigger: "blur"
		  }
		],
		state: [
		  {
			required: true,
			message: "State is required",
			trigger: "blur"
		  }
		],
		countrycode: [
		  {
			required: true,
			message: "Country is required",
			trigger: "blur"
		  }
		],
		latitude: [
		  {
			required: true,
			message: "Latitude is required",
			trigger: "blur"
		  }
		],
		longitude: [
		  {
			required: true,
			message: "Longitude is required",
			trigger: "blur"
		  }
		],
		timezone: [
		  {
			required: true,
			message: "timezone is required",
			trigger: "blur"
		  }
		],
		is_visible: [
		  {
			required: true,
			message: "visible is required",
			trigger: "blur"
		  }
		],
		manager_id: [
		{
		  required: true,
		  message: "manager is required",
		  trigger: "blur"
		}]
	  }
	};
  },
  methods: {
	...mapActions("locations", {
	  createLocation: "create"
	}),
	handleReset() {
	  this.$refs["loactionForm"].resetFields();
	  this.location = {
		alias: "",
		addressline1: "",
		number: "",
		addressline2: "",
		zipcode: "",
		city: "",
		countrycode: "",
		account_id: undefined,
		onboarding_mode: true,
		is_visible: true,
		manager_id: "",
		phone:"",
		fax: "",
		email: "",
		timezone: "Europe/Brussels"
	  };
	},
	handleBack() {
	  this.$router.push({
		name: "locations.index"
	  });
	},
	handleSubmit() {
	  this.$refs["loactionForm"].validate(valid => {
		if (valid) {
		  this.loading = true;
		  this.createLocation({
			data: {
				user_id: this.location.manager_id,
				...this.location
			}
		  })
			.then(res => {
			  this.$notify({
				title: "Success",
				message: "Location Created",
				type: "success"
			  });
			  this.handleBack();
			})
			.catch(err => {
				console.log(err)
			  this.$notify({
				title: "Error",
				message: "Location could not be created!",
				type: "error"
			  });
			})
			.finally(_ => {
			  this.loading = false;
			});
		} else {
		  this.$notify({
			title: "Warning",
			message: "Please complete the form!",
			type: "warning"
		  });
		  return false;
		}
	  });
	}
  }
};
</script>

<style>
</style>
