import { ensureResource } from '@/helpers/rest-store-vuex'

export const state = {
    currentAccountId: null
};

export const actions = {
    setCurrentAccount(context, accountId) {
        context.commit('setCurrentAccount', accountId)
    }
};

export const mutations = {
    setCurrentAccount(state, accountId) {
        state.currentAccountId = accountId;
    }
};

export const getters = {
};