<template>
  <div v-loading="true"></div>
</template>

<script>
import { Base64 } from "js-base64";
export default {
  computed: {
    token() {
      return this.$route.query.token;
    },
    redirectUri() {
      return this.$route.query.redirect_uri;
    }
  },
  mounted() {
    console.log(this.token);
    var authToken = JSON.parse(Base64.decode(this.token));

    this.$store
      .dispatch("auth/signInWithToken", { token: authToken })
      .then(() => {
        this.$router.push(this.redirectUri || { name: "Dashboard" });
      })
      .catch(err => {
        this.$router.push({ name: "Login" });
        //window.location.href= `${process.env.VUE_APP_ZFHQ_AUTH_URI}?client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_AUTH_CALLBACK}`
      });
  }
};
</script>

<style>
</style>
