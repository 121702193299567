import locationsOverview from '@/views/locations/Overview/Index.vue';
import NewLocation from '@/views/locations/NewLocation/Index.vue';
import EditLocation from '@/views/locations/EditLocation/Index.vue';
import ShowLocation from '@/views/locations/ShowLocation/Index.vue';
import Dashboard from '@/views/locations/Dashboard/Index.vue';
import Units from '@/views/locations/Settings/Units.vue';
import Settings from '@/views/locations/Settings/Index.vue';

export default [
    {
        path: '/locations',
        name: 'locations.index',
        component: locationsOverview,
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },
    {
        path: '/locations/new',
        name: 'locations.new',
        component: NewLocation,
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },
    {
        path: '/locations/:locationId',
        name: 'locations.show',
        component: ShowLocation,
        redirect: 'location.dashboard',
        meta: {
            authRequired: true,
            layout: 'app-layout'
        },
        children: [
            {
                path: '',
                name: 'location.dashboard',
                component: Dashboard,
                meta: {
                    authRequired: true,
                    layout: 'app-layout'
                }
            },{
                path: 'units',
                name: 'location.units',
                component: Units,
                meta: {
                    authRequired: true,
                    layout: 'app-layout'
                }
            },
            {
                path: '/locations/:locationId/edit',
                name: 'location.edit',
                component: EditLocation,
                meta: {
                    authRequired: true,
                    layout: 'app-layout'
                }
            },
            {
                path: '/locations/:locationId/settings',
                name: 'location.settings',
                component: Settings,
                meta: {
                    authRequired: true,
                    layout: 'app-layout'
                }
            },
        ]
    },
    
]