<template>
  <table-page-layout>
    <el-row type="flex" :gutter="10" justify="space-between" class="xs-mb-10">
      <el-col :md="6">
        <!-- <el-add-button @click="handleNewType">{{ $t("Type") }}</el-add-button> -->
      </el-col>
      <el-col :md="18" class="text-right">
        <el-input
          v-model="search"
          suffix-icon="fa fa-search"
          :placeholder="`${$t('Search')}...`"
          clearable
          style="width: 400px"
        ></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="m-t-1">
      <el-col :md="24">
        <el-card shadow="never">
          <el-table
            v-loading="isLoading(emailTypes)"
            :data="emailTypes"
            stripe
            size="small"
            :empty-text="$t('No data')"
          >
            <el-table-column :label="$t('Name')" prop="name" />
            <el-table-column :label="$t('Key')" prop="key" />
            <el-table-column :label="$t('subscribeable')">
              <template slot-scope="{ row }">
                {{ row.subscribeable }}
              </template>
            </el-table-column>

            <el-table-column width="60" align="center">
              <template slot-scope="{ row }">
                <el-button type="text" @click="showType(row)">
                  <i class="fa fa-search"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="xs-mt-20 xs-mb-10">
      <el-col :md="24">
        <el-pagination
          :disabled="isLoading(emailTypes)"
          class="text-center"
          style="margin-bottom: 20px"
          background
          layout="prev, pager, next"
          :current-page="page"
          :page-count="pages"
          @current-change="onPageChange"
        />
      </el-col>
    </el-row>
  </table-page-layout>
</template>

<script>
import { getPage } from "@/helpers/rest-store-vuex";
import { isLoading } from "@jorgenevens/rest-store";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import reject from "lodash/reject";
import pickBy from "lodash/pickBy";
import { search } from "@/mixins";
// import ElAddButton from "@/components/ui/add-button";

export default {
  components: {
    // ElAddButton,
  },
  mixins: [search],
  data() {
    return {
      page: parseInt(this.$route.query.page, 10) || 1,
      needle: this.$route.query.needle || "",
      search: this.$route.query.needle || "",
      pageSize: 20,
      customFilters: {
        is_active: this.$route.query.is_active,
      },
    };
  },
  computed: {
    listName() {
      const whiteListed = ["needle", "is_active", "role"];
      let filter = Object.keys(this.filters).map((key) => {
        if (whiteListed.includes(key) && this.filters[key] !== undefined) {
          if (typeof this.filters[key] === "boolean")
            return `${key}-${this.filters[key]}`;
          return String(this.filters[key]).split(",").join("_");
        }
      });
      filter = reject(filter, isEmpty).join("_");
      if (this.filteringIsActive) return `search_${filter}`;
      return `all`;
    },
    filteringIsActive() {
      return (
        !isEmpty(this.needle) || this.customFilters.is_active !== undefined
      );
    },
    filterQueryParams() {
      const whiteListed = ["needle", "is_active"];

      return pickBy(this.filters, (val, key) => {
        if (typeof val === "boolean") return whiteListed.includes(key);
        return !isEmpty(val) && whiteListed.includes(key);
      });
    },
    filters() {
      const _filters = {};
      if (!isEmpty(this.roles)) _filters.role = this.roles.join(",");

      if (!isEmpty(this.needle)) _filters.needle = this.needle;
      _filters.is_active = this.customFilters.is_active;

      if (!isEmpty(this.accountId)) _filters.organisation_id = this.accountId;
      return _filters;
    },
    pages() {
      const total = this.$store.state.emailTypes.list[this.listName].total;
      return Math.ceil(total / this.pageSize);
    },
    emailTypes: getPage(
      (cmp) => cmp.page,
      (cmp) => cmp.listName,
      {
        namespace: "emailTypes",
        params: (cmp) => ({
          pageSize: cmp.pageSize,
          filters: cmp.filters,
          sort: cmp.sort,
        }),
      }
    ),
  },
  watch: {
    filterQueryParams: "setQueryParams",
  },
  methods: {
    isLoading,
    isEmpty,
    setQueryParams() {
      if (this.filteringIsActive) {
        this.page = 1;
        this.$router.push({
          query: {
            page: this.page,
            ...this.filterQueryParams,
          },
        });
      } else {
        this.$router.push({
          query: {
            page: this.page,
          },
        });
      }
    },
    onPageChange(_page) {
      this.page = _page;
      this.$router.push({
        query: {
          page: _page,
          ...this.filterQueryParams,
        },
      });
      this.setQueryParams();
    },
    showType(type) {
      this.$router.push({
        name: "admin.email.emailType.detail",
        params: {
          id: type.id,
        },
      });
    },
    handleNewType() {
      //   if (this.$route.name === "users.admin.index")
      //     this.$router.push({ name: "users.admin.new" });
      //   else this.$router.push({ name: "users.new" });
    },
    applyCustomFilters(_filters) {
      this.customFilters = cloneDeep(_filters);
    },
  },
};
</script>

<style></style>
