<template>
  <div>
    <div :class="identifier"></div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import { mapGetters } from 'vuex';
import { getResource } from "@/util/RestStoreVuex";
import { loadCumulio, DASHBOARDS } from '@/util/cumulio';
import hashIt from 'hash-it';

export default {

  name: "dashboard",

  props: ['id', 'params'],

  mounted() {
      loadCumulio();
  },

  watch: {
      dashboardToken: {
        immediate: true,
        handler(v) {
            // Wait for view to render before activating Cumulio SDK
            // This ensures the container div exists
            setTimeout(this.onLoadDashboard.bind(this), 0);
        }
      }
  },

  methods: {
        onLoadDashboard() {
            const v = this.dashboardToken;

            if(!v || !v.token)
                return null;

            Cumulio.addDashboard({
                dashboardId: this.dashboardId,
                container: `.${this.identifier}`,
                key: v.id,
                token: v.token
            });
        }
  },

    computed: {
        dashboardId() {
            return DASHBOARDS[this.id] || this.id;
        },

        identifier() {
            return 'cumulio__dashboard--' + (this.dashboardId || '').replace(/[^a-z0-9]+/ig, '');
        },

        dashboardUniqueKey() {
            const hash = hashIt(this.params);
            return `${this.dashboardId}-${hash}`;
        },

        dashboardToken: getResource('dashboardUniqueKey', {
            namespace: "reports/cumulio/token",
            params: cmp => ({
                ...cmp.params,
                dashboardId: cmp.dashboardId
            }),
            condition: cmp => !!cmp.dashboardId
        })
    }
};
</script>
