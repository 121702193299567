var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-page-layout',[(_vm.showPageTitle)?_c('page-title',[_vm._v(_vm._s(_vm.pageTitle))]):_vm._e(),_c('el-row',{staticClass:"xs-mb-10",attrs:{"type":"flex","gutter":10,"justify":"space-between"}},[_c('el-col',{attrs:{"md":6}},[_c('el-add-button',{on:{"click":_vm.handleNewUser}},[_vm._v("User")])],1),_c('el-col',{staticClass:"text-right",attrs:{"md":18}},[_c('el-input',{staticStyle:{"width":"400px"},attrs:{"suffix-icon":"fa fa-search","placeholder":((_vm.$t('Search Users')) + "..."),"clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('el-row',{staticClass:"m-t-1",attrs:{"gutter":10}},[_c('el-col',{attrs:{"md":24}},[_c('el-card',{attrs:{"shadow":"never"}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading(_vm.users)),expression:"isLoading(users)"}],attrs:{"data":_vm.users,"stripe":"","size":"small","empty-text":_vm.$t('No Users')},on:{"sort-change":_vm.handleSortChange}},[_c('el-table-column',{attrs:{"width":"40","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{staticClass:"fa fa-circle",class:{'text-success': row.is_active, 'text-danger': !row.is_active}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-small pointer",on:{"click":function($event){return _vm.showUser(row)}}},[_vm._v(_vm._s(row.firstname)+" "+_vm._s(row.lastname))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Username'),"width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(row.username))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Email'),"width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(row.email))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Role'),"width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(row.role))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Date Created'),"width":"120","sortable":"custom","prop":"created_at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(_vm._f("date")(row.created_at)))])]}}])}),_c('el-table-column',{attrs:{"width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showUser(row)}}},[_c('i',{staticClass:"fa fa-search"})])]}}])})],1)],1)],1)],1),_c('el-row',{staticClass:"xs-mt-20 xs-mb-10",attrs:{"gutter":10}},[_c('el-col',{attrs:{"md":24}},[_c('el-pagination',{staticClass:"text-center",staticStyle:{"margin-bottom":"20px"},attrs:{"disabled":_vm.isLoading(_vm.users),"background":"","layout":"prev, pager, next","current-page":_vm.page,"page-count":_vm.pages},on:{"current-change":_vm.onPageChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }