<template>
  <el-button v-bind="$attrs" v-on="$listeners">
    <i class="fa fa-plus-square xs-mr-10"></i>
    <slot></slot>
  </el-button>
</template>

<script>
import { Button as ElButton } from "element-ui";
export default {
  name: "add-button",
  components: {
    ElButton
  }
};
</script>

<style></style>