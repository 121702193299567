import deserializeJsonApi from './json-api-deserializer';

function JsonApiParser(data) {
    this.data = data;
    this.meta = data.meta;
    this.links = data.links;
}

JsonApiParser.prototype.parse = function () {
    if (this.data === undefined) return;

    var result = {
        data: deserializeJsonApi(this.data)
    };

    if (this.meta) result['meta'] = this.meta;
    if (this.links) result['links'] = this.links;

    return result;
};

JsonApiParser.prototype.parseError = function () {
    if (this.data && this.data.errors) {

        if (this.data.errors.length === 1) {
            return this.data.errors[0];
        }
        return this.data.errors;
    } else return this.data;
};

export default JsonApiParser;