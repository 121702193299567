var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-page-layout',[_c('page-title',[_vm._v("Legal entities")]),_c('el-row',{staticClass:"xs-mb-10",attrs:{"type":"flex","gutter":10,"justify":"space-between"}},[_c('el-col',{attrs:{"md":6}},[_c('el-add-button',{on:{"click":_vm.handleNewEntity}},[_vm._v("New legal entities")])],1),_c('el-col',{staticClass:"text-right",attrs:{"md":18}},[_c('el-input',{staticStyle:{"width":"400px"},attrs:{"suffix-icon":"fa fa-search","placeholder":((_vm.$t('Search legal entities')) + "..."),"clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('el-row',{staticClass:"m-t-1",attrs:{"gutter":10}},[_c('el-col',{attrs:{"md":24}},[_c('el-card',{attrs:{"shadow":"never"}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading(_vm.legalEntities)),expression:"isLoading(legalEntities)"}],ref:"legalEntitiesTable",attrs:{"data":_vm.legalEntities,"stripe":"","size":"small","empty-text":_vm.$t('No legal entities')},on:{"sort-change":_vm.handleSortChange,"expand-change":_vm.handleExpandChange}},[_c('el-table-column',{attrs:{"label":_vm.$t('Name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n                "+_vm._s(row.name)+"\n            ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Description'),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n                "+_vm._s(row.description)+"\n            ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Locale'),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n                "+_vm._s(row.locale)+"\n            ")]}}])}),_c('el-table-column',{attrs:{"width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleShow(row)}}},[_c('i',{staticClass:"fa fa-search"})])]}}])})],1)],1)],1)],1),_c('el-row',{staticClass:"xs-mt-20 xs-mb-10",attrs:{"gutter":10}},[_c('el-col',{attrs:{"md":24}},[(_vm.pages > 1)?_c('el-pagination',{staticClass:"text-center",staticStyle:{"margin-bottom":"20px"},attrs:{"disabled":_vm.isLoading(_vm.legalEntities),"background":"","layout":"prev, pager, next","current-page":_vm.page,"page-count":_vm.pages},on:{"current-change":_vm.onPageChange}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }