import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters("auth", {
            currentLocation: "getCurrentLocation"
        }),
        locationId() {
            return this.currentLocation.id
        }
    }
}