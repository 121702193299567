import { apply } from '@jorgenevens/rest-store/vuex';
import { attach } from '@jorgenevens/rest-store';
import zfhqClient from '@/config/zfhq-api'

import { fetchPage, fetchResource, fetchAll, createResource, updateResource } from '../helpers/actions';
import _mutations from '../helpers/mutations';

function fetchPageFromApi(options) {
    const {
        page = 1,
        pageSize = 10,
        accountId
    } = options;

    if(!!!accountId) throw new Error('accountId is missing');

    const filters = {
        ...options.filters,
    };

    return zfhqClient.get(`v5/accounts/${accountId}/billing_entities`, {
        filters,
        page: {
            size: pageSize,
            number: page
        }
    });
}

export const mutations = {
    ..._mutations
};

export const state = {
    ...apply(attach)
};

export const actions = {

    fetch: fetchResource(options => {
        const _data = {
            include: ''
        }
        return zfhqClient.get(`v5/billing_entities/${options.id}`, _data);
    }),

    fetchPage: fetchPage(options => fetchPageFromApi(options)),

    fetchAll: fetchAll((page, options) => fetchPageFromApi({
        ...options,
        pageSize: Math.min(20, options.pageSize || 20),
        page
    })),
}