<template>
  <el-select
    placeholder="Select Timezone"
    v-bind="$attrs"
    clearable
    v-model="selectedValue"
    filterable
  >
    <el-option v-for="zone in TimeZones" :key="zone" :label="zone" :value="zone"/>
  </el-select>
</template>

<script>
import _isEmpty from "lodash/isEmpty";
import TimeZones from '@/helpers/timezones.json'

export default {
  props: {
    value: {
      type: String
    }
  },

  data() {
    return {
      TimeZones
    };
  },

  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  }
};
</script>
