<template>
  <div>
    <page-title>New Application (Client)</page-title>
    <el-row type="flex" justify="space-between" class="xs-mb-10">
      <div>
        <el-button plain @click="handleBack" :disabled="loading">
          <i class="fa fa-arrow-circle-left xs-mr-10"></i>Back
        </el-button>
      </div>
      <div>
        <el-row type="flex" justify="end">
          <el-button plain @click="handleReset" :disabled="loading">
            <i class="fa fa-times xs-mr-10"></i>Reset
          </el-button>
          <el-button type="primary" plain @click="handleSubmit" :loading="loading">
            <i class="fa fa-save xs-mr-10"></i>Save
          </el-button>
        </el-row>
      </div>
    </el-row>

    <el-card shadow="never">
      <el-form
        label-position="left"
        label-width="150px"
        :model="application"
        :rules="rules"
        ref="appForm"
        v-loading="loading"
      >
        <el-form-item label="Name" prop="name">
          <el-input v-model="application.name"></el-input>
        </el-form-item>
        <el-form-item label="Type" prop="type">
          <el-select v-model="application.type" placeholder="Select Type" style="min-width:200px;">
            <el-option v-for="s in TYPES" :key="s" :label="s" :value="s"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Account">
          <select-account v-model="application.account_id" style="min-width:300px;"/>
        </el-form-item>

        <el-divider content-position="left" style="margin-top:40px;">
          <i class="fa fa-cogs xs-mr-10"></i>Settings
        </el-divider>
        <el-form-item label="Scopes">
          <multi-tag-input
            v-model="application.scopes"
            :suggestions="SCOPES"
            button-label="scope"
            placeholder="scope"
          />
        </el-form-item>
        <el-form-item label="Redirect Uri" prop="redirect_uri">
          <el-input v-model="application.redirect_uri"></el-input>
        </el-form-item>
        <el-form-item label="PreAuthorized">
          <el-switch v-model="application.preauthorized"></el-switch>
        </el-form-item>
        <el-form-item label="Confidential">
          <el-switch v-model="application.confidential"></el-switch>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import PageTitle from "@/components/ui/page-title";
import { getPage } from "@/helpers/rest-store-vuex";
import { mapActions } from "vuex";
import { SCOPES, TYPES } from "../helpers/consts";
import SelectAccount from "@/components/app/select-account";
import MultiTagInput from "@/components/ui/multi-tag-input";

export default {
  components: {
    PageTitle,
    SelectAccount,
    MultiTagInput
  },
  data() {
    return {
      SCOPES,
      TYPES,
      application: {
        name: "",
        type: "",
        scopes: [],
        redirect_uri: "urn:ietf:wg:oauth:2.0:oob",
        preauthorized: true,
        confidential: true,
        account_id: undefined,
        location_id: undefined
      },
      loading: false,
      rules: {
        name: [
          {
            required: true,
            message: "Name is required",
            trigger: "blur"
          }
        ],
        type: [
          {
            required: true,
            message: "Type is required",
            trigger: "blur"
          }
        ],
        redirect_uri: [
          {
            required: true,
            message: "Redirect Uri is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions("oauthApplications", {
      createApplication: "create"
    }),
    handleReset() {
      this.$refs["appForm"].resetFields();
      this.application = {
        name: "",
        type: "",
        scopes: [],
        redirect_uri: "urn:ietf:wg:oauth:2.0:oob",
        preauthorized: true,
        confidential: true,
        account_id: undefined,
        location_id: undefined
      };
    },
    handleBack() {
      this.$router.push({
        name: "oauthApplications.index"
      });
    },
    handleSubmit() {
      this.$refs["appForm"].validate(valid => {
        if (valid) {
          this.loading = true;
          this.createApplication({
            data: this.application
          })
            .then(res => {
              this.$notify({
                title: "Success",
                message: "Application Created",
                type: "success"
              });
              this.handleBack();
            })
            .catch(err => {
              this.$notify({
                title: "Error",
                message: "Application could not be created!",
                type: "error"
              });
            })
            .finally(_ => {
              this.loading = false;
            });
        } else {
          this.$notify({
            title: "Warning",
            message: "Please complete the form!",
            type: "warning"
          });
          return false;
        }
      });
    }
  }
};
</script>

<style>
</style>
