import { get, set, has, omit, isNil, isEmpty, reduce } from "lodash";

function isSet(value) {
  // null and undefined are empty values
  if (isNil(value)) return false;

  // numbers and booleans are non-empty
  if (["number", "boolean"].includes(typeof value)) return true;

  // strings, arrays, sets, objects, ... are tested for their length/size
  return !isEmpty(value);
}

function hasChanged(prev, next) {
  if (prev == next) return false;

  // if it changes from empty to other empty value, ignore the change,
  // for example, the ui-select component emits a [] value on init, changing
  // `null` to `[]` ; we don't need to track such changes
  return isSet(prev) || isSet(next);
}

export default function createModel(resourceKey, dataKey, modelKey) {
  if (Array.isArray(modelKey)) {
    return reduce(
      modelKey,
      (obj, key) => {
        obj[key] = createModel(resourceKey, dataKey, key);
        return obj;
      },
      {}
    );
  }

  return {
    get() {
      const resource = get(this, resourceKey) || {};
      const data = get(this, dataKey) || {};

      return has(data, modelKey)
        ? get(data, modelKey)
        : get(resource, modelKey);
    },
    set(v) {
      const resource = get(this, resourceKey) || {};
      let data = get(this, dataKey) || {};

      const original = get(resource, modelKey);
      const changed = hasChanged(original, v);
      data = omit(data, modelKey);

      if (changed) set(data, modelKey, v);

      this[dataKey] = data;
    },
  };
}
