<template>
  <el-container direction="vertical">
    <el-row type="flex" justify="space-between" class="xs-mb-20">
      <el-link> </el-link>
      <el-menu
        :default-active="childPath"
        router
        mode="horizontal"
        active-text-color="#009fe1"
      >
        <el-menu-item
          index="/"
          :route="{
            name: 'account.dashboard',
            params: { accountId: accountId },
          }"
        >
          <i class="fa fa-home xs-mr-10"></i>Dashboard
        </el-menu-item>
        <el-menu-item
          index="/integrations"
          :route="{
            name: 'integrations.overview',
            params: { accountId: accountId },
          }"
        >
          <i class="fa fa-info-circle xs-mr-10"></i>Integrations
        </el-menu-item>
        <el-menu-item
          index="/modules"
          :route="{
            name: 'modules.overview',
            params: { accountId: accountId },
          }"
        >
          <i class="fa fa-info-circle xs-mr-10"></i>Modules
        </el-menu-item>
        <el-menu-item
          index="/branding"
          :route="{
            name: 'account.branding',
            params: { accountId: accountId },
          }"
        >
          <i class="fa fa-paint-roller xs-mr-10"></i>Branding
        </el-menu-item>
        <el-menu-item
          index="/users"
          :route="{ name: 'account.users', params: { accountId: accountId } }"
        >
          <i class="fa fa-users xs-mr-10"></i>Users
        </el-menu-item>
        <el-menu-item v-show="false" index="/locations">
          <i class="fa fa-map-marker-alt xs-mr-10"></i>Locations
        </el-menu-item>
      </el-menu>
    </el-row>
    <router-view :key="$route.fullPath" />
  </el-container>
</template>

<script>
import { getResource } from "@/helpers/rest-store-vuex";

export default {
  computed: {
    childPath() {
      const match = this.$route.path.replace(/^(.*[\\\/])/, "/");
      if (match) return match;
      return this.$route.path;
    },
    accountId() {
      return this.$route.params.accountId;
    },
    account: getResource((cmp) => cmp.accountId, {
      namespace: "accounts",
      condition: (cmp) => cmp.accountId,
    }),
  },
};
</script>

<style lang="scss" scoped>
.el-menu--horizontal {
  border-bottom: none !important;
  .el-menu-item {
    border-bottom: none !important;
  }
}
</style>
