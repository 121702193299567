import { apply } from '@jorgenevens/rest-store/vuex';
import { attach } from '@jorgenevens/rest-store';

import { fetchPage, fetchResource, createResource } from '@/store/helpers/actions';
import _mutations from '@/store/helpers/mutations';
import zfhqClient from '@/config/zfhq-api'

export const mutations = {
    ..._mutations
};

export const state = {
    ...apply(attach)
};

export const actions = {
    fetch: fetchResource(options => {
        const { dashboardId } = options;

        return zfhqClient.post(`v5/reporting/cumulio/authorization`,{
            securables: [dashboardId],
            filters:{
                location_id: [options.locationId],
                account_id: [options.accountId]
            }
        })
        .then(data => {
            console.log("dataaa",data);
            return { data, id: options.id };
        })
    }),
} 
