var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-page-layout',[(_vm.showPageTitle)?_c('page-title',[_vm._v(_vm._s(_vm.pageTitle))]):_vm._e(),_c('el-row',{staticClass:"xs-mb-10",attrs:{"type":"flex","gutter":10,"justify":"space-between"}},[_c('el-col',{attrs:{"md":6}},[_c('el-add-button',{on:{"click":_vm.handleNewLocation}},[_vm._v("Location")])],1),_c('el-col',{staticClass:"text-right",attrs:{"md":18}},[_c('el-input',{staticStyle:{"width":"400px"},attrs:{"suffix-icon":"fa fa-search","placeholder":((_vm.$t('Search Locations')) + "..."),"clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('el-row',{staticClass:"m-t-1",attrs:{"gutter":10}},[_c('el-col',{attrs:{"md":24}},[_c('el-card',{attrs:{"shadow":"never"}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading(_vm.locations)),expression:"isLoading(locations)"}],attrs:{"data":_vm.locations,"stripe":"","size":"small","empty-text":_vm.$t('No Locations')},on:{"sort-change":_vm.handleSortChange}},[_c('el-table-column',{attrs:{"label":_vm.$t('Name'),"sortable":"custom","prop":"alias"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-small pointer",on:{"click":function($event){return _vm.showLocation(row)}}},[_vm._v(_vm._s(row.alias))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Account'),"sortable":"custom","prop":"operator_organisation_id","width":"220","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row && row.account)?_c('resource',{attrs:{"namespace":"accounts","id":row.account.id,"field":"company_name"}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('City'),"width":"150","sortable":"custom","prop":"city","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(row.city))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Country'),"width":"150","sortable":"custom","prop":"countrycode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(row.country_name))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Date Created'),"width":"120","sortable":"custom","prop":"created_at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(_vm._f("date")(row.created_at)))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Onboarding'),"align":"center","width":"180","sortable":"custom","prop":"onboarding_mode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{staticClass:"fa fa-circle",class:{'text-success': row.onboarding_mode, 'text-danger': !row.onboarding_mode}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Billed'),"align":"center","width":"180","sortable":"custom","prop":"setup_is_billed"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{staticClass:"fa fa-circle",class:{'text-success': row.setup_is_billed, 'text-danger': !row.setup_is_billed}})]}}])}),_c('el-table-column',{attrs:{"width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showLocation(row)}}},[_c('i',{staticClass:"fa fa-search"})])]}}])})],1)],1)],1)],1),_c('el-row',{staticClass:"xs-mt-20 xs-mb-10",attrs:{"gutter":10}},[_c('el-col',{attrs:{"md":24}},[_c('el-pagination',{staticClass:"text-center",staticStyle:{"margin-bottom":"20px"},attrs:{"disabled":_vm.isLoading(_vm.locations),"background":"","layout":"prev, pager, next","current-page":_vm.page,"page-count":_vm.pages},on:{"current-change":_vm.onPageChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }