<template>
  <div>
    <page-title>{{title}}</page-title>
    <el-row type="flex" justify="space-between" class="xs-mb-10">
      <div>
        <el-button plain @click="handleBack" :disabled="loading">
          <i class="fa fa-arrow-circle-left xs-mr-10"></i>Back
        </el-button>
      </div>
      <div>
        <el-row type="flex" justify="end">
          <el-button plain @click="handleReset" :disabled="loading">
            <i class="fa fa-times xs-mr-10"></i>Reset
          </el-button>
          <el-button type="primary" plain @click="handleSubmit" :loading="loading">
            <i class="fa fa-save xs-mr-10"></i>Save
          </el-button>
        </el-row>
      </div>
    </el-row>

    <el-card shadow="never">
      <el-form
        label-position="left"
        label-width="150px"
        :model="entity"
        :rules="rules"
        ref="entityForm"
      >
        <el-form-item label="Name" prop="name">
          <el-input v-model="entity.name" style="width:400px;"></el-input>
        </el-form-item>
        <el-form-item label="Description" prop="description">
          <el-input v-model="entity.description" style="width:400px;"></el-input>
        </el-form-item>
        <el-form-item label="locale" prop="locale">
          <el-select v-model="entity.locale" style="width:400px;" filterable>
            <el-option v-for="l in localeOptions" :key="l.countrycode" :label="l.name" :value="l.countrycode"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>

    <el-row type="flex" justify="end" class="xs-mb-10 xs-mt-10">
      <div v-if="entityId">
        <el-button type="danger" plain @click="handleRemove" :loading="loading">
          <i class="far fa-trash-alt xs-mr-10"></i>Remove legal entity
        </el-button>
      </div>
    </el-row>
  </div>
</template>

<script>
import { isOK, isLoading } from "@jorgenevens/rest-store";
import { getPage } from '@jorgenevens/rest-store/vuex';
import PageTitle from "@/components/ui/page-title";
import { mapActions } from "vuex";
import { getResource } from "@/helpers/rest-store-vuex";
import cloneDeep from "lodash/cloneDeep";

export default {
  components: {
    PageTitle,
  },
  data() {
    return {
      loading: false,
      entity: {},
      rules: {
        name: [
          {
            required: true,
            message: "Name is required",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Description is required",
            trigger: "blur"
          }
        ],
        locale: [
          {
            required: true,
            message: "Locale is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    entityId() {
      return this.$route.params.entityId
    },
    entityData: getResource(cmp => cmp.entityId, {
      namespace: "legalEntities",
      condition: cmp => cmp.entityId
    }),

    title() {
      return this.entityId ? "Edit legal entity" : "New legal entity"
    },

    localeOptions: getPage(() => 1, cmp => 'all', {
			namespace: 'countries',
			params: cmp => ({
				pageSize: 300,
			}),
		}),

  },
  watch: {
      entityData: {
          immediate: true,
          handler(v) {
              if (v) this.entity = cloneDeep(v)
          }
      }
  },
  methods: {
    ...mapActions("legalEntities", {
      createEntity: "create",
      updateEntity: "update",
      deleteEntity: "delete",
    }),
    handleReset() {
      this.$refs["entityForm"].resetFields();
      this.entity = cloneDeep(this.entityData);
    },
    handleBack() {
      console.log("back")
      this.$router.push({
        name: "legalEntities.index"
      });
    },
    handleRemove() {
      this.$confirm(
        "Are you sure?",
        "Delete legal entity",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          }
      )
        .then(() => {
          this.loading = true;
          this.deleteEntity({id: this.entityId})
            .then(res => {
              this.loading = false;
              this.$notify({
                title: "Success",
                message: "Legal entity Removed",
                type: "success"
              });
              this.handleBack();
            })
            .catch(err => {
              this.loading = false;
              this.$notify({
                title: "Error",
                message: "Legal entity could not be removed!",
                type: "error"
              });
            })
        })
        .catch(() => {});
    },
    handleSubmit() {
      this.$refs["entityForm"].validate(valid => {
        if (!valid) return
          this.loading = true;
          if (this.entityId) {
            this.updateEntity({
              id: this.entityId,
              data: this.entity
            }).then(res => {
              this.loading = false;
              this.$notify({
                title: "Success",
                message: "Legal entity updated",
                type: "success"
              });
              this.handleBack();
            })
            .catch(err => {
              this.loading = false;
              this.$notify({
                title: "Error",
                message: "Legal entity could not be updated!",
                type: "error"
              });
            })
          } else {
            this.createEntity({
              data: this.entity
            }).then(res => {
              console.log("res", res)
              this.loading = false;
              console.log("loading", this.loading)
              this.$notify({
                title: "Success",
                message: "Legal entity Created",
                type: "success"
              });
              this.handleBack();
            })
            .catch(err => {
              this.loading = false;
              this.$notify({
                title: "Error",
                message: "Legal entity could not be created!",
                type: "error"
              });
            })
          }
        })
    }
  }
};
</script>

<style>
</style>
