import { apply } from '@jorgenevens/rest-store/vuex';
import { attach } from '@jorgenevens/rest-store';
import zfhqClient from '@/config/zfhq-api'

import { fetchPage, fetchResource, createResource, updateResource } from '../helpers/actions';
import _mutations from '../helpers/mutations';


export const mutations = {
    ..._mutations
};

export const state = {
    ...apply(attach)
};

export const actions = {

    fetch: fetchResource(options => {
        const _data = {
            include: ''
        }
        return zfhqClient.get(`v5/service_apps/${options.id}`, _data);
    }),

    fetchPage: fetchPage(options => {
        const {
            page = 1,
            pageSize = 25,
            filters,
            sort = 'company_name',
            needle
        } = options;

        let _filters = {
            ...filters,
        }

        if (needle) {
            _filters.needle = needle;
        }

        return zfhqClient.get('v5/service_apps', {
            sort,
            page: {
                size: pageSize,
                number: page,
            },
            filters: _filters,
        });
    }),

    create: createResource(options => {
        const {
            data
        } = options;

        let _data = {
            service_app: {
                ...data
            }
        }

        return zfhqClient.post(`v5/service_apps`, _data)
    }),

    update: updateResource(options => {
        const {
            id,
            data
        } = options;

        let _data = {
            service_app: {
                ...data
            }
        }

        return zfhqClient.put(`v5/service_apps/${id}`, _data)
    }),

    refreshApiKey: updateResource(options => {
        const {
            id,
        } = options;

        let _data = {
            service_app: {
                refresh_api_key: true
            }
        }

        return zfhqClient.put(`v5/service_apps/${id}`, _data)
    }),

    delete({ commit }, id) {
        return zfhqClient.delete(`v5/service_apps/${id}`)
            .then(() => commit('removeResource', { id }));
    },

    // delete({ commit }, { id }) {
    //     return zfhqClient.delete(`v5/service_apps/${id}`)
    //         .then(_ => {
    //             commit('removeResource', { id })
    //         })
    // },
}