<template>
  <el-card class="counter-widget-card">
    <el-row type="flex" justify="space-between" align="middle" class="xs-pl-10 xs-pr-10">
      <i :class="icon" class="text-xx-large xs-mr-10"></i>
      <span class="text-xx-large weight-bold count">{{count}}</span>
    </el-row>
    <div v-if="title" class="text-right xs-pr-10">{{title}}</div>
  </el-card>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 0
    },
    icon: {
      type: String,
      default: "fa fa-tags"
    },
    title: {
      type: String
    }
  }
};
</script>

<style lang="scss">
.counter-widget-card {
  height: 100px;
  width: 100%;
  min-width: 250px;
  .count {
    color: rgb(0, 159, 225);
  }
}
</style>

