import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    ...modules
  },
  state: {
    // global state
  },
  mutations: {
  },
  actions: {
  }
});

// loading global functions
Vue.prototype.$startLoading = () => {
  store.dispatch("ui/startLoading");
};
Vue.prototype.$endLoading = () => {
  store.dispatch("ui/endLoading");
};

Vue.prototype.$isLoading = store.state.ui.isLoading;


// create copy of initial state of the store
const initialState = JSON.parse(JSON.stringify(store.state));
// function to reset vuex to initial state
Vue.prototype.$resetState = () => {
  store.replaceState(JSON.parse(JSON.stringify(initialState)));
};

export default store;