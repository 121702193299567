<template>
  <el-select
    placeholder="Select Billing Entity"
    v-bind="$attrs"
    clearable
    v-model="selectedValue"
    filterable
    :loading="isLoading(billing_entities)"
    optionLabel="company_name"
    optionKey="id"
  >
    <el-option
      v-for="item in billing_entities"
      :key="item.id"
      :label="item.company_name"
      :value="item.id"
    />
  </el-select>
</template>

<script>
import { isLoading } from "@jorgenevens/rest-store";
import getAll from "@/store/helpers/get-all";

export default {
  props: {
    value: {
      type: [String, Number]
    },
    accountId: {
      type: String
    }
  },

  methods: {
    isLoading,
  },

  watch: {
    billing_entities(v) {
      this.$emit("updated:loading", isLoading(v));
    }
  },

  computed: {
    listName() {
      return `select-${this.accountId}`
    },

    billing_entities: getAll(cmp => cmp.listName, {
      namespace: "billingEntities",
      params: cmp => ({ accountId: cmp.accountId })
    }),


    selectedValue: {
      get() {
        if(isLoading(this.billing_entities)) return undefined;
        if (this.value) return this.value.toString(); //normalize to string, api returns integer
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  }
};
</script>
