import _Promise from 'babel-runtime/core-js/promise';
import _Object$assign from 'babel-runtime/core-js/object/assign';
import { BasicAuth } from "../util/header";
import { isFunction } from '../util';

function OauthToken(client, data) {
    this.client = client;
    this.data = data;
    this.tokenType = data.token_type && data.token_type.toLowerCase();
    this.accessToken = data.access_token;
    this.refreshToken = data.refresh_token;

    this.expiresIn(Number(data.expires_in));
}

OauthToken.prototype.expired = function () {
    return Date.now() > this.expires.getTime();
};

OauthToken.prototype.expiresIn = function (duration) {
    if (typeof duration === 'number') {
        this.expires = new Date();
        this.expires.setSeconds(this.expires.getSeconds() + duration);
    } else if (duration instanceof Date) {
        this.expires = new Date(duration.getTime());
    } else {
        throw new TypeError('Unknown duration: ' + duration);
    }

    return this.expires;
};

OauthToken.prototype.refresh = function (opts) {
    var self = this;
    var options = _Object$assign({}, this.client.options, opts);

    if (!this.refreshToken) {
        return _Promise.reject(new Error('No refresh token'));
    }

    var data = {
        refresh_token: this.refreshToken,
        grant_type: 'refresh_token'

        //reset the current token to clear the authoriation header (see request interceptor)
    };this.client.resetToken();
    var headers = {
        authorization: BasicAuth(options.clientId, options.clientSecret)
    };

    return this.client.post(options.tokenURI, data, { headers: headers }).then(function (res) {
        var token = self.client.setToken(res);
        if (isFunction(self.client.cbTokenChanged)) self.client.cbTokenChanged(token);

        return token;
    });
};

OauthToken.prototype.revoke = function (opts) {
    var self = this;
    var options = _Object$assign({}, this.client.options, opts);

    var data = {
        token: this.accessToken
    };
    return this.client.post(options.revokeURI, data, {}).then(function (_) {
        // self.client.resetToken()
    });
};

export default OauthToken;