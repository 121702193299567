import moment from 'moment';

export default {
	install(Vue) {

		Vue.filter('moment', function (date, _format = '') {
			return moment(date).format(_format);
		});

		Vue.filter('time', function (date, _format = 'HH:mm') {
			return moment(date).format(_format);
		});

		Vue.filter('month', function (date, _format = 'MMM') {
			return moment(date).format(_format);
		});

		Vue.filter('day', function (date, _format = 'D') {
			return moment(date).format(_format);
		});

		Vue.filter('date', function (date, _format = 'DD MMM YYYY') {
			return moment(date).format(_format);
		});

		Vue.filter('time_ago', function (date) {
			return moment(date).fromNow();
		});

		Vue.filter('full_day', function (date, _format = 'dddd') {
			return moment(date).format(_format);
		});

		Vue.filter('year', function (date, _format = 'YYYY') {
			return moment(date).format(_format);
		});

		Vue.filter('yesno', function (v) {
			return v ? 'yes' : 'no'
		});
	}
}