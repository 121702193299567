<template>
  <div>
    <el-tag
      :key="tag"
      v-for="tag in tags"
      closable
      v-bind="$attrs"
      :disable-transitions="true"
      @close="removeTag(tag)"
    >{{tag}}</el-tag>
    <el-autocomplete
      class="input-new-tag"
      :placeholder="$t(placeholder)"
      v-if="tagInputVisible"
      v-model="newTag"
      ref="saveTagInput"
      name="tag"
      v-validate="{ required: true }"
      @keyup.enter.native="addTag"
      @select="selectSuggestion"
      clearable
      size="small"
      style="width:300px;"
      autocomplete="none"
      :fetch-suggestions="autocompleteSearch"
    ></el-autocomplete>
    <el-button
      class="button-new-tag"
      size="small"
      @click="showInput"
      v-show="!hideNewTagButton && !tagInputVisible"
    >
      <slot name="button">
        <i class="fa fa-plus-circle m-r-5"></i>
        {{buttonLabel}}
      </slot>
    </el-button>
  </div>
</template>


<script>
import { Validator } from "vee-validate";
import _isEqual from "lodash/isEqual";
import _sortBy from "lodash/sortBy";
import _cloneDeep from "lodash/cloneDeep";

export default {
  data() {
    return {
      tags: [],
      tagInputVisible: false,
      newTag: "",
      options: []
    };
  },
  computed: {
    hideNewTagButton() {
      return (
        this.hideButtonOnAllSelected &&
        _isEqual(_sortBy(this.options), _sortBy(this.tags))
      );
    }
  },
  methods: {
    autocompleteSearch(needle, callback) {
      var results = needle
        ? this.options.filter(o => {
            return o.toLowerCase().indexOf(needle.toLowerCase()) === 0;
          })
        : this.options;
      results = results.filter(a => !this.tags.includes(a));
      callback(
        results.map(a => {
          return {
            value: a
          };
        })
      );
    },

    selectSuggestion(tag) {
      this.newTag = tag.value;
      this.addTag();
    },

    removeTag(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
      this.$emit("input", this.tags);
    },

    showInput() {
      this.tagInputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    addTag(tag) {
      let newTag = this.newTag;
      if (newTag === "") {
        this.tagInputVisible = false;
        return;
      }
      const isNewTag = !this.options.includes(newTag);
      const isUnSelected = !this.tags.includes(newTag);

      if ((isNewTag && this.allowCreate) || isUnSelected) {
        this.tags.push(newTag);
        this.tagInputVisible = false;
        this.newTag = "";
        this.$emit("input", this.tags);
        // save new suggestion to list
        if (isNewTag && this.allowCreate) this.options.push(newTag);
      } else {
        this.newTag = "";
        this.tagInputVisible = false;
        return;
      }
    }
  },
  watch: {
    value: {
      handler(v) {
        if (Array.isArray(v)) {
          this.tags = _cloneDeep(v);
        } else {
          this.tags = [];
        }
      },
      immediate: true
    },
    suggestions: {
      handler(v) {
        this.options = Array.isArray(v) ? v : [];
      },
      immediate: true
    }
  },
  props: {
    value: {
      type: Array,
      default: []
    },
    // must be an array of strings
    suggestions: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: "Tag"
    },
    buttonLabel: {
      type: String,
      default: "Tag"
    },
    allowCreate: {
      type: Boolean,
      default: false
    },
    hideButtonOnAllSelected: {
      type: Boolean,
      default: true
    }
  }
};
</script>


<style lang="scss" scoped>
.el-tag + .el-tag,
.el-tag + .button-new-tag,
.el-tag + .input-new-tag {
  margin-left: 5px;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  vertical-align: bottom;
}
</style>
