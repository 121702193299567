<template>
  <div id="__table_page_layout__">
    <div class="__table_page_layout_content__">
      <div
        class="xs-mb-20 xs-pb-10 b-b xs-pl-10 weight-book text-primary"
        v-if="!!$slots['page-title']"
      >
        <slot name="page-title"/>
      </div>
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "TablePageLayout"
};
</script>

<style lang="scss">
#__table_page_layout__ {
  position: relative;
  display: flex;
  justify-content: flex-start;
  .__table_page_layout_content__ {
    max-width: 1400px;
    width: 100% !important;
    .el-card__body {
      padding: 10px 0px;
    }
  }
}
</style>
