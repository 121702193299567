import { apply } from '@jorgenevens/rest-store/vuex';
import { attach } from '@jorgenevens/rest-store';
import zfhqClient from '@/config/zfhq-api'

import { fetchPage, fetchResource} from '../helpers/actions';
import _mutations from '../helpers/mutations';

export const mutations = {
    ..._mutations
};

export const state = {
    ...apply(attach)
};

export const actions = {
    fetch: fetchResource(options => {
        return zfhqClient.get(`v5/countries/${options.id}`);
    }),

    fetchPage: fetchPage(options => {
        const {
            page = 1,
            pageSize = 250,
        } = options;

        return zfhqClient.get('v5/countries', {
            sort: 'name',
            page: {
                size: pageSize,
                number: page
            }
        });
    }),
}