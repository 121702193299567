<template>
  <div>dashboard {{account.company_name}}
  	<br/>
  	<CumulioDashboard :id="dashboardId" :params="params"/>

  </div>

</template>

<script>
import _get from 'lodash/get';
import { mapGetters } from 'vuex';
import CumulioDashboard from '@/components/app/cumulio/Dashboard';
import { getResource } from "@/helpers/rest-store-vuex";
export default {
	computed: {
        dashboardId() {
            return "3b12ad9a-b238-439e-8c4e-6ed36745443e"
        },
		params(){
			return {
				accountId: this.$route.params.accountId
			}
		},
		accountId(){
			return this.$route.params.accountId;
		},
	    account: getResource(cmp => cmp.accountId, {
	      namespace: "accounts",
	      condition: cmp => cmp.accountId
	    })
	},
	components: {
		CumulioDashboard
	},

};
</script>
	
<style>
</style>
