import UsersOverview from '@/views/users/Overview/Index.vue';
import NewUser from '@/views/users/NewUser/Index.vue'

export default [
    {
        path: '/users',
        name: 'users.index',
        component: UsersOverview,
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },
    {
        path: '/users/new',
        name: 'users.new',
        component: NewUser,
        props: {
            roles: ['operator_admin', 'operator'],
        },
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },
    {
        path: '/admin-users',
        name: 'users.admin.index',
        component: UsersOverview,
        props: {
            roles: ['super_admin', 'admin'],
            pageTitle: "Admin Users"
        },
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },
    {
        path: '/admin-users/new',
        name: 'users.admin.new',
        component: NewUser,
        props: {
            roles: ['super_admin', 'admin'],
        },
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },

]