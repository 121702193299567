import { apply } from '@jorgenevens/rest-store/vuex';
import { attach } from '@jorgenevens/rest-store';
import zfhqClient from '@/config/zfhq-api'

import { fetchPage, fetchResource, createResource, updateResource } from '../helpers/actions';
import _mutations from '../helpers/mutations';


export const mutations = {
    ..._mutations
};

export const state = {
    ...apply(attach)
};

export const actions = {

    fetch: fetchResource(options => {
        const _data = {
            include: ''
        }
        return zfhqClient.get(`v5/accounts/${options.id}`, _data);
    }),

    fetchPage: fetchPage(options => {
        const {
            page = 1,
            pageSize = 25,
            filters,
            sort = 'company_name',
            needle
        } = options;

        let _filters = {
            ...filters,
        }

        if (needle) {
            _filters.needle = needle;
        }

        return zfhqClient.get('v5/accounts', {
            sort,
            page: {
                size: pageSize,
                number: page,
            },
            filters: _filters,
        });
    }),
}