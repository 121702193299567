<template>
 <el-container direction="vertical">
    <page-title>Settings</page-title>
  <el-card shadow="never">
    <el-form
    label-position="left"
    label-width="150px"
    :model="data"
    :rules="rules"
    ref="loactionForm"
    v-loading="!isOK(locationSettings)"
    >
    <el-form-item label="Operator booker" prop="operator_booker_version">
      <el-select
      v-model="data.meeting.operator_booker_version"
      v-validate="'required'">
        <el-option
        v-for="item in bookerOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"/>
      </el-select>
    </el-form-item>
    </el-form>
        <el-button type="primary" v-loading="loading" plain @click="handleSubmit">
    <i class="fa fa-save xs-mr-10"></i>Save
    </el-button>
  </el-card>
        <pre>data: {{data}}</pre>

      <pre>locationSettings: {{locationSettings}}</pre>

  </el-container>
</template>

<script>
import { getResource } from "@/helpers/rest-store-vuex";
import PageTitle from "@/components/ui/page-title";
import { mapActions } from "vuex";

import _cloneDeep from "lodash/cloneDeep";
import { isOK, isLoading } from "@jorgenevens/rest-store";

export default {
  components: {
    PageTitle
  },
  data(){
    return {
      data: {},
      rules: {},
      loading: false,
    }
  },
  computed: {
    locationId() {
      return this.$route.params.locationId;
    },
    locationSettings: getResource(cmp => cmp.locationId, {
      namespace: "locationSettings",
      condition: cmp => cmp.locationId
    }),
        bookerOptions() {
      return [
        { value: 'V1', label: 'V1' },
        { value: 'V2', label: 'V2'},
      ];
    },
  },
    watch: {

  locationSettings: {
    immediate: true,
    handler(v) {
    if (!isLoading(v) && isOK(v)) {
      this.data = {
      ..._cloneDeep(v),
      }
    }
    }
  }
  },
  methods: {
      ...mapActions("locationSettings", {
    update: "update"
  }),
  isLoading,
  isOK,
    handleSubmit(){
      this.loading = true
    this.update({
    id: this.locationId,
    settings: this.data
    }).then(()=> {
      this.loading = false,
          this.$notify({
              title: this.$t('Success'),
              message: this.$t('Location settings updated'),
              type: 'success'
            });
    })
    }
  }
};
</script>