import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";
import { USER_ROLE } from '@/config/constants';
Vue.use(VueRouter);

const router = new VueRouter({
  base: process.env.BASE_URL,

  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "history",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((routeTo, from, next) => {
  // if a route is not found go to error
  if (routeTo.matched.length === 0) return next({ name: 'NotFound' });
  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some(route => route.meta.authRequired);
  const adminRequired = routeTo.matched.some(route => route.meta.adminRequired);
  // If auth isn't required for the route, just continue.
  if (!authRequired) return next();

  let user = store.getters["auth/currentUser"]

  // If auth is required and the user is logged in...
  if (!_.isEmpty(user)) {
    if (authRequired && (user.role === USER_ROLE.ADMIN || user.role === USER_ROLE.SUPER_ADMIN)) {
      next()
    } else {
      redirectToLogin()
    }
  } else {
    redirectToLogin()
  }

  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: "Login", query: { redirectFrom: routeTo.fullPath } });

    //window.location.href = `${process.env.VUE_APP_ZFHQ_AUTH_URI}?client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_AUTH_CALLBACK}`
  }
});

export default router;