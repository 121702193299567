<template>
  <div class="flex justify-center align-center text-center" style="height:100vh;">
    <el-card style="max-width:400px;margin:auto;" v-loading="loading">
      <div slot="header" class="title text-large text-bolder">ZapFloorHQ - Admin</div>
      <el-input type="username" v-model="username" placeholder="Email" clearable class="xs-mb-5"/>
      <el-input
        type="password"
        v-model="password"
        placeholder="Password"
        clearable
        class="xs-mb-5"
      />
      <el-button type="primary" plain @click="handleSignIn" class="xs-mt-15 full-width">Sign In</el-button>
      <div class="xs-mt-20 xs-pl-10 xs-pr-10 flex justify-between">
        <el-button type="text">Forgot Password?</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import store from "@/store";
export default {
  data() {
    return {
      username: "",
      password: "",
      loading: false
    };
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    handleSignIn() {
      (async () => {
        try {
          this.loading = true;
          await this.signIn({
            username: this.username,
            password: this.password
          });
          const redirect = this.$route.query.redirectFrom || {
            name: "Dashboard"
          };
          this.$router.push(redirect);
        } catch (err) {
          this.$notify.error({
            title: "Authentication Failed",
            message: this.$t(err)
          });
        } finally {
          this.loading = false;
        }
      })();
    }
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage) {
      const authToken = JSON.parse(localStorage.getItem("authToken"));
      const redirect = to.query.redirectFrom || { name: "Dashboard" };

      if (!!!authToken) next();
      else
        store
          .dispatch("auth/signInWithToken", { token: authToken })
          .then(() => {
            next(redirect);
          })
          .catch(err => {
            next();
          });
    }
  },
  // when route changes and this component is already rendered,
  // the logic will be slightly different.
  beforeRouteUpdate(to, from, next) {
    if (localStorage) {
      const authToken = JSON.parse(localStorage.getItem("authToken"));
      const redirect = to.query.redirectFrom || { name: "Dashboard" };

      if (!!!authToken) next();
      else
        store
          .dispatch("auth/signInWithToken", { token: authToken })
          .then(() => {
            next(redirect);
          })
          .catch(err => {
            next();
          });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-card {
  text-align: center;
  div.title {
    font-size: 20px;
    text-transform: uppercase;
  }
}
</style>