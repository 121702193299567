// Logger
import Logger from 'js-logger';
Logger.useDefaults({
    formatter: function formatter(messages, context) {
        messages.unshift('-');
        messages.unshift(new Date().toUTCString());
        if (context.name) messages.unshift(context.name);
        messages.unshift(context.level.name);
    }
});
Logger.setLevel(Logger.DEBUG);

export default Logger;