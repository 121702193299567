<template>
  <el-container direction="vertical">
    <page-title>Edit Location</page-title>

    <el-card shadow="never">
      <el-form
        ref="loactionForm"
        v-loading="loading"
        label-position="left"
        label-width="150px"
        :model="location"
        :rules="rules"
      >
        <el-form-item label="Account" prop="account_id">
          <SelectAccount v-model="location.account_id" style="width: 300px" />
        </el-form-item>
        <el-form-item label="Billing Entity" prop="billing_entity_id">
          <SelectBillingEntity
            v-model="location.billing_entity_id"
            :account-id="location.account_id"
            style="min-width: 300px"
          />
        </el-form-item>
        <el-form-item label="Alias" prop="alias">
          <el-input v-model="location.alias"></el-input>
        </el-form-item>
        <el-form-item label="Street" prop="addressline1">
          <el-input v-model="location.addressline1"></el-input>
        </el-form-item>
        <el-form-item label="Number" prop="number" style="width: 300px">
          <el-input v-model="location.number"></el-input>
        </el-form-item>
        <el-form-item label="Street 2" prop="addressline2">
          <el-input v-model="location.addressline2"></el-input>
        </el-form-item>
        <el-form-item label="Zipcode" prop="zipcode" style="width: 300px">
          <el-input v-model="location.zipcode"></el-input>
        </el-form-item>
        <el-form-item label="City" prop="city">
          <el-input v-model="location.city"></el-input>
        </el-form-item>
        <el-form-item label="State" prop="state">
          <el-input v-model="location.state"></el-input>
        </el-form-item>
        <el-form-item label="Country" prop="countrycode">
          <SelectCountry v-model="location.countrycode" style="width: 300px" />
        </el-form-item>
        <el-form-item label="Time Zone" prop="timezone">
          <SelectTimezone
            v-model="location.timezone"
            style="min-width: 300px"
          />
        </el-form-item>
        <el-form-item label="Latitude" prop="latitude" style="width: 300px">
          <el-input v-model="location.latitude"></el-input>
        </el-form-item>
        <el-form-item label="Longitude" prop="longitude" style="width: 300px">
          <el-input v-model="location.longitude"></el-input>
        </el-form-item>
        <el-form-item label="Setup billed" prop="setup_is_billed">
          <el-switch
            v-model="location.setup_is_billed"
            style="display: block"
            active-color="#13ce66"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="Is visible" prop="is_visible">
          <el-switch v-model="location.is_visible"></el-switch>
        </el-form-item>
        <el-form-item label="Manager" prop="manager">
          <SelectUser
            v-model="location.manager"
            :account-id="location.account_id"
            style="min-width: 300px"
          />
        </el-form-item>
        <el-form-item label="Location type" prop="location type">
          <SelectLocationType
            v-model="location.location_type"
            style="min-width: 300px"
          />
        </el-form-item>
      </el-form>
      <el-button type="primary" plain @click="handleSubmit">
        <i class="fa fa-save xs-mr-10"></i>Save
      </el-button>
    </el-card>
  </el-container>
</template>

<script>
import { getResource } from "@/helpers/rest-store-vuex";
import PageTitle from "@/components/ui/page-title";
import SelectAccount from "@/components/app/select-account";
import SelectCountry from "@/components/app/select-country";
import SelectBillingEntity from "@/components/app/select-billing-entity";
import SelectLocationType from "@/components/app/select-location-type";
import SelectTimezone from "@/components/app/select-timezone";
import SelectUser from "@/components/app/select-user";

import { mapActions } from "vuex";

import _cloneDeep from "lodash/cloneDeep";
import { isOK, isLoading } from "@jorgenevens/rest-store";

export default {
  components: {
    PageTitle,
    SelectAccount,
    SelectCountry,
    SelectBillingEntity,
    SelectTimezone,
    SelectUser,
    SelectLocationType,
  },
  data() {
    return {
      location: {},
      rules: {},
    };
  },
  computed: {
    loading() {
      return isLoading(this.locationData);
    },
    locationId() {
      return this.$route.params.locationId;
    },
    locationData: getResource((cmp) => cmp.locationId, {
      namespace: "locations",
      condition: (cmp) => cmp.locationId,
    }),
  },
  watch: {
    "location.account_id": {
      handler(oldVal, newVal) {
        if (oldVal !== newVal) this.location.billing_entity_id = undefined;
      },
    },
    locationData: {
      immediate: true,
      handler(v) {
        if (!isLoading(v) && isOK(v)) {
          this.location = {
            ..._cloneDeep(v),
            account_id: v.account.id,
          };
        }
      },
    },
  },
  methods: {
    ...mapActions("locations", {
      updateLocation: "update",
    }),
    handleSubmit() {
      this.updateLocation({
        id: this.locationId,
        data: this.location,
      });
    },
  },
};
</script>
