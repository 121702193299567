<template>
  <div>
    <el-row class="xs-mb-40" :gutter="20">
      <el-col :span="6">
        <counter-widget-card
          :count="currentUser.stats.total_accounts"
          icon="far fa-building"
          title="Accounts"
        ></counter-widget-card>
      </el-col>
      <el-col :span="6">
        <counter-widget-card
          :count="currentUser.stats.total_account_users"
          icon="far fa-user"
          title="Account Users"
        ></counter-widget-card>
      </el-col>
      <el-col :span="6">
        <counter-widget-card
          :count="currentUser.stats.total_customers"
          icon="fa fa-building"
          title="Customers"
        ></counter-widget-card>
      </el-col>
      <el-col :span="6">
        <counter-widget-card
          :count="currentUser.stats.total_customer_users"
          icon="fa fa-user"
          title="Customer Users"
        ></counter-widget-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <CumulioDashboard :id="dashboardId" :params="params"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import authUser from "@/mixins/authUser";
import CounterWidgetCard from "@/components/ui/counter-widget-card";
import CumulioDashboard from '@/components/app/cumulio/Dashboard';
export default {
  mixins: [authUser],
  components: {
    CounterWidgetCard,
    CumulioDashboard
  },
  computed:{
    dashboardId() {
        return "3b12ad9a-b238-439e-8c4e-6ed36745443e"
    },
  }
};
</script>

<style lang="scss">
</style>

