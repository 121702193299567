import EmailOverview from "@/views/email/overview";
import EmailTypeOverview from "@/views/email/emailType/overview";
import EmailTypeDetail from "@/views/email/emailType/detail";
import BrandingOverview from "@/views/branding/overview";
import AdminOverview from "@/views/admin/overview";

export default [
  {
    path: "/admin",
    name: "admin.index",
    component: AdminOverview,
    meta: {
      authRequired: true,
      layout: "app-layout",
    },
    redirect: {
      name: "admin.email",
    },
    children: [
      {
        path: "/email",
        name: "admin.email",
        component: EmailOverview,
        redirect: {
          name: "admin.email.emailType",
        },
        meta: {
          authRequired: true,
          layout: "app-layout",
        },
        children: [
          {
            path: "/emailType",
            name: "admin.email.emailType",
            component: EmailTypeOverview,
            meta: {
              authRequired: true,
              layout: "app-layout",
            },
          },
          {
            path: "/emailType/:id",
            name: "admin.email.emailType.detail",
            component: EmailTypeDetail,
            meta: {
              authRequired: true,
              layout: "app-layout",
            },
          },
        ],
      },
      {
        path: "/branding",
        name: "admin.branding",
        component: BrandingOverview,
        meta: {
          authRequired: true,
          layout: "app-layout",
        },
      },
    ],
  },
];
