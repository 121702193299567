<template>
  <el-dialog
    title="Update Password"
    :visible.sync="dialogVisible"
    @closed="handleClosed"
    :close-on-click-modal="false"
  >
    <el-form :model="form" :rules="rules" ref="form">
      <el-form-item label="New Password" label-width="150px" prop="password">
        <el-input v-model="form.password" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Confirm Password" label-width="150px" prop="password_confirmation">
        <el-input v-model="form.password_confirmation" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog" :disabled="isLoading">Cancel</el-button>
      <el-button type="primary" @click="handleSubmit" :disabled="isLoading">{{$t('Change Password')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { USER_ROLE, LOCALE } from "@/config/constants";
import { mapActions, mapGetters } from "vuex";
import { getResource } from "@/helpers/rest-store-vuex";
import { isLoading } from "@jorgenevens/rest-store";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      USER_ROLE,
      LOCALE,
      dialogVisible: true,
      form: {
          password: '',
          password_confirmation: ''
      },
      rules: {
        password: [{ required: true }, { message: "Password is required!" }],
        password_confirmation: [{ required: true }, { message: "Password is required!" }],
      }
    };
  },
  computed: {
    ...mapGetters("ui", ["isLoading"]),
    isUpdate() {
      return !_.isEmpty(this.data);
    },
  },
  methods: {
    ...mapActions("auth", ["updatePassword"]),
    closeDialog() {
      this.dialogVisible = false;
    },
    handleClosed() {
      this.$router.push({ path: this.$store.state.route.from.fullPath });
    },
    handleSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$startLoading();
          let _data = {
            password: this.form.password,
            password_confirmation: this.form.password_confirmation
          };
          this.updatePassword({ id: this.form.id, ..._data }).then(() => {
            this.$endLoading();
            this.closeDialog();
          });
        } else {
          this.$notify.error({
            title: "Validation Error",
            message: "Please check the form..."
          });
        }
      });
    },
    closeDialog() {
      this.dialogVisible = false;
      this.$refs["form"].resetFields();
    }
  }
};
</script>

