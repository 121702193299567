var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-page-layout',[_c('page-title',[_vm._v("Accounts")]),_c('el-row',{staticClass:"xs-mb-10",attrs:{"type":"flex","gutter":10,"justify":"space-between"}},[_c('el-col',{attrs:{"md":6}},[_c('el-add-button',{attrs:{"disabled":true},on:{"click":_vm.handleNewAccount}},[_vm._v("Account")])],1),_c('el-col',{staticClass:"text-right",attrs:{"md":18}},[_c('el-popover',{attrs:{"placement":"bottom-end","width":"300","trigger":"click"},model:{value:(_vm.filtersVisible),callback:function ($$v) {_vm.filtersVisible=$$v},expression:"filtersVisible"}},[_c('filters',{attrs:{"value":_vm.customFilters},on:{"update":_vm.applyCustomFilters}}),_c('el-button',{staticClass:"xs-mr-5",attrs:{"slot":"reference","plain":""},slot:"reference"},[_c('i',{staticClass:"fa fa-sliders-h xs-mr-10"}),_vm._v("\n          "+_vm._s(_vm.$t('Filters'))+"\n          "),_c('i',{staticClass:"fa fa-caret-down xs-ml-10"})])],1),_c('el-input',{staticStyle:{"width":"400px"},attrs:{"suffix-icon":"fa fa-search","placeholder":((_vm.$t('Search Accounts')) + "..."),"clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('el-row',{staticClass:"m-t-1",attrs:{"gutter":10}},[_c('el-col',{attrs:{"md":24}},[_c('el-card',{attrs:{"shadow":"never"}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading(_vm.accounts)),expression:"isLoading(accounts)"}],attrs:{"data":_vm.accounts,"stripe":"","size":"small","empty-text":_vm.$t('No Accounts')},on:{"sort-change":_vm.handleSortChange}},[_c('el-table-column',{attrs:{"width":"40","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{staticClass:"fa fa-circle",class:{'text-success': row.is_active, 'text-danger': !row.is_active}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Name'),"sortable":"custom","prop":"company_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-small pointer",on:{"click":function($event){return _vm.showAccount(row)}}},[_vm._v(_vm._s(row.company_name))]),(row.is_demo_account)?_c('el-tag',{staticClass:"xs-ml-10 text-uppercase",attrs:{"type":"primary","size":"mini","disable-transitions":true}},[_vm._v("demo account")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Locations'),"width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(row.total_locations))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Date Created'),"width":"120","sortable":"custom","prop":"created_at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(_vm._f("date")(row.created_at)))])]}}])}),_c('el-table-column',{attrs:{"width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showAccount(row)}}},[_c('i',{staticClass:"fa fa-search"})])]}}])})],1)],1)],1)],1),_c('el-row',{staticClass:"xs-mt-20 xs-mb-10",attrs:{"gutter":10}},[_c('el-col',{attrs:{"md":24}},[_c('el-pagination',{staticClass:"text-center",staticStyle:{"margin-bottom":"20px"},attrs:{"disabled":_vm.isLoading(_vm.accounts),"background":"","layout":"prev, pager, next","current-page":_vm.page,"page-count":_vm.pages},on:{"current-change":_vm.onPageChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }