<template>
  <el-container direction="vertical">
    <el-row type="flex" justify="space-between" class="xs-mb-20">
      <h3 style="line-height:45px;">
        <i class="far fa-building xs-mr-10"></i>
        {{location.alias}}
      </h3>

      <el-menu :default-active="childPath" router mode="horizontal" active-text-color="#009fe1">
        <el-menu-item
          index="/"
          :route="{name: 'location.dashboard', params: { locationId: locationId }}"
        >
          <i class="fa fa-info-circle xs-mr-10"></i>Info
        </el-menu-item>
        <el-menu-item
          index="/units"
          :route="{name: 'location.units', params: { locationId: locationId }}"
        >
          <i class="fa fa-info-circle xs-mr-10"></i>Units
        </el-menu-item>
        <el-menu-item
          index="/edit"
          :route="{name: 'location.edit', params: { locationId: locationId }}"
        >
          <i class="fa fa-edit xs-mr-10"></i>Edit Location
        </el-menu-item>

        <el-menu-item
          index="/settings"
          :route="{name: 'location.settings', params: { locationId: locationId }}"
        >
          <i class="fa fa-cogs xs-mr-10"></i>Settings
        </el-menu-item>
      </el-menu>
    </el-row>

    <router-view :key="$route.fullPath"/>
    <el-row type="flex" justify="start" class="xs-mt-20">
      <el-link @click="$router.push({name: 'locations.index'})" :underline="false">
        <i class="fa fa-arrow-alt-circle-left xs-mr-10"></i>Back
      </el-link>
    </el-row>
  </el-container>
</template>

<script>
import { getResource } from "@/helpers/rest-store-vuex";

import PageTitle from "@/components/ui/page-title";

export default {
  components: {
    PageTitle
  },
  computed: {
    childPath() {
      let match = this.$route.path.replace(/^(.*[\\\/])/, "/");
      if (match) return match;
      return this.$route.path;
    },
    locationId() {
      return this.$route.params.locationId;
    },
    location: getResource(cmp => cmp.locationId, {
      namespace: "locations",
      condition: cmp => cmp.locationId
    })
  }
};
</script>

<style <style lang="scss" scoped>
.el-menu--horizontal {
  border-bottom: none !important;
  .el-menu-item {
    border-bottom: none !important;
  }
}
</style>

