<template>
  <div>
    <el-select
      v-model="selectedValue"
      placeholder="Select location type"
      v-bind="$attrs"
      clearable
      option-label="label"
      option-key="id"
    >
      <el-option
        v-for="item in locationTypeOptions"
        :key="item.id"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedValue: "",
    };
  },

  computed: {
    locationTypeOptions() {
      return [
        { value: "flex_office", id: 0, label: "Flex office" },
        { value: "enterprise", id: 1, label: "Enterprise" },
      ];
    },
  },
  watch: {
    selectedValue: {
      immediate: true,
      handler(v) {
        this.$emit("input", v);
      },
    },
    value: {
      handler(v) {
        this.selectedValue = v;
        this.$emit("input", v);
      },
      immediate: true,
    },
  },
};
</script>
