<template>
  <div class="text-center xs-m-50">
    <div style="font-size:200px;" class="text-bold text-primary">404</div>
    <div class="text-large xs-p-20">Page Not Found</div>
    <router-link :to="{name: 'Dashboard'}">Back</router-link>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
