<template>
  <div>
    <el-row type="flex" justify="space-between">
      <el-checkbox v-model="enableIsActive">
        <span class="weight-semi-bold text-smaller xs-mb-5">Active</span>
      </el-checkbox>
      <el-switch v-model="filters.is_active" class="xs-ml-20 xs-mr-20" :disabled="!enableIsActive"></el-switch>
    </el-row>
    </el-row>
    <el-button
      plain
      class="xs-mt-10"
      size="small"
      @click="applyFilters"
      style="width:100%;"
    >{{$t('Apply')}}</el-button>
    <pre>{{filters}}</pre>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    value: {
      handler(v) {
        this.filters = { ...v };
      },
      immediate: true
    },
    enableIsActive: {
      handler(v) {
        if (v && this.filters.is_active === undefined)
          this.filters.is_active = true;
      }
    }
  },
  data() {
    return {
      enableIsActive: false,
      filters: {
        is_active: undefined,
      }
    };
  },
  methods: {
    applyFilters() {
      let _filters = {
        ...this.filters
      };
      if (!this.enableIsActive) delete _filters.is_active;

      this.$emit("update", _filters);
    }
  }
};
</script>

<style>
</style>
