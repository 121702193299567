import { apply } from '@jorgenevens/rest-store/vuex';
import { attach } from '@jorgenevens/rest-store';
import zfhqClient from '@/config/zfhq-api'

import { fetchPage, fetchResource, fetchAll, createResource, updateResource } from '../../helpers/actions';
import _mutations from '../../helpers/mutations';


export const mutations = {
    ..._mutations
};

export const state = {
    ...apply(attach)
};

function fetchPageFromApi(options) {
    const {
        page = 1,
        pageSize = 10,
    } = options;

    return zfhqClient.get(`v5/countries`, {
        page: {
            size: pageSize,
            number: page
        }
    });
}

export const actions = {

    fetch: fetchResource(options => {
        const _data = {
            include: ''
        }
        return zfhqClient.get(`v5/countries/${options.id}`, _data);
    }),

    fetchPage: fetchPage(options => {
        const {
            page = 1,
            pageSize = 25,
            filters,
            sort = 'name',
            needle
        } = options;

        let _filters = {
            ...filters,
        }

        if (needle) {
            _filters.needle = needle;
        }

        return zfhqClient.get('v5/countries', {
            sort,
            page: {
                size: pageSize,
                number: page,
            },
            filters: _filters,
        });
    }),

    fetchAll: fetchAll((page, options) => fetchPageFromApi({
        ...options,
        pageSize: Math.min(20, options.pageSize || 20),
        page
    })),
}