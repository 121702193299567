<template>
  <component
    :is="element"
    class="xs-mb-20 xs-pb-10 b-b xs-pl-10 weight-book text-primary"
    v-if="!!$slots['default']"
  >
    <slot/>
  </component>
</template>

<script>
export default {
  props: {
    element: {
      type: String,
      default: "div",
      validator: function(value) {
        // The value must match one of these strings
        return ["div"].indexOf(value) !== -1;
      }
    }
  }
};
</script>

<style>
</style>
