import _Object$keys from "babel-runtime/core-js/object/keys";
import _Object$assign from "babel-runtime/core-js/object/assign";
import _getIterator from "babel-runtime/core-js/get-iterator";
/**
 * Contents of this file has been taken from
 * https://github.com/Skysplit/json-api-deserializer
 *
 * It was not pre-compiled and unmaintained so we moved it into our own
 * repository so we can make changes and it will automatically be compiled
 */

var normalizeToArray = function normalizeToArray(list) {
	return Array.isArray(list) ? list : [list];
};

var getResource = function getResource(resources, type, id) {
	// lookup resource in (included) resources
	var resource = resources[type];

	// return object with id if the resource was not inlcuded 
	if (!resource) return { id: id

		// return whole resource if it was included
	};return resource[id];
};

var parseResources = function parseResources(list) {
	var resources = {};

	if (!list) {
		return resources;
	}

	var _iteratorNormalCompletion = true;
	var _didIteratorError = false;
	var _iteratorError = undefined;

	try {
		for (var _iterator = _getIterator(normalizeToArray(list)), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
			var resource = _step.value;
			var type = resource.type,
			    id = resource.id,
			    attributes = resource.attributes;


			resources[type] = resources[type] || {};
			resources[type][id] = _Object$assign({ id: id }, attributes);
		}
	} catch (err) {
		_didIteratorError = true;
		_iteratorError = err;
	} finally {
		try {
			if (!_iteratorNormalCompletion && _iterator.return) {
				_iterator.return();
			}
		} finally {
			if (_didIteratorError) {
				throw _iteratorError;
			}
		}
	}

	return resources;
};

var mapRelationsToResources = function mapRelationsToResources(list, resources) {
	return normalizeToArray(list).map(function (_ref) {
		var id = _ref.id,
		    type = _ref.type,
		    _ref$relationships = _ref.relationships,
		    relationships = _ref$relationships === undefined ? {} : _ref$relationships;
		return injectRelations(id, type, resources, relationships);
	});
};

var injectRelations = function injectRelations(id, type, resources) {
	var relationships = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

	return _Object$keys(relationships).map(function (name) {
		var data = relationships[name].data;
		// Skip if there is no data field

		if (!data) {
			// when relationship is null set relationship with id equal to null
			resources[type][id][name] = { id: null };
			return;
		}

		var relation = void 0;

		if (Array.isArray(data)) {
			relation = data.map(function (data) {
				return getResource(resources, data.type, data.id);
			});
		} else {
			relation = getResource(resources, data.type, data.id);
		}

		resources[type][id][name] = relation;

		return getResource(resources, type, id);
	});
};

export default (function (_ref2) {
	var data = _ref2.data,
	    _ref2$included = _ref2.included,
	    included = _ref2$included === undefined ? [] : _ref2$included;

	if (!data) {
		return;
	}
	/**
  * parse resources.
  * included first, then requested resource
  */
	var resources = _Object$assign({}, parseResources(included), parseResources(data));

	mapRelationsToResources(included, resources), mapRelationsToResources(data, resources);

	if (Array.isArray(data)) {
		return data.map(function (_ref3) {
			var type = _ref3.type,
			    id = _ref3.id;
			return getResource(resources, type, id);
		});
	} else {
		return getResource(resources, data.type, data.id);
	}
});