import zfhqClient from '@/config/zfhq-api'
export default {
	methods: {
		fetchUploadUrl: function (name, prefix) {
			return new Promise(function (resolve, reject) {
				zfhqClient.get('v5/aws/signed_url', {
					file_name: name,
					prefix: prefix
				})
					.then(res => {
						resolve(res)
					})
					.catch(err => {
						reject(err)
					});
			});
		}
	}
}
