<template>
	<div>
		<el-select
			v-bind="$attrs"
			v-model="selectedUser"
			filterable
			:filter-method="search"
			:loading="isLoading(users)"
			:placeholder="placeholder || $t('Select user')">
	        <el-option
	            v-for="item in users"
	            :key="item.id"
	            :label="item.full_name"
	            :value="item.id" />
		</el-select>
	</div>

</template>

<script>
import { isLoading } from '@jorgenevens/rest-store';
import { getPage } from '@jorgenevens/rest-store/vuex';
import _debounce from 'lodash/debounce';

export default {
    name: 'AppUserSelect',

	props: {
		placeholder: {
			type: String
		},
		value: {
			type: String | Object
		},
		customerId: {
			type: [String, Number]
		},
		pageSize: {
			type: Number,
			default: 300
		},
		filters: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			query: undefined
		};
	},

	methods: {
		isLoading,

		search: _debounce(function(query) {
			this.query = query || undefined;
		}, 250)
	},

	computed: {
		users: getPage(() => 1, 'users.select', {
            namespace: 'users',
            condition: cmp => cmp.customerId,
            params: cmp => ({
                pageSize: cmp.pageSize,
                customerId: cmp.customerId,
                fields: 'full_name',
                sort: 'full_name',
                filters: {
                    ...cmp.filters,
                    needle: cmp.query,
                    organisation_id: cmp.customerId
                }
            })
        }),

		selectedUser: {
			get() { return this.value; },
			set(v) { this.$emit('input', v); }
		}
	}
};
</script>
