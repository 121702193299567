import Dashboard from '@/views/Dashboard.vue'
import NotFound from '@/views/NotFound.vue'
var autoLoad = [];

function loadRoutes(r) {
    r.keys().forEach(key => {
        const routes = r(key).default
        if (routes)
            autoLoad.push(...routes)
    })
}

loadRoutes(require.context('./', true, /^((?!(index)\.).)*\.js$/));

export default [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            authRequired: true,
            layout: 'app-layout'
        },
    },
    {
        path: '/error/not-found',
        name: 'NotFound',
        component: NotFound,
        meta: {
            authRequired: false,
        },
    },
    ...autoLoad
]