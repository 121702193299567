<template>
  <table-page-layout>
    <el-row type="flex" :gutter="10" justify="space-between" class="xs-mb-10">
      <el-col :md="24" class="text-right">
        <!-- <el-input
          v-model="search"
          suffix-icon="fa fa-search"
          :placeholder="`${$t('Search')}...`"
          clearable
          style="width: 400px"
        ></el-input> -->
      </el-col>
    </el-row>
    <el-row :gutter="10" class="m-t-1">
      <el-col :md="24">
        <el-card shadow="never">
          <el-table
            v-loading="isLoading(brandings)"
            :data="brandings"
            stripe
            size="small"
            :empty-text="$t('No whitelabel awaiting approval')"
          >
            <el-table-column :label="$t('Domain')" prop="domain" />
            <el-table-column :label="$t('Status')" prop="status" />
            <el-table-column width="120">
              <template slot-scope="{ row }">
                <el-button size="small" @click="onApprove(row)">
                  {{ $t("Approve") }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <!-- <el-row :gutter="10" class="xs-mt-20 xs-mb-10">
      <el-col :md="24">
        <el-pagination
          :disabled="isLoading(brandings)"
          class="text-center"
          style="margin-bottom: 20px"
          background
          layout="prev, pager, next"
          :current-page="page"
          :page-count="pages"
          @current-change="onPageChange"
        />
      </el-col>
    </el-row> -->
  </table-page-layout>
</template>

<script>
import { getPage } from "@/helpers/rest-store-vuex";
import { mapActions } from "vuex";
import { isLoading } from "@jorgenevens/rest-store";

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    brandings: getPage(
      () => 1,
      () => "all-brandings",
      {
        namespace: "branding",
        params: () => ({
          pageSize: 300,
          filters: {
            status: "not_approved",
          },
        }),
      }
    ),
  },
  methods: {
    ...mapActions("branding", {
      updateBranding: "update",
    }),
    isLoading,
    onApprove(whitelabel) {
      this.updateBranding({
        id: whitelabel.id,
        status: "approved",
      })
        .then(() => {
          this.$notify({
            title: this.$t("Success"),
            message: this.$t("Whitelabel approved"),
            type: "success",
          });
        })
        .catch((err) => this.$showApiError(err));
    },
  },
};
</script>

<style></style>
