var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-page-layout',[_c('el-row',{staticClass:"m-t-1",attrs:{"gutter":10}},[_c('el-col',{attrs:{"md":24}},[_c('el-card',{attrs:{"shadow":"never"}},[_c('el-table',{attrs:{"data":_vm.operatorintegrations,"stripe":"","size":"small","empty-text":_vm.$t('No modules')}},[_c('el-table-column',{attrs:{"label":_vm.$t('Integration name'),"sortable":"custom","prop":"integrationname","width":"220","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n              "+_vm._s(row.integration.title)+"\n            ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Integration name'),"prop":"module_nm","width":"220","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n              "+_vm._s(row.integration.title)+"\n            ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Start date'),"prop":"module_nm","width":"220","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n              "+_vm._s(row.created_at)+"\n            ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Requested date'),"prop":"module_nm","width":"220","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n              "+_vm._s(row.date_status_changed)+"\n            ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Billable'),"align":"center","width":"180","sortable":"custom","prop":"billable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{staticClass:"fa fa-circle",class:{'text-success': row.billable, 'text-danger': !row.billable}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('Status'),"align":"center","width":"180","sortable":"custom","prop":"billable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n              "+_vm._s(row.status)+"\n            ")]}}])}),_c('el-table-column',{attrs:{"width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.show(row)}}},[_c('i',{staticClass:"fa fa-search"})])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }