<template>
  <div>
    <div v-if="menuOpen" class="app-menu-overlay"></div>
    <el-container>
      <el-aside>
        <el-row
          type="flex"
          justify="center"
          align="middle"
          style="height:60px;"
          class="b-r pointer"
        >
          <img src="@/assets/logo.png" height="30" @click="$router.push({name: 'Dashboard'})">
        </el-row>
        <side-nav class="__side_nav__"/>
      </el-aside>
      <el-container>
        <el-header class="b-b">
          <el-popover
            placement="bottom-start"
            width="450"
            v-model="selectAccountDialogVisible"
            v-show="false"
          >
            <select-current-account @on-select="selectAccountDialogVisible =false"/>
            <span slot="reference" class="pointer" style="line-height:20px;">
              <i class="far fa-user-circle xs-mr-10"></i>
              <template v-if="isOK(currentAccount)">{{currentAccount.company_name}}</template>
              <template v-else>Select Current Account</template>
            </span>
          </el-popover>
          <div>
            <el-dropdown trigger="click" @command="handleProfileMenuCommand">
              <span class="el-dropdown-link pointer xs-mr-10" style="line-height: 40px;">
                <i class="fa fa-user-circle xs-mr-5"/>
                {{currentUser.initialName}}
                <i class="fa fa-caret-down xs-ml-10"/>
              </span>
              <el-dropdown-menu slot="dropdown" class="text-right text-book">
                <el-dropdown-item class="text-small" :command="{name: 'Logout'}">
                  <i class="fa fa-sign-out-alt xs-mr-10"/>Sign Out
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <el-main v-loading.fullscreen.lock="isLoading" @keyup.alt.67="showInfo" tabindex="0">
          <slot/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import authUser from "@/mixins/authUser";
import currentAccountMixin from "@/mixins/current_account";
import SideNav from "./SideNav";
import SelectCurrentAccount from "@/views/accounts/SelectCurrentAccount/Index";
import { mapActions, mapGetters, mapState } from "vuex";
import { isOK } from "@jorgenevens/rest-store";

export default {
  mixins: [authUser, currentAccountMixin],
  data() {
    return {
      selectAccountDialogVisible: false,
      selectedAccountId: null
    };
  },
  methods: {
    isOK,
    ...mapActions("ui", ["toggleMenu", "closeMenu"]),
    ...mapActions("admin", ["setCurrentAccount"]),
    handleProfileMenuCommand(cmd) {
      this.$router.push(cmd);
    }
  },
  computed: {
    ...mapGetters("ui", ["menuOpen", "isLoading"])
  },
  components: {
    SideNav,
    SelectCurrentAccount
  },
  created() {}
};
</script>
<style lang="scss">
.el-header {
  z-index: 40;
  width: 100%;
  height: 60px !important;
  padding: 0 10px;
  line-height: 60px;
  display: flex;
  justify-content: flex-end;
  background-color: white;
  .el-badge {
    display: inline-flex;
    .el-badge__content {
      font-size: inherit !important;
    }
  }
}
.el-main {
  padding: 20px !important;
  height: calc(100vh - 60px);
  background-color: white;
}
.el-aside {
  width: 64px !important;
  background-color: white;
  .el-menu {
    height: calc(100vh - 60px);
    .el-menu-item:first {
      margin-top: 20px;
    }
  }
  .el-menu-item,
  .el-submenu {
    i {
      color: white;
    }
    &.is-active {
      i {
        color: #009fe1;
      }
    }
    text-align: center;
    i.fa,
    i.far {
      margin-right: 0px !important;
    }
  }
}

.__side_nav__:not(.el-menu--collapse) {
  width: 250px !important;
  .el-menu-item,
  .el-submenu {
    i.fa,
    i.far {
      margin-right: 10px !important;
    }
  }
}
</style>

