import { apply } from "@jorgenevens/rest-store/vuex";
import { attach } from "@jorgenevens/rest-store";
import zfhqClient from "@/config/zfhq-api";

import {
  fetchPage,
  fetchResource,
  createResource,
  updateResource,
} from "../helpers/actions";
import _mutations from "../helpers/mutations";

export const mutations = {
  ..._mutations,
};

export const state = {
  ...apply(attach),
};

export const actions = {
  fetch: fetchResource((options) => {
    const { id, domain } = options;
    return zfhqClient
      .get(`v5/brandings?domain=${domain}`)
      .catch(() => ({ data: { id } }));
  }),

  fetchPage: fetchPage((options) => {
    const { page = 1, pageSize = 50, filters } = options;
    return zfhqClient
      .get("v5/brandings", {
        page: {
          size: pageSize,
          number: page,
        },
        filters,
      })
      .then((res) => console.log("brandings", res) || res);
  }),

  update: updateResource((options) => {
    const { id, ...branding } = options;
    return zfhqClient.patch(`v5/brandings/${id}`, { branding });
  }),

  create: createResource((options = {}) => {
    const { ...branding } = options;
    const url = "v5/brandings";
    return zfhqClient.post(url, { branding });
  }),
};
