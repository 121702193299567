<template>
 <el-container direction="vertical">
    <page-title>Info</page-title>

    <pre>{{location}}</pre>
  </el-container>
</template>

<script>
import { getResource } from "@/helpers/rest-store-vuex";
import PageTitle from "@/components/ui/page-title";

export default {
  components: {
    PageTitle
  },
  computed: {
    locationId() {
      return this.$route.params.locationId;
    },
    location: getResource(cmp => cmp.locationId, {
      namespace: "locations",
      condition: cmp => cmp.locationId
    })
  }
};
</script>