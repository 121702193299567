<template>

  <table-page-layout>
  <el-link @click="$router.push({name: 'integrations.overview', params:{accountId: accountId}})" :underline="false">
    <i class="fa fa-arrow-alt-circle-left xs-mr-10"></i>Back
  </el-link>
    <el-row :gutter="10" class="m-t-1">
      <el-col :md="24">
      <el-form
        label-position="left"
        label-width="150px"
        :model="accountIntegration"
        ref="loactionForm"
        v-loading="isLoading(accountIntegrationData)"
      >
        <h1>{{accountIntegration.integration.title}}</h1>
        <h2>Status</h2>
        <el-form-item label="Requested Date" prop="date_requested">
           <el-date-picker type="date" placeholder="Requested date" v-model="accountIntegration.date_requested" style="width: 100%;"></el-date-picker>
        </el-form-item>
        <el-form-item label="Start Date" prop="start_date">
          <el-date-picker type="date" placeholder="Start date" v-model="accountIntegration.start_date" style="width: 100%;"></el-date-picker>
        </el-form-item>
        <el-form-item label="Price setup fee" prop="price_setup_fee">
          <el-input type="number" v-model="accountIntegration.price_setup_fee"></el-input>
        </el-form-item>
        <el-form-item label="Setup Billed" prop="setup_is_billed">
          <el-switch
            style="display: block"
            v-model="accountIntegration.setup_is_billed"
            active-color="#13ce66"
            >
          </el-switch>
        </el-form-item>
        <el-form-item label="Billable" prop="billable">
          <el-switch
            style="display: block"
            v-model="accountIntegration.billable"
            active-color="#13ce66"
            >
          </el-switch>
        </el-form-item>
        <el-form-item label="Status">
          <el-select v-model="accountIntegration.status" placeholder="Status">
            <el-option label="Approved" value="approved"></el-option>
            <el-option label="Requested" value="requested"></el-option>
            <el-option label="Active" value="active"></el-option>
            <el-option label="Inactive" value="inactive"></el-option>
          </el-select>
        </el-form-item>
        <h2>Pricing</h2>
        <h4><b>In contract per month</b></h4>
        <el-form-item label="Member Price" prop="contract_price_per_month">
          <el-input type="number" v-model="accountIntegration.contract_price_per_month"></el-input>
        </el-form-item>

        <el-form-item label="User Price" prop="contract_price_per_user_per_month">
          <el-input type="number" v-model="accountIntegration.contract_price_per_user_per_month"></el-input>
        </el-form-item>

        <el-form-item label="Total members" prop="contract_members_per_month">
          <el-input type="number" v-model="accountIntegration.contract_members_per_month"></el-input>
        </el-form-item>
        <el-form-item label="Total users" prop="price_per_month">
          <el-input type="number" v-model="accountIntegration.contract_users_per_month"></el-input>
        </el-form-item>
        <el-form-item label="Billable per user" prop="billable_per_active_user">
          <el-switch
            style="display: block"
            v-model="accountIntegration.billable_per_active_user"
            active-color="#13ce66"
            >
          </el-switch>
        </el-form-item>
      </el-form>
      </el-col>
    </el-row>
    <el-button type="primary" plain @click="handleSubmit" :loading="isLoading(accountIntegrationData)">
      <i class="fa fa-save xs-mr-10"></i>Save
    </el-button>
    </el-row>
  </table-page-layout>
</template>

<script>
import { getPage } from "@/helpers/rest-store-vuex";
import { isOK, isLoading } from "@jorgenevens/rest-store";
import { search } from "@/mixins";
import { getResource } from "@/helpers/rest-store-vuex";
import isEmpty from "lodash/isEmpty";
import { mapActions } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import reject from "lodash/reject";
import pickBy from "lodash/pickBy";
import _cloneDeep from "lodash/cloneDeep";
import ElAddButton from "@/components/ui/add-button";
import PageTitle from "@/components/ui/page-title";
import Resource from "@/components/app/resource";

export default {
  components: {
    ElAddButton,
    PageTitle,
    Resource
  },
  props: {
    showPageTitle: {
      type: Boolean,
      default: true
    },
    pageTitle: {
      type: String,
      default: "Modules"
    }
  },
  data() {
    return {
      page: parseInt(this.$route.query.page) || 1,
      pageSize: 20,
      accountIntegration: null,
    };
  },
  watch: {
    accountIntegrationData: {
      immediate: true,
      handler(v) {
        if (!isLoading(v) && isOK(v)) {
          this.accountIntegration = {
            ..._cloneDeep(v),
          }
        }
      }
    }
  },
  methods: {
    ...mapActions("accountintegrations", {
      updateAccountIntegration: "update"
    }),
    isLoading,
    isEmpty,
    handleSubmit(){
      this.updateAccountIntegration({
        id: this.accountIntegrationId,
        data: this.accountIntegration
      })
    }
  },
  computed: {
    accountIntegrationId() {
      return this.$route.params.accountIntegrationId;
    },
    accountId(){
      return this.$route.params.accountId;
    },
    accountIntegrationData: getResource(cmp => cmp.accountIntegrationId, {
      namespace: "accountintegrations",
      condition: cmp => cmp.accountIntegrationId
    })
  }
};
</script>
