<template>
  <el-dialog
    title="My Profile"
    :visible.sync="dialogVisible"
    @closed="handleClosed"
    :close-on-click-modal="false"
  >
    <el-form :model="form" :rules="rules" ref="form">
      <el-form-item label="First Name" label-width="150px" prop="firstname">
        <el-input v-model="form.firstname" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Last Name" label-width="150px" prop="lastname">
        <el-input v-model="form.lastname" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Email" label-width="150px" prop="email">
        <el-input v-model="form.email" type="email" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Language" label-width="150px" prop="locale">
        <el-select v-model="form.locale" placeholder="Please select a Language" autocomplete="none">
          <el-option v-for="c in LOCALE" :key="c.code" :label="c.name" :value="c.code"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog" :disabled="isLoading">Cancel</el-button>
      <el-button type="primary" @click="handleSubmit" :disabled="isLoading">{{$t('Update Profile')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { USER_ROLE, LOCALE } from "@/config/constants";
import { mapActions, mapGetters } from "vuex";
import { getResource } from "@/helpers/rest-store-vuex";
import { isLoading } from "@jorgenevens/rest-store";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      USER_ROLE,
      LOCALE,
      dialogVisible: true,
      rules: {
        customer_id: [{ required: true }, { message: "Customer is required!" }],
        email: [{ required: true }, { message: "Email is required!" }],
        role: [{ required: true }, { message: "Role is required!" }],
        locale: [{ required: true }, { message: "Language is required!" }]
      }
    };
  },
  computed: {
    ...mapGetters("ui", ["isLoading"]),
    ...mapGetters("auth", ["currentUser"]),
    isUpdate() {
      return !_.isEmpty(this.data);
    },
    form: {
      get: getResource(cmp => cmp.currentUser.id, {
        namespace: "users"
      }).transform(r => {
        return _.cloneDeep(r);
      }),
      set: v => {}
    }
  },
  methods: {
    ...mapActions("auth", ["updateProfile"]),
    closeDialog() {
      this.dialogVisible = false;
    },
    handleClosed() {
      this.$router.push({ path: this.$store.state.route.from.fullPath });
    },
    handleSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$startLoading();
          let _data = {
            firstname: this.form.firstname,
            lastname: this.form.lastname,
            email: this.form.email,
            locale: this.form.locale
          };
          this.updateProfile({ id: this.form.id, data: _data }).then(() => {
            this.$endLoading();
            this.closeDialog();
          });
        } else {
          this.$notify.error({
            title: "Validation Error",
            message: "Please check the form..."
          });
        }
      });
    },
    closeDialog() {
      this.dialogVisible = false;
      this.$refs["form"].resetFields();
    }
  }
};
</script>

