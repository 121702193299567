export const SCOPES = [
    "ALL:ACCESS",
    "SOCIAL:READ",
    "SOCIAL:WRITE"
]

export const TYPES = [
    "operator_client",
    "tenant_client",
    "ext_visitor_screen"
]