import _Object$assign from 'babel-runtime/core-js/object/assign';
import { BasicAuth } from "../../util/header";
import { isFunction } from '../../util';

function OwnerFlow(client) {
    this.client = client;
}

OwnerFlow.prototype.getToken = function (username, password, opts) {
    var self = this;
    var options = _Object$assign({}, this.client.options, opts);

    var data = {
        username: username,
        password: password,
        grant_type: 'password',
        scope: options.scopes
    };

    this.client.resetToken();

    var headers = {
        authorization: BasicAuth(options.clientId, options.clientSecret)
    };

    return this.client.post(options.tokenURI, data, { headers: headers }).then(function (res) {
        var token = self.client.setToken(res);
        if (isFunction(self.client.cbTokenChanged)) self.client.cbTokenChanged(token);

        return token;
    });
};

export default OwnerFlow;