import _get from 'lodash/get';

function checkId(options) {
	if (options.id) return;

	throw new Error('ID is missing');
}

export
	function fetchPage(fn) {
	return function ({ commit }, options) {
		commit('loadingPage', options);
		return Promise.resolve()
			.then(() => fn(options))
			.then(res => {
				commit('addPage', {
					page: options.page,
					listName: options.listName,
					pageSize: options.pageSize || 10,

					items: res.data,
					total: _get(res, 'meta.total_row_count') || // v5 api
						_get(res, 'pagination.total_row_count') || // v2 api
						res.total || 0 // custom (papercut, ...)
				});
				return res;
			})
			.catch(error => {
				if (process.env.NODE_ENV !== 'production')
					console.error(error);
				commit('errorPage', { ...options, error });
			});
	};
}

export
	function fetchRange(fn) {
	return function ({ commit }, options) {
		commit('loadingRange', options);
		return Promise.resolve()
			.then(() => fn(options))
			.then(res => {
				commit('addRange', {
					page: options.page,
					listName: options.listName,
					start: options.start,
					end: options.end,

					items: res.data,
					total: _get(res, 'meta.total_row_count', res.total)
				});
				return res;
			})
			.catch(error => {
				if (process.env.NODE_ENV !== 'production')
					console.error(error);
				commit('errorRange', { ...options, error });
			});
	};
}

export
	function fetchAll(fn) {
	return function ({ commit }, options) {
		let page = 0;
		const rangeOptions = {
			listName: options.listName,
			start: 0,
			end: options.pageSize || 10, // placeholder until we know the real total
		}

		commit('loadingRange', rangeOptions);

		function getNextSlice(items = [], total = Infinity) {
			if (items.length >= total)
				return items;

			return fn(++page, options).then(res => {
				items = items.concat(res.data);
				total = _get(res, 'meta.total_row_count', res.total);

				return getNextSlice(items, total);
			});
		}

		return Promise.resolve()
			.then(getNextSlice)
			.then(items => {
				commit('addRange', {
					...rangeOptions,
					end: items.length - 1,
					items,
					total: items.length,
				});
				return items;
			})
			.catch(error => {
				commit('errorRange', { ...rangeOptions, error });
			});
	};
}

export
	function fetchResource(fn) {
	return function ({ commit }, options) {
		if (!options.id) return;
		commit('loadingResource', options);
		return Promise.resolve()
			.then(() => fn(options))
			.then(res => {
				commit('addResource', {
					...options,
					resource: res.data
				});
				return res.data;
			})
			.catch(error => {
				if (process.env.NODE_ENV !== 'production')
					console.error(error);
				commit('errorResource', { ...options, error });
			});
	};
}

export
	function createResource(fn) {
	return function ({ commit }, options) {
		commit('loadingResource', options);
		return Promise.resolve()
			.then(() => fn(options))
			.then(res => {
				commit('addResource', {
					...options,
					resource: res.data
				});
				return res.data;
			})
			.catch(error => {
				if (process.env.NODE_ENV !== 'production')
					console.error(error);
				// commit('errorResource', { ...options, error });
				throw error;
			});
	}
}

export
	function updateResource(fn) {
	return function ({ commit }, options) {
		return Promise.resolve()
			.then(() => checkId(options))
			.then(() => commit('loadingResource', options))
			.then(() => fn(options))
			.then(res => {
				commit('expireResource', {
					...options,
					resource: res.data
				});

				if (!options.listName) {
					commit('expireAll');
				} else {
					commit('expireList', options.listName);
				}


				return res.data;
			})
			.catch(error => {
				//commit('errorResource', { ...options, error });
				throw error;
			});
	}
}

export
	function removeResource(fn) {
	return function ({ commit }, options) {
		return Promise.resolve()
			.then(() => checkId(options))
			.then(() => commit('loadingResource', options))
			.then(() => fn(options))
			.then(res => {
				commit('removeResource', options);


				if (!options.listName) {
					commit('expireAll');
				} else {
					commit('expireList', options.listName);
				}


				return res.data;
			});
	}
}
