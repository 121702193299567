import ServiceAppsOverview from '@/views/serviceApps/Overview/Index.vue';
import NewServiceApp from '@/views/serviceApps/NewApplication/Index.vue';
import EditServiceApp from '@/views/serviceApps/EditApplication/Index.vue';

export default [
    {
        path: '/service-applications',
        name: 'serviceApps.index',
        component: ServiceAppsOverview,
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },
    {
        path: '/service-applications/new',
        name: 'serviceApps.new',
        component: NewServiceApp,
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },
    {
        path: '/service-applications/:applicationId',
        name: 'serviceApps.edit',
        component: EditServiceApp,
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },
]