import _extends from 'babel-runtime/helpers/extends';
import _Object$assign from 'babel-runtime/core-js/object/assign';
import _Promise from 'babel-runtime/core-js/promise';
import axios from 'axios';
import qs from 'qs';

import responseHandler from './util/handlers/response-handler';
import handleErrors from './util/handlers/error-handler';

import OauthToken from './oauth/token';
import OwnerFlow from './oauth/flows/owner';

import { expect, isFunction } from './util';

var DEFAULT_TIMEOUT = 30000;
var DEFAULT_HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

function sanitizeScope(scopes) {
    if (scopes) return Array.isArray(scopes) ? scopes.join(' ') : toString(scopes);else return '';
}

function Client(_options) {
    var _this = this;

    var self = this;

    var clientId = _options.clientId,
        clientSecret = _options.clientSecret,
        baseURL = _options.baseURL,
        _options$tokenURI = _options.tokenURI,
        tokenURI = _options$tokenURI === undefined ? "oauth/token" : _options$tokenURI,
        _options$revokeURI = _options.revokeURI,
        revokeURI = _options$revokeURI === undefined ? "oauth/revoke" : _options$revokeURI,
        _options$timeout = _options.timeout,
        timeout = _options$timeout === undefined ? DEFAULT_TIMEOUT : _options$timeout,
        scopes = _options.scopes,
        _options$headers = _options.headers,
        headers = _options$headers === undefined ? DEFAULT_HEADERS : _options$headers;


    this.client = axios.create({
        baseURL: baseURL,
        timeout: timeout,
        headers: headers
    });

    this.cbOnRepsonse = responseHandler;

    expect(clientId, 'clientId is required');
    expect(clientSecret, 'clientSecret is required');
    expect(baseURL, 'baseURL is required');
    expect(tokenURI, 'tokenURI is required');

    this.token = null;

    this.options = {
        clientId: clientId,
        clientSecret: clientSecret,
        tokenURI: tokenURI,
        revokeURI: revokeURI,
        scopes: sanitizeScope(scopes)
    };

    this.client.interceptors.request.use(function (config) {
        if (self.token) {
            config.headers.Authorization = self.token.tokenType + ' ' + self.token.accessToken;
        }
        return config;
    }, function (error) {
        return _Promise.reject(error);
    });

    this.client.interceptors.response.use(function (response) {
        if (isFunction(_this.cbOnRepsonse)) return _this.cbOnRepsonse(response);else return response;
    }, function (error) {
        if (error) {
            var err = handleErrors(_this, error);
            if (err && !(err instanceof _Promise)) {
                if (isFunction(_this.cbOnError)) _this.cbOnError(err);
                return _Promise.reject(err);
            } else if (err && err instanceof _Promise) return err;
        }
    });

    this.owner = new OwnerFlow(this);
}

Client.prototype = {
    set onTokenChanged(fn) {
        this.cbTokenChanged = fn;
    },
    set onError(fn) {
        this.cbOnError = fn;
    },
    get defautlPageSize() {
        return this._defaultPageSize;
    },
    set defautlPageSize(value) {
        this._defaultPageSize = value || 25;
    }
};

Client.prototype.createToken = function (access, refresh, type, data) {
    var token = this.setToken(access, refresh, type, data);
    //callback
    if (isFunction(this.cbTokenChanged)) this.cbTokenChanged(token);
    return token;
};

Client.prototype.setToken = function (access, refresh, type, data) {
    var options = _Object$assign({}, data, typeof access === 'string' ? { access_token: access } : access, typeof refresh === 'string' ? { refresh_token: refresh } : refresh, typeof type === 'string' ? { token_type: type } : type);

    this.token = new OauthToken(this, options);

    return this.token;
};

Client.prototype.resetToken = function () {
    this.token = null;
    return this.token;
};

Client.prototype.request = function (config) {
    return this.client.request(config);
};

Client.prototype.post = function (url, data, config) {
    return this.client.post(url, data, config);
};

Client.prototype.get = function (url, data, config) {
    if (data) url += '?' + qs.stringify(data);
    return this.client.get(url, _extends({}, config));
};

Client.prototype.delete = function (url, data, config) {
    if (data) url += '?' + qs.stringify(data);
    return this.client.delete(url, _extends({}, config));
};

Client.prototype.put = function (url, data, config) {
    return this.client.put(url, data, config);
};

Client.prototype.patch = function (url, data, config) {
    return this.client.patch(url, data, config);
};

export default Client;