import { USER_ROLE } from '@/config/constants'
import zfhqClient from '@/config/zfhq-api'
import _isEmpty from 'lodash/isEmpty'
import axios from 'axios'

const getDefaultState = () => {
  var authToken = null;
  var currentUser = false;
  if (window.sessionStorage) {
    authToken = JSON.parse(window.sessionStorage.getItem('authToken'));
    console.log("authToken", authToken)
    console.log("zfhqClient", zfhqClient)
    if (!_isEmpty(authToken)){
      zfhqClient.setToken(authToken)
    }
    currentUser = JSON.parse(window.sessionStorage.getItem('currentUser'))
  }

  return {
    currentUser,
    authToken
  }
}

export const state = getDefaultState()

export const mutations = {
  resetAuthToken: state => {
    state.authToken = null;
    if (sessionStorage) {
      sessionStorage.setItem("authToken", JSON.stringify(state.authToken));
    }
  },
  setAuthToken: (state, token) => {
    state.authToken = token;
    if (sessionStorage) {
      sessionStorage.setItem("authToken", JSON.stringify(state.authToken));
    }
  },
  setCurrentUser: (state, currentUser) => {
    state.currentUser = currentUser;
    if (sessionStorage) { 
      sessionStorage.setItem("currentUser", JSON.stringify(state.currentUser));
    }
  }
};

export const actions = {
  setToken: ({ commit }, token) => {
    commit("setAuthToken", token);
  },
  signIn: ({ commit, dispatch }, { username, password }) => {
    return new Promise((resolve, reject) => {
      commit("resetAuthToken");

      return axios.post(`${process.env.VUE_APP_BASE}/api/signin`, {
        username,
        password
      }).then(res => {
        return dispatch("signInWithToken", { token: res.data }).then(() => {
          resolve(true);
        });
        }).catch(err => {
        let error = err.response.data
        if (!_.isEmpty(error) && !_.isEmpty(error.errors)) {
          reject(error.errors[0].code);
        } else {
          reject(err);
        }
      });

    });
  },
  signInWithToken: ({ commit, dispatch }, { token }) => {
    return new Promise((resolve, reject) => {
      if (_.isEmpty(token)) {
        return reject(false);
      }

      zfhqClient.setToken(token)
      commit("setAuthToken", token);
      const userId = token.id || token.user.id
      dispatch("users/fetch", { id: userId }, { root: true }).then(user => {
        if (user.role === USER_ROLE.ADMIN || user.role === USER_ROLE.SUPER_ADMIN) {
          commit("setCurrentUser", user);
          resolve(true);
        } else {
          commit("resetAuthToken");
          commit("setCurrentUser", null);
          reject("Invalid Credentials")
        }
      })
        .catch(err => {
          commit("resetAuthToken");
          commit("setCurrentUser", null);
          reject(err)
        });
    });
  },
  signOut: ({ commit }) => {
    zfhqClient.resetToken();
    commit("resetAuthToken");
    commit("setCurrentUser", null);
  },
  updateProfile: ({ dispatch, commit, state }, data) => {
    const id = state.currentUser.id;

    return dispatch("users/updateUser", { id, ...data }, { root: true }).then(
      user => {
        commit("setCurrentUser", user);
      }
    );
  },
  updatePassword: ({ dispatch, state }, data) => {
    const { password, password_confirmation } = data;
    const id = state.currentUser.id;

    return dispatch(
      "users/updateUser",
      {
        id,
        data: {
          password,
          password_confirmation
        }
      },
      { root: true }
    );
  }
};

export const getters = {
  currentUser: state => {
    const user = state.currentUser;
    if (_.isEmpty(user)) return {};
    const firstName = user.firstname || "No";
    const lastName = user.lastname || "Name";
    const fullName = firstName + " " + lastName;
    const initialName = firstName.slice(0, 1) + ". " + lastName;
    const initials = firstName.slice(0, 1) + " " + lastName.slice(0, 1);

    return {
      ...user,
      fullName,
      initialName,
      initials
    };
  }
};
