import { getResource as _getResource } from '@jorgenevens/rest-store/vuex';
export { apply, getPage, getRange } from '@jorgenevens/rest-store/vuex';

import { shouldFetch, resource } from '@jorgenevens/rest-store';
import { wrapDispatch } from '@jorgenevens/rest-store/vuex';
import getRoot from '@jorgenevens/rest-store/lib/vuex/get-root'

export
    function getResource(selector, options) {
    if (!options.placeholder)
        options.placeholder = {};

    return _getResource(selector, options);
}

export function ensureResource(store, id, options) {
    const { namespace, params } = options;
    let { fetch = 'fetch' } = options;

    if (namespace && typeof fetch === 'string')
        fetch = `${namespace}/${fetch}`;

    const dispatch = wrapDispatch(store.dispatch);
    const root = getRoot(store.state, namespace);

    let promise = Promise.resolve();

    if (shouldFetch(root, id)) {
        promise = dispatch(fetch, { id, ...params }, { root: true })
            .catch(() => { }); // ignore errors; they are stored in the store as usual
    }

    return promise.then(() => resource(root, id));
}