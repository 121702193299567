import AccountsOverview from "@/views/accounts/Overview/Index";
import ShowAccount from "@/views/accounts/ShowAccount/Index";

import UsersOverview from "@/views/users/Overview/Index";
import BrandingOverview from "@/views/accounts/Branding/Index";
import AccountDashboard from "@/views/accounts/Dashboard/Index";
import AccountModules from "@/views/accounts/Modules/Index";
import AccountModuleDetail from "@/views/accounts/Modules/Detail";

import AccountIntegrations from "@/views/accounts/Integrations/Index";
import AccountIntegrationDetail from "@/views/accounts/Integrations/Detail";

import RouteChildren from "@/components/layouts/RouteChildren";

export default [
  {
    path: "/accounts",
    name: "accounts.index",
    component: AccountsOverview,
    meta: {
      authRequired: true,
      layout: "app-layout",
    },
  },
  {
    path: "/accounts/:accountId",
    name: "accounts.show",
    component: ShowAccount,
    redirect: "account.dashboard",
    meta: {
      authRequired: true,
      layout: "app-layout",
    },
    children: [
      {
        path: "",
        name: "account.dashboard",
        component: AccountDashboard,
        meta: {
          authRequired: true,
          layout: "app-layout",
        },
      },
      {
        path: "/accounts/:accountId/integrations",
        name: "account.integrations",
        component: RouteChildren,
        redirect: "integrations.overview",
        meta: {
          authRequired: true,
          layout: "app-layout",
        },
        children: [
          {
            path: "",
            name: "integrations.overview",
            component: AccountIntegrations,
            meta: {
              authRequired: true,
              layout: "app-layout",
            },
          },
          {
            path:
              "/accounts/:accountId/integrations/:accountIntegrationId/show",
            name: "integration.detail",
            component: AccountIntegrationDetail,
            meta: {
              authRequired: true,
              layout: "app-layout",
            },
          },
        ],
      },
      {
        path: "/accounts/:accountId/modules",
        name: "account.modules",
        component: RouteChildren,
        redirect: "modules.overview",
        meta: {
          authRequired: true,
          layout: "app-layout",
        },
        children: [
          {
            path: "",
            name: "modules.overview",
            component: AccountModules,
            meta: {
              authRequired: true,
              layout: "app-layout",
            },
          },
          {
            path: "/accounts/:accountId/modules/:operatorModuleId/show",
            name: "modules.detail",
            component: AccountModuleDetail,
            meta: {
              authRequired: true,
              layout: "app-layout",
            },
          },
        ],
      },
      {
        path: "/accounts/:accountId/branding",
        name: "account.branding",
        props: { showPageTitle: false },
        component: BrandingOverview,
        meta: {
          authRequired: true,
          layout: "app-layout",
        },
      },
      {
        path: "users",
        name: "account.users",
        props: { showPageTitle: false },
        component: UsersOverview,
        meta: {
          authRequired: true,
          layout: "app-layout",
        },
      },
    ],
  },
];
