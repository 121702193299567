import LegalEntitiesOverview from '@/views/legalEntities/Overview/Index.vue';
import LegalEntityDetail from '@/views/legalEntities/Detail/Index.vue';


export default [
    {
        path: '/legal-entities',
        name: 'legalEntities.index',
        component: LegalEntitiesOverview,
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },
    {
        path: '/legal-entities/new',
        name: 'legalEntities.new',
        component: LegalEntityDetail,
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },
    {
        path: '/legal-entities/detail/:entityId',
        name: 'legalEntities.detail',
        component: LegalEntityDetail,
        meta: {
            authRequired: true,
            layout: 'app-layout'
        }
    },
]

