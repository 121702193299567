<template>
  <table-page-layout>
    <page-title>Service Applications</page-title>
    <el-row type="flex" :gutter="10" justify="space-between" class="xs-mb-10">
      <el-col :md="6">
        <el-add-button @click="handleNewApp">New Service App</el-add-button>
      </el-col>
      <el-col :md="18" class="text-right">
        <el-input
          suffix-icon="fa fa-search"
          :placeholder="`${$t('Search Applications')}...`"
          v-model="search"
          clearable
          style="width:400px;"
        ></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="m-t-1">
      <el-col :md="24">
        <el-card shadow="never">
          <el-table
            ref="applicationTable"
            :data="applications"
            stripe
            size="small"
            v-loading="isLoading(applications)"
            :empty-text="$t('No Applications')"
            @sort-change="handleSortChange"
            @expand-change="handleExpandChange"
          >
            <el-table-column width="50" align="center">
              <template slot-scope="{ row }">
                <i
                  class="fa fa-circle"
                  :class="{'text-success': row.is_active, 'text-danger': !row.is_active}"
                ></i>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Name')"
              sortable="custom"
              prop="name"
              width="400"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <span class="text-small pointer" @click="handleShow(row)">{{row.name}}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Account')"
              sortable="custom"
              prop="account_id"
              width="250"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="{ row }">
                <resource
                  namespace="accounts"
                  :id="row.account.id"
                  field="company_name"
                  v-if="row"
                />
              </template>
            </el-table-column>

            <el-table-column :label="$t('Application')" sortable="custom" prop="application_id">
              <template slot-scope="{ row }">
                <resource
                  namespace="oauthApplications"
                  :id="row.application.id"
                  field="name"
                  v-if="row"
                  class="pointer"
                />
              </template>
            </el-table-column>

            <el-table-column width="60" align="center">
              <template slot-scope="{ row }">
                <el-button type="text" @click="handleShow(row)">
                  <i class="fa fa-search"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="xs-mt-20 xs-mb-10">
      <el-col :md="24">
        <el-pagination
          :disabled="isLoading(applications)"
          class="text-center"
          style="margin-bottom:20px;"
          background
          layout="prev, pager, next"
          :current-page="page"
          :page-count="pages"
          @current-change="onPageChange"
        />
      </el-col>
    </el-row>
  </table-page-layout>
</template>

<script>
import { getPage } from "@/helpers/rest-store-vuex";
import { isOK, isLoading } from "@jorgenevens/rest-store";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import reject from "lodash/reject";
import pickBy from "lodash/pickBy";
import { search } from "@/mixins";

import ElAddButton from "@/components/ui/add-button";
import PageTitle from "@/components/ui/page-title";
import Resource from "@/components/app/resource";
import { mapActions } from "vuex";

export default {
  mixins: [search],
  components: {
    ElAddButton,
    PageTitle,
    Resource
  },
  data() {
    return {
      page: parseInt(this.$route.query.page) || 1,
      needle: this.$route.query.needle || "",
      search: this.$route.query.needle || "",
      pageSize: 20,
      sort: "name",
      customFilters: {},
      filtersVisible: false
    };
  },
  watch: {
    filterQueryParams: "setQueryParams"
  },
  methods: {
    isLoading,
    isEmpty,
    ...mapActions("serviceApps", {
      updateApplication: "update",
      deleteApplication: "delete",
      refreshApiKey: "refreshApiKey"
    }),
    setQueryParams() {
      if (this.filteringIsActive) {
        this.page = 1;
        this.$router.push({
          query: {
            page: this.page,
            ...this.filterQueryParams
          }
        });
      } else {
        this.$router.push({
          query: {
            page: this.page
          }
        });
      }
    },
    onPageChange(_page) {
      this.page = _page;
      this.$router.push({
        query: {
          page: _page,
          ...this.filterQueryParams
        }
      });
      this.setQueryParams();
    },
    handleShow(app) {
      this.$router.push({
        name: "serviceApps.edit",
        params: {
          applicationId: app.id
        }
      });
    },
    handleCopySuccess(e) {
      this.$message({
        message: "Copied.",
        center: true,
        duration: 500
      });
    },
    handleNewApp() {
      this.$router.push({
        name: "serviceApps.new"
      });
    },

    handleSortChange({ column, prop, order }) {
      if (order === "ascending") this.sort = `${prop}`;
      if (order === "descending") this.sort = `-${prop}`;
      if (order === null) this.sort = "name";
    },

    handleExpandChange(row, expanded) {
      if (expanded.length > 1)
        this.$refs.applicationTable.toggleRowExpansion(expanded[0], false);
    }
  },
  computed: {
    listName() {
      const whiteListed = ["needle"];
      let filter = Object.keys(this.filters).map(key => {
        if (whiteListed.includes(key) && this.filters[key] !== undefined) {
          if (typeof this.filters[key] === "boolean")
            return `${key}-${this.filters[key]}`;
          return String(this.filters[key])
            .split(",")
            .join("_");
        }
      });
      filter = reject(filter, isEmpty).join("_");
      if (this.filteringIsActive) return `search_${filter}`;
      return `all`;
    },
    filteringIsActive() {
      return !isEmpty(this.needle);
    },
    filterQueryParams() {
      const whiteListed = ["needle"];

      return pickBy(this.filters, (val, key) => {
        if (typeof val === "boolean") return whiteListed.includes(key);
        return !isEmpty(val) && whiteListed.includes(key);
      });
    },
    filters() {
      let _filters = {};

      if (!isEmpty(this.needle)) _filters["needle"] = this.needle;

      return _filters;
    },
    pages() {
      const total = this.$store.state.serviceApps.list[this.listName].total;
      return Math.ceil(total / this.pageSize);
    },
    applications: getPage(cmp => cmp.page, cmp => cmp.listName, {
      namespace: "serviceApps",
      params: cmp => ({
        pageSize: cmp.pageSize,
        filters: cmp.filters,
        sort: cmp.sort
      })
    })
  }
};
</script>

<style lang="scss">
.resource-link {
  &:hover {
    text-decoration: underline;
    padding-bottom: 5px;
  }
}
</style>
