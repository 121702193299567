<template>
  <el-menu
    ref="navMenu"
    :default-active="$route.path"
    router
    :collapse="true"
    background-color="#1f2532"
    text-color="#fff"
    active-text-color="#009fe1"
  >
    <el-menu-item index="/" :route="{ name: 'Dashboard' }">
      <i class="fa fa-tachometer-alt"></i>
      <span slot="title">Dashboard</span>
    </el-menu-item>
    <el-menu-item index="/accounts" :route="{ name: 'accounts.index' }">
      <i class="fa fa-users"></i>
      <span slot="title">Accounts</span>
    </el-menu-item>
    <el-menu-item index="/locations" :route="{ name: 'locations.index' }">
      <i class="far fa-building"></i>
      <span slot="title">Locations</span>
    </el-menu-item>
    <el-menu-item index="/admin" :route="{ name: 'admin.index' }">
      <i class="fa fa-cog"></i>
      <span slot="title">Admin</span>
    </el-menu-item>
    <div class="xs-mt-10 xs-pt-10" />
    <el-submenu index="1" style="position: absolute; bottom: 10px; width: 100%">
      <template slot="title">
        <i class="fa fa-ellipsis-h"></i>
        <span slot="title">Toolbox</span>
      </template>
      <el-menu-item index="/admin-users" :route="{ name: 'users.admin.index' }">
        <i class="fa fa-user-shield"></i>
        <span slot="title">Admin Users</span>
      </el-menu-item>
      <el-menu-item
        index="/client-applications"
        :route="{ name: 'oauthApplications.index' }"
      >
        <i class="fa fa-laptop-code"></i>
        <span slot="title">Client Applications</span>
      </el-menu-item>
      <el-menu-item
        index="/service-applications"
        :route="{ name: 'serviceApps.index' }"
      >
        <i class="fa fa-cube"></i>
        <span slot="title">Service Apps</span>
      </el-menu-item>
      <el-menu-item
        index="/legal-entities"
        :route="{ name: 'legalEntities.index' }"
      >
        <i class="fa fa-balance-scale"></i>
        <span slot="title">Legal entities</span>
      </el-menu-item>
      <!-- <el-menu-item index="/billing_frequenties" :route="{name: 'Accounts'}">
        <i class="far fa-money-bill-alt"></i>
        <span>Billing Frequencies</span>
      </el-menu-item>
      <el-menu-item index="/currencies" :route="{name: 'Accounts'}">
        <i class="fa fa-coins"></i>
        <span>Currencies</span>
      </el-menu-item>
      <el-menu-item index="/modules" :route="{name: 'Accounts'}">
        <i class="fa fa-archive"></i>
        <span>Modules</span>
      </el-menu-item>
      <el-menu-item index="/static_pages" :route="{name: 'Accounts'}">
        <i class="fa fa-file-alt"></i>
        <span>Static Pages</span>
      </el-menu-item>
      <el-menu-item index="/integrations" :route="{name: 'Accounts'}">
        <i class="fa fa-puzzle-piece"></i>
        <span>Integrations</span>
      </el-menu-item>
      <el-menu-item index="/mail_types" :route="{name: 'Accounts'}">
        <i class="fa fa-envelope"></i>
        <span>Mail Types</span>
      </el-menu-item>
      <el-menu-item index="/mail_templates" :route="{name: 'Accounts'}">
        <i class="far fa-envelope"></i>
        <span>Mail Templates</span>
      </el-menu-item>
      <el-menu-item index="/notification_types" :route="{name: 'Accounts'}">
        <i class="fa fa-bullhorn"></i>
        <span>Notification Types</span>
      </el-menu-item>
      <el-menu-item index="/utilities" :route="{name: 'Accounts'}">
        <i class="fa fa-wrench"></i>
        <span>Utilities</span>
      </el-menu-item>-->
    </el-submenu>
  </el-menu>
</template>

<script>
import { mapGetters } from "vuex";
import authUser from "@/mixins/authUser";

export default {
  mixins: [authUser],
  data() {
    return {
      openPath: [],
    };
  },
  computed: {
    ...mapGetters("ui", ["menuOpen"]),
    accountCount() {
      return 0;
    },
  },
};
</script>

<style lang="scss">
.el-menu {
  border-right: none !important;
  .el-menu-item {
    line-height: 45px !important;
    height: 45px !important;
    i.fa,
    i.far {
      margin-right: 10px;
    }
  }
  .el-submenu__title {
    line-height: 45px !important;
    height: 45px !important;

    .el-menu-item {
      line-height: 40px !important;
      height: 40px !important;
    }
    i.fa,
    i.far {
      margin-right: 10px;
    }
  }
}
</style>
