export var toString = function toString(str) {
    return str == null ? '' : String(str);
};

export var isFunction = function isFunction(fn) {
    return fn && typeof fn === 'function';
};

export var expects = function expects(obj) {
    for (var i = 1; i < arguments.length; i++) {
        var prop = arguments[i];

        if (obj[prop] == null) {
            throw new TypeError('Expected "' + prop + '" to exist');
        }
    }
};

export var expect = function expect(arg, message) {
    if ([undefined, null].includes(arg)) throw new TypeError(message || 'argument is undefined!');
};