import _get from 'lodash/get';

import fetchRange from '@jorgenevens/rest-store/lib/list/range';
import shouldFetchRange from '@jorgenevens/rest-store/lib/list/should-fetch-range';
import _total from '@jorgenevens/rest-store/lib/list/_total';
import _list from '@jorgenevens/rest-store/lib/list/_list';

import _transformable from '@jorgenevens/rest-store/lib/vuex/_transformable';
import getRoot from '@jorgenevens/rest-store/lib/vuex/get-root';
import _compact from '@jorgenevens/rest-store/lib/vuex/_compact';
import _selectors from '@jorgenevens/rest-store/lib/vuex/_selectors';
import { updateListHash } from '@jorgenevens/rest-store/lib/vuex/_state-helper';
import { wrapDispatch } from '@jorgenevens/rest-store/lib/vuex/throttled-dispatch';

const allDefaults = {
    namespace: null,
    listName: 'all',
    fetch: 'fetchAll',
    storeName: '$store',
    condition: () => true
};

function shouldFetchAll(root, listName) {
    const list = _list(root, listName);
    const total = _total(list);

    if (total === Infinity)
        return true;

    return shouldFetchRange(root, listName, 0, total - 1);
}

function fetchAll(root, listName, opts) {
    const list = _list(root, listName);
    let end = _total(list);

    if (end === Infinity)
        end = 10;

    return fetchRange(root, listName, 0, end, opts);
}

export default
function getAll(listName, options = {}) {
    options = { ...allDefaults, ...options };

    const { namespace, storeName } = options;
    let { fetch, condition } = options;

    const {
        getListName,
        getParams,
        getHash
    } = _selectors(undefined, { ...options, listName });

    if (namespace && typeof fetch === 'string')
        fetch = `${namespace}/${fetch}`;

    return _transformable(function () {
        const opts = getParams(this);
        const listName = getListName(this, opts);

        const { start = 0, end = 9 } = getSelection(this, opts) || {};

        const store = _get(this, storeName);
        const hash = getHash(opts);
        const allowFetch = condition(this, opts);
        const dispatch = wrapDispatch(store.dispatch);

        updateListHash(store, namespace, listName, hash);

        const root = getRoot(store.state, namespace);
        if (allowFetch && shouldFetchAll(root, listName))
            dispatch(fetch, { listName, ...opts });

        const entries = fetchAll(root, listName, opts);
        return _compact(entries);
    });
}
