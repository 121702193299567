<template>
<pre>{{roles}}</pre>
</template>

<script>
export default {
  props: {
    roles: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style>
</style>
