import { shouldFetch, resource } from '@jorgenevens/rest-store';
import { wrapDispatch } from '@jorgenevens/rest-store/vuex';
import getRoot from '@jorgenevens/rest-store/lib/vuex/get-root'

import store from '@/store'

export default function (id, options) {
    const { namespace, params } = options;
    let { fetch = 'fetch' } = options;

    if (namespace && typeof fetch === 'string')
        fetch = `${namespace}/${fetch}`;

    const dispatch = wrapDispatch(store.dispatch);
    const root = getRoot(store.state, namespace);

    let promise = Promise.resolve();

    if (shouldFetch(root, id)) {
        promise = dispatch(fetch, { id, ...params }, { root: true })
            .catch((err) => {
                return Promise.reject(err)
            }); // ignore errors; they are stored in the store as usual
    }

    return promise.then(() => resource(root, id));
}
