<template>
  <table-page-layout>
    <page-title v-if="showPageTitle">{{pageTitle}}</page-title>
    <el-row type="flex" :gutter="10" justify="space-between" class="xs-mb-10">
      <el-col :md="6">
        <el-add-button @click="handleNewLocation">Location</el-add-button>
      </el-col>
      <el-col :md="18" class="text-right">
        <el-input
          suffix-icon="fa fa-search"
          :placeholder="`${$t('Search Locations')}...`"
          v-model="search"
          clearable
          style="width:400px;"
        ></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="m-t-1">
      <el-col :md="24">
        <el-card shadow="never">
          <el-table
            :data="locations"
            stripe
            size="small"
            v-loading="isLoading(locations)"
            :empty-text="$t('No Locations')"
            @sort-change="handleSortChange"
          >
            <el-table-column :label="$t('Name')" sortable="custom" prop="alias">
              <template slot-scope="{ row }">
                <span class="text-small pointer" @click="showLocation(row)">{{row.alias}}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Account')"
              sortable="custom"
              prop="operator_organisation_id"
              width="220"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="{ row }">
                <resource
                  namespace="accounts"
                  :id="row.account.id"
                  field="company_name"
                  v-if="row && row.account"
                />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('City')"
              width="150"
              sortable="custom"
              prop="city"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="{ row }">
                <span class="text-small">{{row.city}}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Country')"
              width="150"
              sortable="custom"
              prop="countrycode"
            >
              <template slot-scope="{ row }">
                <span class="text-small">{{row.country_name}}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Date Created')"
              width="120"
              sortable="custom"
              prop="created_at"
            >
              <template slot-scope="{ row }">
                <span class="text-small">{{row.created_at | date}}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Onboarding')"
              align="center"
              width="180"
              sortable="custom"
              prop="onboarding_mode"
            >
              <template slot-scope="{ row }">
                <i
                  class="fa fa-circle"
                  :class="{'text-success': row.onboarding_mode, 'text-danger': !row.onboarding_mode}"
                ></i>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Billed')"
              align="center"
              width="180"
              sortable="custom"
              prop="setup_is_billed"
            >
              <template slot-scope="{ row }">
                <i
                  class="fa fa-circle"
                  :class="{'text-success': row.setup_is_billed, 'text-danger': !row.setup_is_billed}"
                ></i>
              </template>
            </el-table-column>
            <el-table-column width="60" align="center">
              <template slot-scope="{ row }">
                <el-button type="text" @click="showLocation(row)">
                  <i class="fa fa-search"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="xs-mt-20 xs-mb-10">
      <el-col :md="24">
        <el-pagination
          :disabled="isLoading(locations)"
          class="text-center"
          style="margin-bottom:20px;"
          background
          layout="prev, pager, next"
          :current-page="page"
          :page-count="pages"
          @current-change="onPageChange"
        />
      </el-col>
    </el-row>
<!-- 
      <pre>locations: {{ locations }} </pre> -->
  </table-page-layout>
</template>

<script>
import { getPage } from "@/helpers/rest-store-vuex";
import { isOK, isLoading } from "@jorgenevens/rest-store";
import { search } from "@/mixins";

import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import reject from "lodash/reject";
import pickBy from "lodash/pickBy";

import ElAddButton from "@/components/ui/add-button";
import PageTitle from "@/components/ui/page-title";
import Resource from "@/components/app/resource";

import SingleUpload from '@/components/upload/single-upload';

export default {
  mixins: [search],
  components: {
    ElAddButton,
    PageTitle,
    Resource
  },
  props: {
    showPageTitle: {
      type: Boolean,
      default: true
    },
    pageTitle: {
      type: String,
      default: "Locations"
    }
  },
  data() {
    return {
      page: parseInt(this.$route.query.page) || 1,
      needle: this.$route.query.needle || "",
      search: this.$route.query.needle || "",
      pageSize: 20,
      sort: "-created_at,alias",
      customFilters: {
        onboarding_mode: this.$route.query.onboarding_mode
      },
      filtersVisible: false
    };
  },
  watch: {
    filterQueryParams: "setQueryParams"
  },
  methods: {
    isLoading,
    isEmpty,
    setQueryParams() {
      if (this.filteringIsActive) {
        this.page = 1;
        this.$router.push({
          query: {
            page: this.page,
            ...this.filterQueryParams
          }
        });
      } else {
        this.$router.push({
          query: {
            page: this.page
          }
        });
      }
    },
    onPageChange(_page) {
      this.page = _page;
      this.$router.push({
        query: {
          page: _page,
          ...this.filterQueryParams
        }
      });
      this.setQueryParams();
    },
    showLocation(location) {
        this.$router.push({
          name: "location.dashboard",
          params: {
            locationId: location.id
          }
        });
    },
    handleNewLocation() {
      if (this.$route.name === "locations.admin.index") {
        this.$router.push({ name: "locations.admin.new" });
      } else {
        this.$router.push({ name: "locations.new" });
      }
    },
    applyCustomFilters(_filters) {
      this.customFilters = cloneDeep(_filters);
      this.filtersVisible = false;
    },
    handleSortChange({ column, prop, order }) {
      if (order === "ascending") this.sort = `${prop}`;
      if (order === "descending") this.sort = `-${prop}`;
      if (order === null) this.sort = "company_name";
    }
  },
  computed: {
    accountId() {
      return this.$route.params.accountId;
    },
    listName() {
      const whiteListed = ["needle", "onboarding_mode"];
      let filter = Object.keys(this.filters).map(key => {
        if (whiteListed.includes(key) && this.filters[key] !== undefined) {
          if (typeof this.filters[key] === "boolean")
            return `${key}-${this.filters[key]}`;
          return String(this.filters[key])
            .split(",")
            .join("_");
        }
      });
      filter = reject(filter, isEmpty).join("_");
      if (this.filteringIsActive) return `search_${filter}`;
      return `all`;
    },
    filteringIsActive() {
      return (
        !isEmpty(this.needle) ||
        this.customFilters.onboarding_mode !== undefined
      );
    },
    filterQueryParams() {
      const whiteListed = ["needle", "onboarding_mode"];

      return pickBy(this.filters, (val, key) => {
        if (typeof val === "boolean") return whiteListed.includes(key);
        return !isEmpty(val) && whiteListed.includes(key);
      });
    },
    filters() {
      let _filters = {};

      if (!isEmpty(this.needle)) _filters["needle"] = this.needle;
      _filters["onboarding_mode"] = this.customFilters.onboarding_mode;

      if (!isEmpty(this.accountId))
        _filters["organisation_id"] = this.accountId;
      return _filters;
    },
    pages() {
      const total = this.$store.state.locations.list[this.listName].total;
      return Math.ceil(total / this.pageSize);
    },
    locations: getPage(cmp => cmp.page, cmp => cmp.listName, {
      namespace: "locations",
      params: cmp => ({
        pageSize: cmp.pageSize,
        filters: cmp.filters,
        sort: cmp.sort
      })
    })
  }
};
</script>

<style>
</style>

}
</script>

<style>
</style>
