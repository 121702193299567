import { apply } from '@jorgenevens/rest-store/vuex';
import { attach } from '@jorgenevens/rest-store';
import zfhqClient from '@/config/zfhq-api'

import { fetchPage, fetchResource, createResource, updateResource } from '../helpers/actions';
import _mutations from '../helpers/mutations';

export const mutations = {
    ..._mutations
};

export const state = {
    ...apply(attach)
};

export const actions = {
    fetch: fetchResource(options => {
        return zfhqClient.get(`v5/users/${options.id}`)
    }),

    fetchPage: fetchPage(options => {

        const {
            page = 1,
            pageSize = PAGING.DEFAULT_SIZE,
            filters,
            include,
            sort = 'role,firstname,lastname'
        } = options;


        let _filters = {
            ...filters,
        }

        let _include = ''
        if (include && include.length > 0) _include = include.join(',')

        return zfhqClient.get('v5/users', {
            sort,
            include: _include,
            filters: _filters,
            page: {
                size: pageSize,
                number: page
            }
        });
    }),

    create: createResource(options => {
        const {
            data
        } = options;

        let _data = {
            user: {
                ...data
            }
        }

        return zfhqClient.post(`v5/users`, _data);
    }),

    update: updateResource(options => {
        const {
            id,
            data
        } = options;

        let _data = {
            user: {
                ...data,
            }
        }

        return zfhqClient.put(`v5/users/${id}`, _data)
    }),
}