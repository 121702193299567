<template>
  <table-page-layout>
    <page-title>Legal entities</page-title>
    <el-row type="flex" :gutter="10" justify="space-between" class="xs-mb-10">
      <el-col :md="6">
        <el-add-button @click="handleNewEntity">New legal entities</el-add-button>
      </el-col>
      <el-col :md="18" class="text-right">
        <el-input
          suffix-icon="fa fa-search"
          :placeholder="`${$t('Search legal entities')}...`"
          v-model="search"
          clearable
          style="width:400px;"
        ></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="m-t-1">
      <el-col :md="24">
        <el-card shadow="never">
          <el-table
            ref="legalEntitiesTable"
            :data="legalEntities"
            stripe
            size="small"
            v-loading="isLoading(legalEntities)"
            :empty-text="$t('No legal entities')"
            @sort-change="handleSortChange"
            @expand-change="handleExpandChange"
          >
            <el-table-column
              :label="$t('Name')"
            >
              <template slot-scope="{ row }">
                  {{row.name}}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Description')"
              align="center"
            >
              <template slot-scope="{ row }">
                  {{row.description}}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Locale')"
              align="center"
            >
              <template slot-scope="{ row }">
                  {{row.locale}}
              </template>
            </el-table-column>
            <el-table-column width="60" align="center">
              <template slot-scope="{ row }">
                <el-button type="text" @click="handleShow(row)">
                  <i class="fa fa-search"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="xs-mt-20 xs-mb-10">
      <el-col :md="24">
        <el-pagination
          v-if="pages > 1"
          :disabled="isLoading(legalEntities)"
          class="text-center"
          style="margin-bottom:20px;"
          background
          layout="prev, pager, next"
          :current-page="page"
          :page-count="pages"
          @current-change="onPageChange"
        />
      </el-col>
    </el-row>
  </table-page-layout>
</template>

<script>
import { getPage } from "@/helpers/rest-store-vuex";
import { isLoading } from "@jorgenevens/rest-store";
import isEmpty from "lodash/isEmpty";
import reject from "lodash/reject";
import pickBy from "lodash/pickBy";
import { search } from "@/mixins";

import ElAddButton from "@/components/ui/add-button";
import PageTitle from "@/components/ui/page-title";
import Resource from "@/components/app/resource";
export default {
  mixins: [search],
  components: {
    ElAddButton,
    PageTitle,
    Resource
  },
  data() {
    return {
      page: parseInt(this.$route.query.page) || 1,
      needle: this.$route.query.needle || "",
      search: this.$route.query.needle || "",
      pageSize: 20,
      sort: "name",
      customFilters: {},
      filtersVisible: false
    };
  },
  watch: {
    filterQueryParams: "setQueryParams"
  },
  methods: {
    isLoading,
    isEmpty,
    setQueryParams() {
      if (this.filteringIsActive) {
        this.page = 1;
        this.$router.push({
          query: {
            page: this.page,
            ...this.filterQueryParams
          }
        });
      } else {
        this.$router.push({
          query: {
            page: this.page
          }
        });
      }
    },
    onPageChange(_page) {
      this.page = _page;
      this.$router.push({
        query: {
          page: _page,
          ...this.filterQueryParams
        }
      });
      this.setQueryParams();
    },
    handleShow(entity) {
      this.$router.push({
        name: "legalEntities.detail",
        params: {
          entityId: entity.id
        }
      });
    },

    handleNewEntity() {
      this.$router.push({
        name: "legalEntities.new"
      });
    },

    handleSortChange({ column, prop, order }) {
      if (order === "ascending") this.sort = `${prop}`;
      if (order === "descending") this.sort = `-${prop}`;
      if (order === null) this.sort = "name";
    },

    handleExpandChange(row, expanded) {
      if (expanded.length > 1)
        this.$refs.applicationTable.toggleRowExpansion(expanded[0], false);
    }
  },
  computed: {
    listName() {
      const whiteListed = ["needle"];
      let filter = Object.keys(this.filters).map(key => {
        if (whiteListed.includes(key) && this.filters[key] !== undefined) {
          if (typeof this.filters[key] === "boolean")
            return `${key}-${this.filters[key]}`;
          return String(this.filters[key])
            .split(",")
            .join("_");
        }
      });
      filter = reject(filter, isEmpty).join("_");
      if (this.filteringIsActive) return `search_${filter}`;
      return `all`;
    },
    filteringIsActive() {
      return !isEmpty(this.needle);
    },
    filterQueryParams() {
      const whiteListed = ["needle"];

      return pickBy(this.filters, (val, key) => {
        if (typeof val === "boolean") return whiteListed.includes(key);
        return !isEmpty(val) && whiteListed.includes(key);
      });
    },
    filters() {
      let _filters = {};

      if (!isEmpty(this.needle)) _filters["needle"] = this.needle;

      return _filters;
    },
    pages() {
      const total = this.$store.state.legalEntities.list[this.listName]
        .total;
      return Math.ceil(total / this.pageSize);
    },
    legalEntities: getPage(cmp => cmp.page, cmp => cmp.listName, {
      namespace: "legalEntities",
      params: cmp => ({
        pageSize: cmp.pageSize,
        filters: cmp.filters,
        sort: cmp.sort
      })
    })
  }
};
</script>

<style>
</style>
