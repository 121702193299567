<template>
	<div>
		<input 
			ref="uploader" 
			type="file" 
			style="display:none;" 
			multiple @change="handleFilesChanged" />
		<button 
			type="button"
			class="btn btn-primary"
			@click="handleSelectFiles" :disabled="!enabled">
		<slot></slot>
	</button>
</div>
</template>

<script>
import { fetchUploadUrl } from '@/mixins'
import axios from 'axios'

export default {

	mixins: [
		fetchUploadUrl
	],

	name: 'single-upload',

	data () {
		return {
			value: [],
			selectFilesBtnDisabled: false
		};
	},

	props: [
		'enabled'
	],

	methods: {
		handleSelectFiles: function(e){
			this.value = []
			this.$emit('input', this.value);
			this.$refs.uploader.click();
			this.selectFilesBtnDisabled = true;
		},
		handleFilesChanged: function(event) {
			let _files = event.target.files;
			for (var i = _files.length - 1; i >= 0; i--) {
				let f = _files[i];
				this.fetchUploadUrl(f.name, 'customer_files')
				.then(res => {
					let file = {
						id: new Date().valueOf(),
						date_modified: f.lastModifiedDate,
						file_name: f.name,
						file_size: f.size,
						content_type: f.type,
						upload_url: res.data.upload_url,
						download_url: res.data.download_url,
						progress: 0,
						progress_status: '',
						error: ''
					}
					this.value.push(file)
					this.$emit('input', this.value)

					//start uploading
					let _this = this;
					var options = {
				        headers: {
				          'Content-Type': file.content_type
				        },
				        onUploadProgress: progressEvent => {
				        	file.progress = Math.round((progressEvent.loaded / progressEvent.total ) * 100);
				        } 
				      };
					axios.put(res.data.upload_url, f, options)
					.then(res => {
						file.progress_status = 'success';
						_this.$emit('fileUploadComplete', file);
					})
					.catch(err => {
						file.progress_status = 'exception';
						file.error = err.message;
						_this.$emit('fileUploadError', file);
					})
				})
			}
		},
	},

	mounted(){
		console.log(this);
	},

	created(){
		console.log(this);
	}
};
</script>

<style lang="scss" type="text/scss" scoped>
</style>