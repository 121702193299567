export const state = {
  menuOpen: false,
  loading: false
};

export const actions = {
  toggleMenu({ commit, state }) {
    commit("setMenuOpen", !state.menuOpen);
  },
  closeMenu({ commit }) {
    commit("setMenuOpen", false);
  },
  startLoading({ commit }) {
    commit("setLoading", true);
  },
  endLoading({ commit }) {
    commit("setLoading", false);
  }
};

export const mutations = {
  setMenuOpen(state, open) {
    state.menuOpen = open;
  },
  setLoading(state, loading) {
    state.loading = loading
  }
};

export const getters = {
  menuOpen(state) {
    return state.menuOpen;
  },
  isLoading(state) {
    return state.loading
  }
};