<script>
export default {
  render: h => {},
  created() {
    this.$startLoading();
    this.$store.dispatch("auth/signOut").then(() => {
      this.$endLoading();
      this.$router.push({ name: "Login" });
    });
  }
};
</script>

