import debounce from "lodash/debounce";

export default {
    data() {
        return {
            search: '',
            needle: undefined
        }
    },
    watch: {
        search: "onSearch"
    },
    methods: {
        onSearch: debounce(function () {
            this.needle = this.search || undefined;
        }, 500)
    }
}