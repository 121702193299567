import { apply } from '@jorgenevens/rest-store/vuex';
import {
	loadingPage as _loadingPage,
	errorPage as _errorPage,
	addPage as _addPage,

	loadingRange as _loadingRange,
	errorRange as _errorRange,
	addRange as _addRange,

	options as _options,
	expireAll as _expireAll,

	loading as _loading,
	remove as _remove,
	expire as _expire,
	error as _error,
	add as _add
} from '@jorgenevens/rest-store';


export
	function loadingPage(state, opts) {
	const { listName, page } = opts;

	apply(_loadingPage, state, listName, page, opts);
};

export
	function addPage(state, opts) {
	const { listName, page, items, total, ttl = 3600 * 1000 } = opts;

	const ids = items.map(i => {
		apply(_add, state, i.id, i, { ttl });
		return i.id;
	});

	apply(_addPage, state, listName, page, ids, opts);
	apply(_options, state, listName, { total });
}

export
	function errorPage(state, opts) {
	const { error, page, listName } = opts;
	apply(_errorPage, state, listName, page, error, opts);
}

export
	function expireAll(state) {
	Object.keys(state.list).forEach(listName => {
		apply(_expireAll, state, listName);
	});
}

export
	function expireList(state, listName) {
	apply(_expireAll, state, listName);
}

export const page = { loadingPage, addPage, errorPage, expireAll, expireList };

export
	function loadingRange(state, opts) {
	const { listName, start, end } = opts;

	apply(_loadingRange, state, listName, start, end, opts);
}

export
	function addRange(state, opts) {
	const { listName, start, end, items, total } = opts;

	const ids = items.map(i => {
		apply(_add, state, i.id, i, { ttl: 3600 * 1000 });
		return i.id;
	});

	apply(_addRange, state, listName, start, end, ids, opts);
	apply(_options, state, listName, { total });
}

export
	function errorRange(state, opts) {
	const { error, start, end, listName } = opts;
	apply(_errorRange, state, listName, start, end, error, opts);
}

export const range = { loadingRange, addRange, errorRange, expireAll, expireList };

export
	function loadingResource(state, opts) {
	apply(_loading, state, opts.id);
}

export
function addResource(state, opts) {
    const { id, resource, listName, ttl = 3600 * 1000 } = opts;

    // Remove loading indicator on "undefined"
    if (!id)
        apply(_remove, state, id, null, { ttl });

	apply(_add, state, id || resource.id, resource, { ttl });

	// skip expire lists
	if (listName === false)
		return;

	if (!listName) {
		expireAll(state)
	} else {
		expireList(state, listName)
	}
}

export
	function removeResource(state, opts) {
	apply(_remove, state, opts.id);

	expireAll(state);
}

export
	function expireResource(state, opts) {
	apply(_expire, state, opts.id);
}

export
	function errorResource(state, opts) {
	apply(_error, state, opts.id, opts.error);
}

export const resource = { loadingResource, addResource, expireResource, removeResource, errorResource };

export default {
	...page,
	...range,
	...resource
};
