<template>
  <el-container direction="vertical">
    <el-card shadow="never">
      <el-form
        ref="loactionForm"
        v-loading="loading"
        label-position="left"
        label-width="150px"
        :model="emailType"
      >
        <el-form-item :label="$t('Name')" prop="name">
          <el-input v-model="emailType.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('Key')" prop="key">
          <el-input v-model="emailType.key"></el-input>
        </el-form-item>
        <el-form-item :label="$t('Specific')" prop="specific">
          <el-select v-model="emailType.specific">
            <el-option
              v-for="(item, idx) in specificOptions"
              :key="idx"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('Subscribeable')" prop="subscribeable">
          <el-checkbox v-model="emailType.subscribeable"></el-checkbox>
        </el-form-item>
      </el-form>
      <el-button type="primary" plain @click="handleSubmit">
        <i class="fa fa-save xs-mr-10"></i>Save
      </el-button>
    </el-card>
  </el-container>
</template>

<script>
import { getResource } from "@/helpers/rest-store-vuex";
import { mapActions } from "vuex";

import _cloneDeep from "lodash/cloneDeep";
import { isOK, isLoading } from "@jorgenevens/rest-store";

export default {
  components: {},
  data() {
    return {
      emailType: {},
    };
  },
  computed: {
    loading() {
      return isLoading(this.emailTypeData);
    },
    typeId() {
      return this.$route.params.id;
    },
    emailTypeData: getResource((cmp) => cmp.typeId, {
      namespace: "emailTypes",
      condition: (cmp) => cmp.typeId,
    }),
    specificOptions() {
      return [
        { value: 0, label: this.$t("Operator") },
        { value: 1, label: this.$t("Tenant") },
        { value: 2, label: this.$t("Both") },
      ];
    },
  },
  watch: {
    emailTypeData: {
      immediate: true,
      handler(v) {
        if (!isLoading(v) && isOK(v)) {
          this.emailType = {
            ..._cloneDeep(v),
          };
        }
      },
    },
  },
  methods: {
    isOK,
    isLoading,
    ...mapActions("emailTypes", {
      updateEmailType: "update",
    }),
    handleSubmit() {
      this.updateEmailType({
        id: this.typeId,
        mail_type: this.emailType,
      })
        .then(() => {
          this.$notify({
            title: "Success",
            message: "Email type updated",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify({
            title: "Error",
            message: "Update failed",
            type: "error",
          });
        });
    },
  },
};
</script>
