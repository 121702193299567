<template>
  <div>
    <page-title>Edit Application (Client)</page-title>
    <el-row type="flex" justify="space-between" class="xs-mb-10">
      <div>
        <el-button plain @click="handleBack" :disabled="loading">
          <i class="fa fa-arrow-circle-left xs-mr-10"></i>Back
        </el-button>
      </div>
      <div>
        <el-row type="flex" justify="end">
          <el-button plain @click="handleReset" :disabled="loading">
            <i class="fa fa-times xs-mr-10"></i>Reset
          </el-button>
          <el-button type="primary" plain @click="handleSubmit" :loading="loading">
            <i class="fa fa-save xs-mr-10"></i>Save
          </el-button>
        </el-row>
      </div>
    </el-row>

    <el-card shadow="never">
      <el-form
        label-position="left"
        label-width="150px"
        :model="application"
        :rules="rules"
        ref="appForm"
        v-loading="loading"
      >
        <el-form-item label="Name" prop="name">
          <el-input v-model="application.name"></el-input>
        </el-form-item>
        <el-form-item label="Type" prop="type">
          <el-select
            v-model="application.type"
            placeholder="Select Type"
            style="min-width:200px;"
            v-validate="`required`"
          >
            <el-option v-for="s in TYPES" :key="s" :label="s" :value="s"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Account">
          <select-account v-model="application.account_id" style="min-width:300px;"/>
        </el-form-item>

        <el-form-item label="Scopes">
          <multi-tag-input
            v-model="application.scopes"
            :suggestions="SCOPES"
            button-label="scope"
            placeholder="scope"
          />
        </el-form-item>
        <el-form-item label="Redirect Uri" prop="redirect_uri">
          <el-input v-model="application.redirect_uri">
            <i
              class="far fa-copy xs-pr-10 pointer"
              slot="suffix"
              v-clipboard:copy="application.redirect_uri"
              v-clipboard:success="handleCopySuccess"
            ></i>
          </el-input>
        </el-form-item>
        <el-form-item label="Client Id" prop="uid">
          <el-input v-model="application.uid" readonly>
            <i
              class="far fa-copy xs-pr-10 pointer"
              slot="suffix"
              v-clipboard:copy="application.uid"
              v-clipboard:success="handleCopySuccess"
            ></i>
          </el-input>
        </el-form-item>
        <el-form-item label="Client Secret" prop="secret">
          <el-input v-model="application.secret" readonly>
            <i
              class="far fa-copy xs-pr-10 pointer"
              slot="suffix"
              v-clipboard:copy="application.secret"
              v-clipboard:success="handleCopySuccess"
            ></i>
          </el-input>
        </el-form-item>
        <el-form-item label="PreAuthorized">
          <el-switch v-model="application.preauthorized"></el-switch>
        </el-form-item>
        <el-form-item label="Confidential">
          <el-switch v-model="application.confidential"></el-switch>
        </el-form-item>
      </el-form>
    </el-card>
    <el-row type="flex" justify="space-between" class="xs-mb-10 xs-mt-10">
      <div></div>
      <div>
        <el-button type="danger" plain @click="handleRemove" :loading="loading">
          <i class="far fa-trash-alt xs-mr-10"></i>Remove Application
        </el-button>
      </div>
    </el-row>
  </div>
</template>

<script>
import PageTitle from "@/components/ui/page-title";
import { getPage, getResource } from "@/helpers/rest-store-vuex";
import { isOK, isLoading } from "@jorgenevens/rest-store";
import { mapActions } from "vuex";
import { SCOPES, TYPES } from "../helpers/consts";
import SelectAccount from "@/components/app/select-account";
import MultiTagInput from "@/components/ui/multi-tag-input";

import _cloneDeep from "lodash/cloneDeep";
import _get from "lodash/get";

import { ensureResource } from "@/helpers/rest-store-vuex";

export default {
  components: {
    PageTitle,
    SelectAccount,
    MultiTagInput
  },
  mounted() {
    ensureResource(this.$store, this.applicationId, {
      namespace: "oauthApplications"
    }).then(data => {
      this.application = {
        ..._cloneDeep(data),
        account_id: data.account.id,
        location_id: data.location.id
      };
    });
  },
  data() {
    return {
      SCOPES,
      TYPES,
      application: {
        name: "",
        type: "",
        scopes: [],
        redirect_uri: "urn:ietf:wg:oauth:2.0:oob",
        preauthorized: true,
        confidential: true,
        account_id: undefined,
        location_id: undefined
      },
      loading: false,
      rules: {
        name: [
          {
            required: true,
            message: "Name is required",
            trigger: "blur"
          }
        ],
        type: [
          {
            required: true,
            message: "Type is required",
            trigger: "blur"
          }
        ],
        redirect_uri: [
          {
            required: true,
            message: "Redirect Uri is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    applicationId() {
      return this.$route.params.applicationId;
    }
  },
  methods: {
    isOK,
    isLoading,
    ...mapActions("oauthApplications", {
      fetchApplication: "fetch",
      updateApplication: "update",
      deleteApplication: "delete"
    }),
    handleCopySuccess(e) {
      this.$message({
        message: "Copied.",
        center: true,
        duration: 500
      });
    },
    handleReset() {
      this.$refs["appForm"].resetFields();
      let d = _cloneDeep(this.appData);
      this.application = {
        ...d,
        account_id: d.account.id,
        location_id: d.location.id
      };
    },
    handleRemove() {
      this.$prompt(
        "Please enter the application name for confirmation.",
        "Remove Application",
        {
          confirmButtonText: "OK, Remove",
          cancelButtonText: "Cancel",
          inputValidator: input => {
            if (input !== this.application.name)
              return "application name invalid.";
            return true;
          }
        }
      )
        .then(({ value }) => {
          this.loading = true;
          this.deleteApplication({
            id: this.applicationId
          })
            .then(_ => {
              this.$notify({
                title: "Success",
                message: "Application Removed",
                type: "success"
              });
              this.handleBack();
            })
            .catch(err => {
              this.$notify({
                title: "Error",
                message: "Application could not be removed!",
                type: "error"
              });
            })
            .finally(_ => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    handleBack() {
      this.$nextTick(() => {
        this.$router.push({
          name: "oauthApplications.index"
        });
      });
    },
    handleSubmit() {
      this.$refs["appForm"].validate(valid => {
        if (valid) {
          this.loading = true;
          this.updateApplication({
            id: this.applicationId,
            data: this.application
          })
            .then(res => {
              this.$notify({
                title: "Success",
                message: "Application Updated",
                type: "success"
              });
              this.handleBack();
            })
            .catch(err => {
              this.$notify({
                title: "Error",
                message: "Application could not be updated!",
                type: "error"
              });
            })
            .finally(_ => {
              this.loading = false;
            });
        } else {
          this.$notify({
            title: "Warning",
            message: "Please complete the form!",
            type: "warning"
          });
        }
      });
    }
  }
};
</script>

<style>
</style>
