<template>
  <div>
    <el-form>
      <SingleUpload
        v-model="units_file"
        :enabled="true"
        :label="$t('Select Units CSV')"
      >
        {{ $t("Select Units CSV") }}
      </SingleUpload>
      {{ locationId }}
      <el-button @click="onClick">{{ $t("Import") }}</el-button>
    </el-form>
  </div>
</template>

<script>
import _get from "lodash/get";
import { mapActions } from "vuex";

import SingleUpload from "@/components/upload/single-upload";

const DATE_FORMAT = "YYYY-MM-DD";

export default {
  data() {
    return {
      units_file: null,
      form: {
        units_csv: "",
        location_id: null,
      },
    };
  },
  computed: {
    locationId() {
      this.form.location_id = this.$route.params.locationId;
      return this.$route.params.locationId;
    },
  },
  methods: {
    ...mapActions("locations/units", ["importUnits"]),

    onClick() {
      this.importUnits(this.form)
        .then(() =>
          this.$notify({
            title: this.$t("Success"),
            message: this.$t("Import Scheduled"),
            type: "success",
          })
        )
        .catch((e) =>
          this.$notify({
            title: this.$t("Error"),
            message: e.message,
            type: "error",
          })
        );
    },
  },

  watch: {
    units_file(v) {
      const url = _get(v, "0.download_url");
      this.form.units_csv = url;
    },
  },

  components: {
    SingleUpload,
  },
};
</script>
