<template>
  <el-container direction="vertical">
    <el-row type="flex" justify="space-between" class="xs-mb-20">
      <el-link> </el-link>
      <el-menu
        :default-active="childPath"
        router
        mode="horizontal"
        active-text-color="#009fe1"
      >
        <el-menu-item index="admin.email" :route="{ name: 'admin.email' }">
          <i class="fa fa-envelope m-r-10" />
          <span>{{ $t("Email") }}</span>
        </el-menu-item>
        <el-menu-item
          index="admin.branding"
          :route="{ name: 'admin.branding' }"
        >
          <i class="fa fa-copyright m-r-10" />
          <span>{{ $t("Branding") }}</span>
        </el-menu-item>
      </el-menu>
    </el-row>
    <router-view :key="$route.fullPath" />
  </el-container>
</template>

<script>
export default {
  computed: {
    childPath() {
      const match = this.$route.path.replace(/^(.*[\\/])/, "/");
      if (match) return match;
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-menu--horizontal {
  border-bottom: none !important;
  .el-menu-item {
    border-bottom: none !important;
  }
}
</style>
