<template>
  <span v-if="isLoading(data) && !isOK(data)">
    <span class="fa fa-spinner fa-spin text-smaller"></span>
  </span>
  <span v-else>
    <template>
      <slot :data="data">{{data[field] || $t('-NA-')}}</slot>
    </template>
  </span>
</template>

<script>
import { getResource } from "@/helpers/rest-store-vuex";
import { isLoading, isOK } from "@jorgenevens/rest-store";

export default {
  props: {
    id: {
      type: String | Number,
      required: true
    },
    namespace: {
      required: true
    },
    fetch: {
      type: String
    },
    field: {
      type: String
    },
    params: {
      type: [Object, Function]
    }
  },
  computed: {
    data() {
      return getResource("id", {
        fetch: this.fetch || "fetch",
        namespace: this.namespace,
        condition: () => !!this.id,
        params: this.params
      }).call(this);
    }
  },

  methods: {
    isLoading,
    isOK
  }
};
</script>
