import { mapState, mapGetters } from "vuex";
import { USER_ROLE } from "@/config/constants";

export default {
  computed: {
    ...mapGetters("auth", ['currentUser']),

    isAdmin() {
      return this.currentUser && [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN].includes(this.currentUser.role)
    },
  }
};