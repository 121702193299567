<template>
  <div id="app">
    <component :is="layout">
      <router-view :key="$router.fullPath"/>
    </component>
  </div>
</template>

<script>

export default {
  name: 'app',
  computed: {
    layout() {
      return this.$route.meta.layout || "default-layout";
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
</style>
